import React, { useState } from 'react';
import axios from 'utils/axios';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { format } from 'date-fns';
import { useLocation, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { BsArrowRightShort } from 'react-icons/bs';
import { stringifyPrice } from 'utils/functions/stringifyPrice';
import RayButton from 'components/RayButton';
import {
  PRODUCT_TYPES,
  BuildingOption,
  LOST_KEYCARD_VARIANT_ID,
  PRINTING_BW_VARIANT_ID,
  PRINTING_COLOR_VARIANT_ID,
  ConferenceRoom,
} from './types';
import { emailValidateRegex } from 'utils/constants';
import { useOktaAuth } from '@okta/okta-react';

type Props = {
  email: string | undefined;
  building: BuildingOption | null;
  // memberCount: number | undefined;
  noOfHours: number;
  bookingDetails: { date: any; startTime?: any } | undefined;
  selectedVariant: any;
  quantity: any;
  productType: string;
  notes: string;
  gstin?: string | undefined;
  disableOrderBtn?: boolean;
  creditCount: number;
  enterprise: any;
  selectedRoom: ConferenceRoom;
  leftHotStamp: string,
  rightHotStamp: string
};

function CheckoutCard({
  email,
  building,
  bookingDetails,
  quantity,
  selectedVariant,
  productType,
  notes,
  noOfHours,
  gstin,
  disableOrderBtn = false,
  creditCount,
  enterprise,
  selectedRoom,
  leftHotStamp,
  rightHotStamp
}: Props) {
  const memberCount = selectedRoom?.capacity || undefined;
  const classes = useStyles();
  const CONFERENCE_HANDLE = 'conference-room-shop-purchase';
  const isProductConference = productType === CONFERENCE_HANDLE;
  const isProductLostKey = productType === "lost-keycard-ode";

  const { state }: any = useLocation();
  const { push } = useHistory();

  const { userInfo, setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();

  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const errorCheck = () => {
    if (!enterprise && !enterprise.id) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Select Enterprise to Proceed',
      });
      return false;
    }
    if (!selectedVariant || (selectedVariant && !selectedVariant.id)) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Select Variant to Proceed',
      });
      return false;
    }
    if (!building?.id) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Select Building',
      });
      return false;
    }
    if (isProductConference) {
      if (
        !selectedRoom ||
        !(selectedRoom.globalInventoryId && selectedRoom.id)
      ) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Select Cabin to Proceed',
        });
        return false;
      }
      if (!noOfHours) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Enter Meeting Duration',
        });
        return false;
      }
      if (bookingDetails && !bookingDetails.startTime) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Select Start Time Slot',
        });
        return false;
      }
    }
    if (!email || email === '' || !email.match(emailValidateRegex)) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Enter a valid email address',
      });
      return false;
    }
    if (!quantity) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Quantity must be greater than zero',
      });
      return false;
    }
    if(isProductLostKey){
      if (!leftHotStamp || !leftHotStamp.match(/^[0-9]{10}$/)) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter a 10 digit number for left hotstamp',
        });
        return false;
      }
      if (!rightHotStamp || !rightHotStamp.match(/^[0-9]{7}$/)) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter a 7 digit number for right hotstamp',
        });
        return false;
      }
    }
    return true;
  };

  const makeDraftOrder = async () => {
    if (!errorCheck()) return;
    setBtnDisable(true);
    const conferenceRoomCheck = isProductConference;
    const parkingCheck = productType === PRODUCT_TYPES['parking'].handle;
    const draftNotesBody: any = {
      handle: productType,
      building_title: building && building.name,
      building_id: building && building.id ? building.id : '',
      micromarket: building ? building.micromarket : '',
      city: building ? building.city : '',
      building_zone: building ? building.region : '',
      isDashboard: true,
    };
    if (
      conferenceRoomCheck &&
      bookingDetails &&
      bookingDetails.startTime &&
      memberCount &&
      creditCount
    ) {
      const conferenceBody = {
        booking_date: bookingDetails.date,
        booking_start_time: bookingDetails.startTime,
        variant_title: selectedVariant.title,
        member_count: memberCount,
        credit_count: creditCount,
        no_of_hrs: noOfHours,
        building_title: building && building.name,
      };
      draftNotesBody.conference_rooms_info = [conferenceBody];
    }
    if (parkingCheck && bookingDetails && bookingDetails.date) {
      const parkingInfoObject = {
        booking_date: bookingDetails.date,
        variant_title: selectedVariant.title,
      };
      draftNotesBody.parking_info = [parkingInfoObject];
    }
    if (state && state.addOnState && state.addOnState.customerEmail) {
      draftNotesBody.parent_order_no = state.addOnState.orderNo;
      draftNotesBody.booking_firebase_id = state.addOnState.bookingFirebaseId;
    }
    try {
      let type;
      if (selectedVariant.id === LOST_KEYCARD_VARIANT_ID) type = 'lost keycard';
      else if (selectedVariant.id === PRINTING_BW_VARIANT_ID)
        type = 'Printing - Black & White';
      else if (selectedVariant.id === PRINTING_COLOR_VARIANT_ID)
        type = 'Printing - Color';
      else if (conferenceRoomCheck) type = 'conference room';
      const body: any = {
        line_items: [
          {
            variant_id: selectedVariant.id,
            quantity:
              conferenceRoomCheck &&
              bookingDetails &&
              bookingDetails.startTime &&
              creditCount
                ? creditCount
                : quantity,
            type: type,
          },
        ],
        customer_email: email,
        notes: notes,
        enterpriseName: enterprise.id,
        order_creator: userInfo ? userInfo.id : null,
        buildingId: building && building.id ? building.id : '',
        draft_notes: JSON.stringify(draftNotesBody),
      };
      if (conferenceRoomCheck) {
        body.globalInventoryId = selectedRoom.globalInventoryId;
      }
      if(isProductLostKey) {
        body.left_hot_stamp = leftHotStamp;
        body.right_hot_stamp = rightHotStamp
      }
      await axios.post(`shop/create-b2b-purchases`, body, {
        headers: {
          Authorization: authState?.accessToken?.accessToken,
        },
      });
      setToastType({
        show: true,
        type: 'success',
        message: 'ODE purchase successful',
      });
      push('/shop-purchases');
      setBtnDisable(false);
    } catch (err) {
      const error: any = err;
      console.error(error.response);
      setToastType({
        show: true,
        type: 'error',
        message: error?.response?.data?.message || error?.message,
      });
      setBtnDisable(false);
    }
  };
  const getTotalPrice = () => {
    if (selectedVariant && selectedVariant.price) {
      if (isProductConference) {
        if (bookingDetails && selectedVariant && creditCount)
          return stringifyPrice(selectedVariant.price * creditCount);
        else {
          return '₹0';
        }
      } else {
        return stringifyPrice(Number(selectedVariant.price) * quantity);
      }
    } else {
      return '₹0';
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.headText}>
        <h4 className="ray-text--h4">{`Total: ${getTotalPrice()}`}</h4>
      </div>
      <Grid container spacing={1}>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          className={classes.detailCaption}
        >
          {building && building.id && (
            <div className="ray-text--body-small">Property:</div>
          )}
          {isProductConference &&
            selectedRoom &&
            selectedRoom.name &&
            selectedRoom.capacity && (
              <div className="ray-text--body-small">Conference Room:</div>
            )}
          {!isProductConference && quantity !== 0 && (
            <div className="ray-text--body-small">Quantity:</div>
          )}
          {bookingDetails && bookingDetails.date && (
            <div className="ray-text--body-small">Meeting Date:</div>
          )}
          {bookingDetails && bookingDetails.startTime && (
            <div className="ray-text--body-small">Meeting Time:</div>
          )}
          {isProductConference && noOfHours && noOfHours !== 0 && (
            <div className="ray-text--body-small">Meeting Duration:</div>
          )}
          {email && <div className="ray-text--body-small">Email:</div>}
          {gstin && <div className="ray-text--body-small">GSTIN:</div>}
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          className={classes.valueCaptions}
        >
          {building && building.id && (
            <div className="ray-text--body-small">{building.name}</div>
          )}
          {isProductConference &&
            selectedRoom &&
            selectedRoom.name &&
            selectedRoom.capacity && (
              <div className="ray-text--body-small">
                {selectedRoom.name} ({selectedRoom.capacity} Seater)
              </div>
            )}
          {!isProductConference && quantity !== 0 && (
            <div className="ray-text--body-small">{quantity}</div>
          )}
          {bookingDetails && bookingDetails.date && (
            <div className="ray-text--body-small">
              {format(bookingDetails.date, 'do MMM yyyy')}
            </div>
          )}
          {bookingDetails && bookingDetails.startTime && (
            <div className="ray-text--body-small">{`${format(
              bookingDetails.startTime,
              'h:mm aaaa',
            )}`}</div>
          )}

          {isProductConference && noOfHours && noOfHours !== 0 && (
            <div className="ray-text--body-small">{`${noOfHours} ${
              noOfHours === 1 ? 'hr.' : 'hrs.'
            }`}</div>
          )}
          {email && <div className="ray-text--body-small">{email}</div>}
          {gstin && <div className="ray-text--body-small">{gstin}</div>}
        </Grid>
      </Grid>

      <RayButton
        type="button"
        buttonType="primary"
        onClick={makeDraftOrder}
        className={classes.buttonRow}
        disabled={btnDisable || disableOrderBtn}
      >
        {btnDisable ? (
          <CircularProgress size={20} style={{ color: '#FFFFFF' }} />
        ) : (
          <>
            <span>Place Order</span>
            <BsArrowRightShort />
          </>
        )}
      </RayButton>
    </div>
  );
}

export default CheckoutCard;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      border: '2.5px solid #E3E3E3',
      borderRadius: '4px',
      padding: '1em',
    },
    headText: {
      '& small': {
        color: '#424242',
      },
    },
    detailCaption: {
      '& div': {
        fontWeight: 'bolder',
      },
    },
    valueCaptions: {
      '& div': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
        textOverflow: 'ellipsis',
      },
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '& svg': {
        fontSize: '1.5em',
      },
    },
  }),
);
