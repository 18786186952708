import React, { useState } from 'react';
import axios from 'utils/axios';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { format } from 'date-fns';
import { useLocation, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { BsArrowRightShort } from 'react-icons/bs';
import { stringifyPrice } from 'utils/functions/stringifyPrice';
import RayButton from 'components/RayButton';
import { PRODUCT_TYPES, BuildingOption } from './types';
import { emailValidateRegex } from 'utils/constants';
import { useOktaAuth } from '@okta/okta-react';

type Props = {
  email: string | undefined;
  building: BuildingOption | null;
  memberCount: number | undefined;
  bookingDetails: { date: any; startTime?: any; endTime?: any } | undefined;
  confRoom: string | undefined;
  selectedVariant: any;
  quantity: any;
  productType: string;
  notes: string;
  disableOrderBtn?: boolean;
  creditCount: number;
  enterprise: any;
};

function CheckoutCard({
  email,
  building,
  memberCount,
  bookingDetails,
  confRoom,
  quantity,
  selectedVariant,
  productType,
  notes,
  disableOrderBtn = false,
  creditCount,
  enterprise,
}: Props) {
  const classes = useStyles();
  const CONFERENCE_HANDLE = 'conference-room-shop-purchase';

  const { state }: any = useLocation();
  const { push } = useHistory();

  const { userInfo, setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();

  const [btnDisable, setBtnDisable] = useState<boolean>(false);

  const errorCheck = () => {
    if (!enterprise) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Select Enterprise to Proceed',
      });
      return false;
    }
    if (!selectedVariant || (selectedVariant && !selectedVariant.id)) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Select Variant to Proceed',
      });
      return false;
    }
    if (!building?.id) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Select Building',
      });
      return false;
    }
    if (productType === CONFERENCE_HANDLE) {
      if (!confRoom || confRoom === '') {
        setToastType({
          show: true,
          type: 'info',
          message: 'Enter Conference ID',
        });
        return false;
      }
    }
    if (!email || email === '' || !email.match(emailValidateRegex)) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Enter a valid email address',
      });
      return false;
    }
    return true;
  };

  const makeDraftOrder = async () => {
    if (!errorCheck()) return;
    setBtnDisable(true);
    const draftNotesBody: any = {
      handle: productType,
      building_title: building && building.name,
      building_id: building && building.id ? building.id : '',
      micromarket: building ? building.micromarket : '',
      city: building ? building.city : '',
      building_zone: building ? building.region : '',
    };
    const conferenceRoomCheck = productType === CONFERENCE_HANDLE;
    if (
      conferenceRoomCheck &&
      bookingDetails &&
      bookingDetails.startTime &&
      memberCount
    ) {
      draftNotesBody.conference_room_id = confRoom;
      draftNotesBody.booking_date = bookingDetails.date;
      draftNotesBody.booking_start_time = bookingDetails.startTime;
      draftNotesBody.variant_title = selectedVariant.title;
      draftNotesBody.member_count = memberCount;
      draftNotesBody.credit_count = creditCount;
      draftNotesBody.no_of_hrs = Math.round(
        Math.abs(bookingDetails.endTime - bookingDetails.startTime) / 36e5,
      );
    }
    if (
      productType === PRODUCT_TYPES['parking'].handle &&
      bookingDetails &&
      bookingDetails.date
    ) {
      draftNotesBody.booking_date = bookingDetails.date;
      draftNotesBody.variant_title = selectedVariant.title;
    }
    if (state && state.addOnState && state.addOnState.customerEmail) {
      draftNotesBody.parent_order_no = state.addOnState.orderNo;
      draftNotesBody.booking_firebase_id = state.addOnState.bookingFirebaseId;
    }
    try {
      const body = {
        line_items: [
          {
            variant_id: selectedVariant.id,
            quantity:
              conferenceRoomCheck &&
              bookingDetails &&
              bookingDetails.endTime &&
              bookingDetails.startTime &&
              memberCount
                ? creditCount
                : quantity,
            type: 'conference room',
          },
        ],
        customer_email: email,
        enterpriseName: enterprise.id,
        notes: notes,
        order_creator: userInfo ? userInfo.id : null,
        buildingId: building && building.id ? building.id : '',
        draft_notes: JSON.stringify({
          ...draftNotesBody,
          untrackedInventory: true,
        }),
      };
      await axios.post(`shop/create-b2b-purchases`, body, {
        headers: {
          Authorization: authState?.accessToken?.accessToken,
        },
      });
      setToastType({
        show: true,
        type: 'success',
        message: 'ODE purchase successful',
      });
      push('/shop-purchases');
      setBtnDisable(false);
    } catch (err) {
      const error: any = err;
      console.error(error.response);
      setToastType({
        show: true,
        type: 'error',
        message: error.response.data.message,
      });
      setBtnDisable(false);
    }
  };
  const getTotalPrice = () => {
    if (
      selectedVariant &&
      selectedVariant.price &&
      productType === CONFERENCE_HANDLE
    ) {
      return stringifyPrice(1000 * creditCount);
    } else {
      return '₹0';
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.headText}>
        <h4 className="ray-text--h4">{`Total: ${getTotalPrice()}`}</h4>
      </div>
      <Grid container spacing={1}>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          className={classes.detailCaption}
        >
          {building && building.id && (
            <div className="ray-text--body-small">Property:</div>
          )}
          {memberCount && memberCount !== 0 && (
            <div className="ray-text--body-small">Guests:</div>
          )}
          {productType !== CONFERENCE_HANDLE && quantity && quantity !== 0 && (
            <div className="ray-text--body-small">Quantity:</div>
          )}
          {bookingDetails && bookingDetails.date && (
            <div className="ray-text--body-small">Meeting Date:</div>
          )}
          {bookingDetails &&
            bookingDetails.startTime &&
            bookingDetails.endTime && (
              <div className="ray-text--body-small">Meeting Time:</div>
            )}
          {confRoom && (
            <div className="ray-text--body-small">Conference Room: </div>
          )}

          {email && <div className="ray-text--body-small">Email:</div>}
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          className={classes.valueCaptions}
        >
          {building && building.id && (
            <div className="ray-text--body-small">{building.name}</div>
          )}
          {memberCount && memberCount !== 0 && (
            <div className="ray-text--body-small">{memberCount}</div>
          )}
          {productType !== CONFERENCE_HANDLE && quantity && quantity !== 0 && (
            <div className="ray-text--body-small">{quantity}</div>
          )}
          {bookingDetails && bookingDetails.date && (
            <div className="ray-text--body-small">
              {format(bookingDetails.date, 'do MMM yyyy')}
            </div>
          )}
          {bookingDetails &&
            bookingDetails.startTime &&
            bookingDetails.endTime && (
              <div className="ray-text--body-small">{`${format(
                bookingDetails.startTime,
                'h:mm aaaa',
              )}-${format(bookingDetails.endTime, 'h:mm aaaa')}`}</div>
            )}
          {confRoom && <div className="ray-text--body-small">{confRoom}</div>}
          {email && <div className="ray-text--body-small">{email}</div>}
        </Grid>
      </Grid>

      <RayButton
        type="button"
        buttonType="primary"
        onClick={makeDraftOrder}
        className={classes.buttonRow}
        disabled={btnDisable || disableOrderBtn}
      >
        {btnDisable ? (
          <CircularProgress size={20} style={{ color: '#FFFFFF' }} />
        ) : (
          <>
            <span>Place Order</span>
            <BsArrowRightShort />
          </>
        )}
      </RayButton>
    </div>
  );
}

export default CheckoutCard;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      border: '2.5px solid #E3E3E3',
      borderRadius: '4px',
      padding: '1em',
    },
    headText: {
      '& small': {
        color: '#424242',
      },
    },
    detailCaption: {
      '& div': {
        fontWeight: 'bolder',
      },
    },
    valueCaptions: {
      '& div': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
        textOverflow: 'ellipsis',
      },
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '& svg': {
        fontSize: '1.5em',
      },
    },
  }),
);
