import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import Loader from 'components/Loader';
import TableCell from './TableCell';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useImperativeHandle } from 'react';
import { useAuthContext } from 'utils/hooks/useAuthContext';

const Wrapper = styled.div`
  width: 100%;
  table {
    font-size: 14px;
    width: 100%;
  }
  tr.booking_row:hover {
    cursor: pointer;
  }
  .no-bookings {
    margin-top: 20%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  .green-box {
    background: #12872a;
    color: white;
  }
  .user-annotation-text {
    padding: 2px 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
  }.
`;

const PaginationWrapper = styled.div`
  margin-top: 1.5em;
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  queryType: any;
  hits: number;
  reference: any;
  searchString: string;
  selectedBuilding: number;
  selectedOption: string;
  checked: boolean;
};

const TableContainer = forwardRef(
  ({
    queryType,
    hits,
    reference,
    searchString,
    selectedBuilding,
    selectedOption,
    checked,
  }: Props) => {
    const { useAlgolia } = useAuthContext()!;
    const [currentPage, setCurrentPage] = useState<number>(1);
    const classes = useStyles();
    const { isLoading, error, data, refetch } = useReactQuery(
      ['shopPurchases', queryType, hits, currentPage, checked],
      {
        url: useAlgolia
          ? '/shop/fetch-all-purchases'
          : '/api/v1/admin/shop-purchases',
        params: {
          purchaseStatus: queryType,
          pageNo: useAlgolia ? currentPage : currentPage - 1,
          hitsPerPage: hits,
          searchString,
          selectedBuilding,
          selectedOption,
          purchaseType: checked ? 'B2B' : '',
        },
      },
      {
        enabled: !!queryType,
      },
    );

    useImperativeHandle(reference, () => ({
      fetchAgain() {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
        refetch();
      },
      refreshLoad() {
        setCurrentPage(1);
        setTimeout(() => {
          refetch();
        }, 1000);
      },
    }));

    const handlePageChange = (
      event: React.ChangeEvent<unknown>,
      pageNo: number,
    ) => {
      setCurrentPage(pageNo);
      refetch();
    };

    if (isLoading) return <Loader maxHeight />;
    if (error) return <p>Error fetching records</p>;
    return (
      <>
        <Wrapper>
          {data?.data?.hits?.length > 0 && (
            <table className="ray-table">
              <thead>
                <tr>
                  <th>Order #</th>
                  <th>Customer Email</th>
                  <th>Product Name</th>
                  <th>Building Name</th>
                  <th>Created On</th>
                  {/* <th>Status</th> */}
                  <th />
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {React.Children.toArray(
                  data.data.hits.map((cell: any, index: number) => (
                    <TableCell
                      cell={cell}
                      index={index}
                      reloadPurchases={refetch}
                    />
                  )),
                )}
              </tbody>
            </table>
          )}
          {!isLoading && !(data?.data?.hits?.length > 0) && (
            <p className="no-bookings">
              No bookings available for the selected filter
            </p>
          )}
          {data &&
            (useAlgolia
              ? data?.data?.nbPages
              : data?.data?.pagination?.totalPages) > 0 && (
              <PaginationWrapper>
                <Pagination
                  count={
                    useAlgolia
                      ? data?.data?.nbPages
                      : data?.data?.pagination?.totalPages
                  }
                  page={currentPage}
                  onChange={handlePageChange}
                  shape="rounded"
                  classes={{ ul: classes.ul }}
                />
              </PaginationWrapper>
            )}
        </Wrapper>
      </>
    );
  },
);

export default TableContainer;

const useStyles = makeStyles(() => ({
  ul: {
    '& .Mui-selected': {
      backgroundColor: '#0000ff',
      color: '#fff',
    },
  },
}));
