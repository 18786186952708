import {
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { useState } from 'react';
import RayInput from 'components/RayInput';
import isGstinValid from '../../utils/functions/isGstinValid';
import RayButton from 'components/RayButton';
import axios from 'utils/axios';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { useOktaAuth } from '@okta/okta-react';

const GSTValidate = ({
  gstin,
  setGstin,
  gstinError,
  setGstinError,
  gstValid,
  setGstValid,
}: any) => {
  const classes = useStyles();
  const { setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);

  const validateHandler = async () => {
    try {
      setBtnDisable(true);
      const token = authState?.accessToken?.accessToken;
      const response = await axios.post(
        `/shop/validate-gstin`,
        { gstin: gstin },
        {
          headers: { Authorization: token },
        },
      );
      setToastType({
        show: true,
        type: 'success',
        message: response?.data?.message
          ? response?.data?.message
          : 'GST is valid',
      });
      setGstValid(true);
      setBtnDisable(false);
    } catch (err) {
      const error: any = err;
      let message = 'GST is not valid';
      setBtnDisable(false);
      if (error?.response?.data?.message)
        message = error?.response?.data?.message;
      setToastType({
        show: true,
        type: 'error',
        message: message,
      });
    }
  };

  const handleGstinChange = (gstin: string) => {
    setGstin(gstin);
    setGstValid(false);
    if (isGstinValid(gstin)) {
      setGstinError('');
    } else if (gstin.length !== 15) {
      setGstinError('GSTIN number should be of 15 characters');
    } else {
      setGstinError('Enter a valid GSTIN');
    }
  };
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={9}>
        <RayInput
          placeholder="Enter GST for Customer"
          id="gstin__customer"
          type="text"
          fullWidth
          autoMargin={false}
          value={gstin}
          onChange={e => {
            const value = e.target.value.replace(
              /[*|":<>[\]{}`\\()';&$=]/g,
              '',
            );
            handleGstinChange(value);
          }}
          label="Customer GSTIN"
          style={{ textTransform: 'uppercase' }}
        />
        <small className="ray-text--body-x-small">
          This is the GST that will be displayed on Invoice.
        </small>
        {gstinError && (
          <div>
            <small className={classes.fieldError}>{gstinError}</small>
          </div>
        )}
      </Grid>
      <Grid item xs={3}>
        <RayButton
          type="button"
          buttonType="primary"
          onClick={validateHandler}
          disabled={btnDisable || gstin === '' || gstinError || gstValid}
        >
          {btnDisable ? (
            <CircularProgress size={20} style={{ color: '#FFFFFF' }} />
          ) : gstValid ? (
            <>
              <span>GST validated</span>
            </>
          ) : (
            <span>Validate GST</span>
          )}
        </RayButton>
      </Grid>
    </Grid>
  );
};

export default GSTValidate;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldError: {
      color: '#CC0000',
    },
  }),
);
