import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { PRODUCT_TYPES } from "./types";

type Props = {
    info: any;
    selectedVariant: any;
    setVariant: React.Dispatch<any | null>;
    productType: string;
};

function VariantCard({ info, selectedVariant, setVariant, productType }: Props) {
  const classes = useStyles();
  const [checked, setChecked] = useState<boolean>(false);

  const GIFT_HANDLE = PRODUCT_TYPES["gift-card"].handle;

  useEffect(() => {
    if (selectedVariant && selectedVariant.id === info.id) setChecked(true);
    else setChecked(false);
  }, [selectedVariant, info]);

  const handleChange = () => {
    if (!checked) {
      setVariant(info);
    } else setVariant(null);
    setChecked(!checked);
  };
  return (
    <div onClick={handleChange} className={`${classes.root} ${checked ? classes.selected: ''}`}>
      <span className="ray-text--body-large">{productType === GIFT_HANDLE ? `₹${info.price}` : info.title}</span>
      {productType !== GIFT_HANDLE && <span className={classes.priceText}>{`₹${info.price}`}</span>}
    </div>
  );
}

export default VariantCard;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "flex-start",
      border: "2px solid #E3E3E3",
      borderRadius: "4px",
      padding:'1em',
      minWidth: '25%',
      marginRight: '0.5em',
      "&:hover": {
        cursor: "pointer",
        border: "2px solid #0000FF",
      },
    },
    selected: {
      border: "2px solid #0000FF",
    },
    priceText: {
      color: "#858585",
    },
  })
);
