import { CircularProgress, Grid, Theme, createStyles, makeStyles } from '@material-ui/core'
import SelectInput from 'components/MaterialSelect/Autocomplete'
import React, { useEffect, useState } from 'react'
import RayNumInput from 'components/RayInput/RayNumInput';
import TimePill from 'containers/ShopDetailPage/TimePill';
import { ConferenceRoom, ConferenceRoomResponseData } from 'containers/ShopDetailPage/types';
import axiosGlobal from 'utils/axiosGlobal';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useApolloQuery from 'utils/hooks/useApolloQuery';
import { FETCH_CONFERENCE_VARIANTS_BY_ID } from 'containers/ShopDetailPage/fetchConferenceVariantsById';
import { graphqlIdToRestId } from 'utils/commonFunctions';
import { format } from 'date-fns';
import { DEFAULT_ERROR_STATES } from 'containers/ShopDetailPage';
import { useOktaAuth } from '@okta/okta-react';

const getStartAndEndTime = (date: Date | string) => {
    const bookingDate = new Date(date).toDateString();
    const selectedBookingDate = format(new Date(bookingDate), 'yyyy-MM-dd');
    const startTime = selectedBookingDate + 'T03:30:00.000Z';
    const endTime = selectedBookingDate + 'T11:30:00.000Z';
    return [startTime, endTime];
};

const DEFAULT_LOADING_STATES = {
    timesLoader: false,
    roomsLoader: false,
    variantsLoader: false,
};

type ConferenceReusableProps = {
    selectedBuilding: any;
    noOfHours: number;
    bookingDate: Date | undefined;
    bookingStartTime: Date | undefined;
    selectedRoom: ConferenceRoom | undefined;
    errorState: typeof DEFAULT_ERROR_STATES;
    updateState: (state: any) => void;
    type: string;
}

const ConferenceReusable = ({ selectedBuilding, noOfHours, bookingDate, bookingStartTime, selectedRoom,
    errorState, updateState, type }: ConferenceReusableProps) => {
    const classes = useStyles();
    const {authState} = useOktaAuth();

    const [loaders, setLoaders] = useState(DEFAULT_LOADING_STATES);
    const [availableTimes, setAvailableTimes] = useState<string[]>([]);
    const [variantDetails, setVariantDetails] = useState<
        {
            id: number;
            price: number;
            oldId: string;
            title: string;
        }[]
    >([]);
    const [roomList, setRoomList] = useState<ConferenceRoom[]>([]);
    const [
        buildingConferenceProductID,
        setBuildingConferenceProductID,
    ] = useState<number | null>(null);

    const handleStartTime = (e: any) => {
        updateState({ 'bookingStartTime': e });
    };


    useEffect(() => {
        updateState({ 'selectedRoom': undefined, 'selectedConfVariant': null, 'bookingStartTime': undefined });
        setRoomList([]);
        setVariantDetails([]);
        setBuildingConferenceProductID(null);
        setAvailableTimes([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBuilding])

    useApolloQuery(type === 'event-space' ? 'wework' : 'myhq', FETCH_CONFERENCE_VARIANTS_BY_ID, {
        skip: !buildingConferenceProductID,
        variables: {
            id: `gid://shopify/Product/${buildingConferenceProductID}`,
        },
        onCompleted: (data: any) => {
            if (data && data.product) {
                const selectedVariants = data.product.variants.edges.map(
                    (variant: any) => {
                        return {
                            ...variant.node,
                            id: Number(graphqlIdToRestId(variant.node.id)),
                            price: variant.node.price.amount,
                        };
                    },
                );
                setVariantDetails(selectedVariants);
            }
        },
        onError: () => {
            updateState({
                'errorState': {
                    ...errorState, 'varaintsError':
                        'Failed to fetch Variants from GraphQL'
                }
            });
        },
    });

    const changeLoadersState = (type: keyof typeof loaders, value: boolean) => {
        setLoaders((prev: any) => ({ ...prev, [type]: value }));
    };

    const fetchBookingStartTimes = async (
        params: {
            startTime: string;
            endTime: string;
            id: string;
            noOfHrs: number;
        },
        token: string | undefined,
    ) => {
        try {
            type ResponseType = {
                data: {
                    data: string[];
                    message: string;
                    success: boolean;
                };
            };
            const response: ResponseType = await axiosGlobal.get(
                `/api/v1/admin/conference-rooms/availability`,
                {
                    params,
                    headers: {
                        Authorization: token,
                    },
                },
            );
            return { success: true, items: response.data.data };
        } catch (error) {
            console.error(error);
            return { success: false, items: [] };
        }
    };

    const fetchTimeSlots = AwesomeDebouncePromise(
        async (params: {
            startTime: string;
            endTime: string;
            id: string;
            noOfHrs: number;
        }) => {
            changeLoadersState('timesLoader', true);
            const timesResponse = await fetchBookingStartTimes(params, authState?.accessToken?.accessToken);
            setAvailableTimes(timesResponse.items);
            updateState({
                'errorState': {
                    ...errorState, 'timesError':
                        timesResponse.items.length === 0 ? 'Sorry, we don\'t have any slots available.' : ''
                }
            });
            changeLoadersState('timesLoader', false);
        },
        700,
    );

    useEffect(() => {
        if (roomList.length && bookingDate && noOfHours && selectedRoom) {
            const [startTime, endTime] = getStartAndEndTime(bookingDate);
            const id = selectedRoom.id;
            if (!startTime || !endTime || !id) {
                return;
            }
            const params = {
                startTime,
                endTime,
                id,
                noOfHrs: noOfHours,
            };
            fetchTimeSlots(params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingDate, noOfHours, selectedRoom, roomList]);

    const getAllConferenceRooms = async (
        pageNumber: number,
        totalConferenceRooms: ConferenceRoom[],
    ): Promise<void> => {
        const bodyParams = {
            pageNumber,
            limit: 50,
            locationId: selectedBuilding.locationUuid,
            isEventSpace: type === 'event-space',
        };
        // API CALL
        const {
            data: {
                data: { pagination, result },
            },
        }: {
            data: ConferenceRoomResponseData;
        } = await axiosGlobal.post('/api/v1/admin/conference-rooms/', bodyParams, {
            headers: {
                Authorization: authState?.accessToken?.accessToken,
            },
        });

        if (result) {
            for (const room of result) {
                if (
                    room &&
                    'productId' in room &&
                    'variantId' in room &&
                    'capacity' in room &&
                    room.capacity &&
                    room.productId &&
                    room.variantId
                ) {
                    totalConferenceRooms.push(room);
                }
            }
        }
        if (pagination.pageNumber + 1 >= pagination.totalPages) {
            return;
        }
        await getAllConferenceRooms(
            pagination.pageNumber + 1,
            totalConferenceRooms,
        );
    };

    const fetchConferenceRoomsFromGlobal = async () => {
        try {
            changeLoadersState('roomsLoader', true);
            const totalConferenceRooms: ConferenceRoom[] = [];
            await getAllConferenceRooms(0, totalConferenceRooms);
            setRoomList(totalConferenceRooms);
            if (totalConferenceRooms.length > 0) {
                setBuildingConferenceProductID(
                    totalConferenceRooms[0].partnerProductId!,
                );
            }
            updateState({
                'errorState': {
                    ...errorState, 'roomsError':
                    totalConferenceRooms.length > 0 ? '' : 'Unable to find inventories for the location' 
                }
            });
        } catch (err) {
            let error: any = err;
            console.error(error);
            const errorMessage =
                error?.response?.data?.message ||
                error?.message ||
                'Something went wrong please try again in sometime!';
            // dispatchToast("error", err?.response?.data?.message);
            updateState({
                'errorState': {
                    ...errorState, 'roomsError':
                        errorMessage
                }
            });
        } finally {
            changeLoadersState('roomsLoader', false);
        }
    };

    // Effect that runs all conference APIs
    useEffect(() => {
        if (
            selectedBuilding &&
            'locationUuid' in selectedBuilding &&
            selectedBuilding.locationUuid
        ) {
            fetchConferenceRoomsFromGlobal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBuilding]);



    const handleCabinChange = (
        _: React.ChangeEvent<{}>,
        value: ConferenceRoom | null,
    ) => {
        handleStartTime(undefined);
        if (value) {
            const selectedVariant = variantDetails?.find((variant: any) => {
                return variant.id === value.partnerVariantId;
            });
            updateState({ 'selectedRoom': value, 'selectedConfVariant': selectedVariant });
            return;
        }
        updateState({ 'selectedRoom': undefined });
        setAvailableTimes([]);
    };
    return (
        <Grid container item spacing={2}>
            {!loaders['roomsLoader'] &&
                !errorState['roomsError'] &&
                roomList.length > 0 && (
                    <Grid item xs={12}>
                        <SelectInput
                            size="medium"
                            key="building"
                            options={roomList}
                            getOptionLabel={option =>
                                `${option.name} (${option.capacity} Seater)`
                            }
                            disabled={
                                roomList.length === 0 ||
                                loaders['roomsLoader'] ||
                                !!errorState['roomsError']
                            }
                            label="Select a cabin"
                            name="select-conference-room"
                            onChange={handleCabinChange}
                        />
                    </Grid>
                )}
            {loaders['roomsLoader'] && (
                <Grid item xs={12} className={classes.loaderGrid}>
                    <CircularProgress size={25} style={{ color: '#0000FF' }} />
                </Grid>
            )}
            {!loaders['roomsLoader'] &&
                availableTimes.length === 0 &&
                errorState.roomsError.length > 0 && (
                    <Grid item xs={12} className={classes.loaderGrid}>
                        <span
                            style={{ color: '#990000' }}
                            className="ray-text--body"
                        >
                            {errorState.roomsError}
                        </span>
                    </Grid>
                )}
            <Grid item xs={12}>
                <RayNumInput
                    id="no_of_hours"
                    value={noOfHours}
                    increment={() => {
                        if (noOfHours + 1 > 9) return;
                        updateState({ 'noOfHours': noOfHours + 1, 'bookingStartTime': undefined });
                    }}
                    decrement={() => {
                        if (noOfHours - 1 < 1) return;
                        updateState({ 'noOfHours': noOfHours - 1, 'bookingStartTime': undefined });
                    }}
                    onChange={e => {
                        const value = Number(e.target.value);
                        if (Number.isNaN(value) || value <= 0 || value > 9) return;
                        updateState({ 'noOfHours': value, 'bookingStartTime': undefined });
                    }}
                    label="No. of Hours"
                    fullWidth
                />
            </Grid>
            {!loaders['timesLoader'] && availableTimes.sort().length > 0 && (
                <Grid item xs={12}>
                    <div className={`${classes.variantWrapper} ray-grid`}>
                        {React.Children.toArray(
                            availableTimes.map((slot: any) => (
                                <TimePill
                                    bookingDate={bookingDate as Date}
                                    timeSlot={slot}
                                    key={slot}
                                    selectedStartTime={bookingStartTime}
                                    setStartTime={handleStartTime}
                                />
                            )),
                        )}
                    </div>
                </Grid>
            )}

            {loaders['timesLoader'] && (
                <Grid item xs={12} className={classes.loaderGrid}>
                    <CircularProgress size={25} style={{ color: '#0000FF' }} />
                </Grid>
            )}
            {!loaders['timesLoader'] &&
                availableTimes.length === 0 &&
                errorState.timesError.length > 0 && (
                    <Grid item xs={12} className={classes.loaderGrid}>
                        <span
                            style={{ color: '#990000' }}
                            className="ray-text--body"
                        >
                            {errorState.timesError}
                        </span>
                    </Grid>
                )}
        </Grid>
    )
}

export default ConferenceReusable

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loaderGrid: {
            textAlign: 'center',
            '& svg': {
                color: '#0000FF',
            },
        },
        variantWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
        },
    }),
);