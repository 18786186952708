/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Grid } from '@material-ui/core';
import SelectInput from 'components/MaterialSelect/Autocomplete';
import RayInput from 'components/RayInput';
import React, { useEffect, useState } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useFetchRequest } from 'utils/hooks/useFetchRequest';

export default function index({
  collectiveArray,
  cPrice,
  setCPrice,
  setCPName,
  cPName,
  setOther,
  setCollectiveArray,
  customEventTypeTrigger,
  index,
  classes,
  deleteEventHandler,
  id,
}: any) {
  const [price, setPrice] = useState<number>(0);
  const [innerOther , setInnerOther] = useState<any>('');
  const objIndex = collectiveArray.findIndex(
    (obj: { id: number }) => obj.id === id,
  );
  // useEffects
  useEffect(() => {
    if (collectiveArray[objIndex].title !== 'Others') {
      setInnerOther('');
      collectiveArray[objIndex].otherValue = ''; 
    }
  }, [collectiveArray, objIndex, setInnerOther]);
  // custom hooks
  const [
    eventTypesData,
    eventTypesLoader,
    eventTypesError,
  ] = customEventTypeTrigger
    ? useFetchRequest('/shop/fetch-event-types')
    : useFetchRequest('/shop/fetch-event-types', {
        event_type: 'postpaid',
      });
  const handleChangeEventType = (e: React.ChangeEvent<{}>, value: any) => {
    if (value && value.id) {
      if (index === 0) {
        if (cPName.length > 0) {
          const wordArr = cPName.split(',');
          wordArr[0] = value.name;
          setCPName(wordArr.join(' , '));
        } else {
          setCPName(value.name);
        }
      } else if (cPName.split(',')[index]) {
        const wordArr = cPName.split(',');
        wordArr[index] = value.name;
        setCPName(wordArr.join(' , '));
      } else {
        setCPName(cPName + ' , ' + value.name);
      }
    } else if (value === null) {
      setCPName(
        cPName
          .split(',')
          .filter((item: any, index: any) => index !== objIndex)
          .join(','),
      );
    }
    value
      ? (collectiveArray[objIndex].title = value.name)
      : (collectiveArray[objIndex].title = '');
    setCollectiveArray(collectiveArray);
  };
  return (
    <Grid
      container
      xs={12}
      spacing={2}
      style={{ marginLeft: '1px', marginBottom: '1px', display: 'flex' }}
    >
      <>
        <Grid item xs={7}>
          <SelectInput
            key="Custom Event Types"
            options={
              eventTypesData &&
              eventTypesData.eventTypes &&
              eventTypesData.eventTypes.length > 0
                ? eventTypesData.eventTypes
                : []
            }
            getOptionLabel={option => option.name}
            disabled={eventTypesLoader || eventTypesError}
            label={
              customEventTypeTrigger
                ? 'Select Custom Event Type'
                : 'Select Postpaid Event Type'
            }
            name="select-event-type"
            onChange={handleChangeEventType}
            size="medium"
          />
          {collectiveArray[objIndex].title === 'Others' && (
            <Box marginTop={'7px'}>
              <RayInput
                placeholder="Some Other Event"
                type="text"
                id={'other' + index}
                fullWidth
                autoMargin={false}
                value={innerOther}
                onChange={e => {setInnerOther(e.target.value);collectiveArray[objIndex].otherValue = e.target.value}}
                label={"Enter Other Event"}
                className={classes.mt} />
            </Box>
          )}
        </Grid>
        <Grid item xs={4}>
          <RayInput
            type="text"
            id={'price' + index}
            value={price}
            pattern="[0-9]"
            placeholder="Enter price"
            onChange={e => {
              if (e.target.value !== '') {
                setCPrice(cPrice + parseInt(e.target.value) - price);
              } else {
                setCPrice(cPrice - price);
              }
              setPrice(
                parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0,
              );
              const objIndex = collectiveArray.findIndex(
                (obj: { id: any }) => obj.id === id,
              );
              e.target.value
                ? (collectiveArray[objIndex].price = parseInt(e.target.value))
                : (collectiveArray[objIndex].price = 0);
              setCollectiveArray(collectiveArray);
            }}
            label="Price"
            style={{ flex: 1 }}
            fullWidth
          />
        </Grid>
      </>
      <Grid item xs={1}>
        {index !== 0 && (
          <div
            style={{ cursor: 'pointer', marginTop: '15px' }}
            onClick={deleteEventHandler}
          >
            <RiDeleteBinLine size={20} />
          </div>
        )}
      </Grid>
    </Grid>
  );
}
