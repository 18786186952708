export type ShopDetailPageParams = { shopId: string };
export type BuildingOption = {
  id: string;
  name: string;
  disabled?: boolean;
  micromarket?: string;
  city?: string;
  region?: string;
};
export type ProductNested = {
  handle: string;
  quantityEditorLabel: string;
  variantSelectorLabel: string;
};

export type TypeProduct = {
  [key: string]: ProductNested;
};

export const PRODUCT_TYPES: TypeProduct = {
  'conference-room': {
    handle: 'conference-room',
    quantityEditorLabel: 'SELECT NO. OF HOURS',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
  'gift-card': {
    handle: 'gift-card',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT DENOMINATION',
  },
  'lost-keycard': {
    handle: 'lost-keycard',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT VARIANT',
  },
  parking: {
    handle: 'parking',
    quantityEditorLabel: 'SELECT NO. OF VEHICLES',
    variantSelectorLabel: 'SELECT VEHICLE',
  },
  printing: {
    handle: 'printing',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT VARIANT',
  },
  'conference-room-seat': {
    handle: 'conference-room-seat',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
  'conference-room-credit': {
    handle: 'conference-room-credit',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
};
