import React, { useState } from 'react';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { BsArrowRightShort } from 'react-icons/bs';
import RayButton from 'components/RayButton';
import {
    emailValidateRegex, nameValidateRegex,
} from 'utils/constants';
import { ConferenceRoom } from 'containers/ShopDetailPage/types';
import { format } from 'date-fns';
import axiosGlobal from 'utils/axiosGlobal';
import { useOktaAuth } from '@okta/okta-react';

type Props = {
    name: string;
    email: string | undefined;
    building?: any;
    disableOrderBtn?: boolean;
    selectedRoom: ConferenceRoom;
    noOfHours: number;
    bookingDetails: any;
    resetReservation:any;
};

function CheckoutReservation({
    name,
    email,
    building,
    disableOrderBtn = false,
    selectedRoom,
    bookingDetails,
    noOfHours,
    resetReservation,
}: Props) {
    const classes = useStyles();
    const [btnDisable, setBtnDisable] = useState<boolean>(false);
    const {  setToastType } = useAuthContext()!;
    const { authState} = useOktaAuth();

    const memberCount = selectedRoom?.capacity || undefined;

    const errorCheck = () => {
        if (!building || !building.id) {
            setToastType({
                show: true,
                type: 'info',
                message: 'Select Building',
            });
            return false;
        }

        if (!email || email === '' || !email.match(emailValidateRegex)) {
            setToastType({
                show: true,
                type: 'info',
                message: 'Enter a valid email address',
            });
            return false;
        }

        if (!name || name === '') {
            setToastType({
              show: true,
              type: 'info',
              message:
                'Please enter customer name',
            });
            return false;
          }

        if (!name.match(nameValidateRegex)) {
            setToastType({
              show: true,
              type: 'info',
              message:
                'Please use only characters and avoid extra spaces in name',
            });
            return false;
          }

        if (
            !selectedRoom ||
            !(selectedRoom.globalInventoryId && selectedRoom.id)
        ) {
            setToastType({
                show: true,
                type: 'info',
                message: 'Select Cabin to Proceed',
            });
            return false;
        }
        if (!noOfHours) {
            setToastType({
                show: true,
                type: 'info',
                message: 'Enter Meeting Duration',
            });
            return false;
        }
        if (bookingDetails && !bookingDetails.startTime) {
            setToastType({
                show: true,
                type: 'info',
                message: 'Select Start Time Slot',
            });
            return false;
        }
        return true;
    };

    const makeDraftOrder = async () => {
        if (!errorCheck() ) return;
        setBtnDisable(true);
        try {
            const conferenceBody = {
                booking_start_time: bookingDetails.startTime,
                booking_date: bookingDetails.date,
                no_of_hrs: noOfHours,
                member_count: memberCount,
                buildingId: building && building.id ? building.id : '',
                globalInventoryId: selectedRoom.globalInventoryId,
                customer_email: email,
                customer_name: name,
              };

            await axiosGlobal.post(`/api/v1/admin/conference-rooms/reserve-event-space`, conferenceBody, {
                headers: {
                  Authorization: authState?.accessToken?.accessToken,
                },
              });
            setToastType({
                show: true,
                type: 'success',
                message: 'Room reserved successfully',
            });
            resetReservation();
            setBtnDisable(false);
        } catch (err) {
            const error: any = err;
            console.error(error.response);
            setToastType({
                show: true,
                type: 'error',
                message: error.response.data.message,
            });
            setBtnDisable(false);
        }
    };
   
    return (
        <div className={classes.root}>
            <Grid container spacing={1}>
                <Grid
                    item
                    xs={6}
                    container
                    direction="column"
                    alignItems="flex-start"
                    className={classes.detailCaption}
                >
                    {building && building.id && (
                        <div className="ray-text--body-small">Property:</div>
                    )}
                    {
                        selectedRoom &&
                        selectedRoom.name &&
                        selectedRoom.capacity && (
                            <div className="ray-text--body-small">Event space Room:</div>
                        )}
                    {bookingDetails && bookingDetails.date && (
                        <div className="ray-text--body-small">Meeting Date:</div>
                    )}
                    {bookingDetails && bookingDetails.startTime && (
                        <div className="ray-text--body-small">Meeting Time:</div>
                    )}
                    {noOfHours && noOfHours !== 0 && (
                        <div className="ray-text--body-small">Meeting Duration:</div>
                    )}
                    {name && <div className="ray-text--body-small">Name:</div>}
                    {email && <div className="ray-text--body-small">Email:</div>}
                </Grid>
                <Grid
                    item
                    xs={6}
                    container
                    direction="column"
                    alignItems="flex-start"
                    className={classes.valueCaptions}
                >
                    {building && building.id && (
                        <div className="ray-text--body-small">{building.name}</div>
                    )}
                    {
                        selectedRoom &&
                        selectedRoom.name &&
                        selectedRoom.capacity && (
                            <div className="ray-text--body-small">
                                {selectedRoom.name} ({selectedRoom.capacity} Seater)
                            </div>
                        )}
                    {bookingDetails && bookingDetails.date && (
                        <div className="ray-text--body-small">
                            {format(bookingDetails.date, 'do MMM yyyy')}
                        </div>
                    )}
                    {bookingDetails && bookingDetails.startTime && (
                        <div className="ray-text--body-small">{`${format(
                            bookingDetails.startTime,
                            'h:mm aaaa',
                        )}`}</div>
                    )}

                    {noOfHours && noOfHours !== 0 && (
                        <div className="ray-text--body-small">{`${noOfHours} ${noOfHours === 1 ? 'hr.' : 'hrs.'
                            }`}</div>
                    )}
                    {name && <div className="ray-text--body-small">{name}</div>}
                    {email && <div className="ray-text--body-small">{email}</div>}
                </Grid>
            </Grid>

            <RayButton
                type="button"
                buttonType="primary"
                onClick={makeDraftOrder}
                className={classes.buttonRow}
                disabled={btnDisable || disableOrderBtn}
            >
                {btnDisable ? (
                    <CircularProgress size={20} style={{ color: '#FFFFFF' }} />
                ) : (
                    <>
                        <span>Reserve Event Space</span>
                        <BsArrowRightShort />
                    </>
                )}
            </RayButton>
        </div>
    );
}

export default CheckoutReservation;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            border: '2.5px solid #E3E3E3',
            borderRadius: '4px',
            padding: '1em',
        },
        headText: {
            '& small': {
                color: '#424242',
            },
        },
        detailCaption: {
            '& div': {
                fontWeight: 'bolder',
            },
        },
        valueCaptions: {
            '& div': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '100%',
                textOverflow: 'ellipsis',
            },
        },
        buttonRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            '& svg': {
                fontSize: '1.5em',
            },
        },
    }),
);
