import React from 'react';
import styled from 'styled-components';

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  style?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  buttonType?: 'primary' | 'secondary' | 'tertiary';
  dark?: boolean;
  compact?: boolean;
  danger?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
};

const RayButton = ({
  type = 'button',
  onClick,
  disabled = false,
  style,
  className = '',
  children,
  buttonType = 'primary',
  dark = false,
  compact = false,
  danger = false,
  fullWidth = false,
  loading = false,
}: ButtonProps) => (
  <button
    type={type}
    style={style}
    disabled={disabled || loading}
    onClick={onClick}
    className={`ray-button ray-button--${buttonType} ${
      dark ? 'ray-button--dark' : ''
    } ${compact ? 'ray-button--compact' : ''} ${
      danger ? 'ray-button--danger' : ''
    } ${className}`}
  >
    {children}
  </button>
);

const ButtonWrapper = styled(RayButton)`
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
`;

export default ButtonWrapper;
