import React, { useState } from 'react';
import axios from 'utils/axios';
import { useAuthContext } from 'utils/hooks/useAuthContext';

import { useLocation, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { BsArrowRightShort } from 'react-icons/bs';
import { stringifyPrice } from 'utils/functions/stringifyPrice';
import RayButton from 'components/RayButton';
import { BuildingOption } from './types';
import { capitalizeText } from 'utils/functions/capitalizeText';
import {
  codesValidate,
  companyNameValidate,
  emailValidateRegex,
  nameValidateRegex,
  phoneValidate,
  stateCodes,
} from 'utils/constants';
import { Box, Typography } from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';

type Props = {
  cPName: string;
  collectiveArray: any;
  email: string | undefined;
  name: string | undefined;
  building: BuildingOption | null;
  quantity: any;
  notes: string;
  isBillingGST: boolean;
  address1: string;
  address2: string;
  company: string;
  city: string;
  zip: string | undefined;
  phone: string | undefined;
  gstin: string;
  disableOrderBtn?: boolean;
  stateCode: string;
  gstValid: boolean;
  price: number;
};

function CheckoutCard({
  cPName,
  collectiveArray,
  email,
  name,
  building,
  quantity,
  notes,
  isBillingGST,
  address1,
  address2,
  company,
  city,
  zip,
  phone,
  stateCode,
  gstin,
  gstValid,
  disableOrderBtn = false,
  price,
}: Props) {
  const classes = useStyles();

  const { state }: any = useLocation();
  const { push } = useHistory();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const { userInfo, setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();
  const errorCheck = () => {
    if (!building || !building.id) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Select Building',
      });
      return false;
    }

    if (!email || email === '' || !email.match(emailValidateRegex)) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Enter a valid email address',
      });
      return false;
    }

    if (!name || name === '') {
      setToastType({
        show: true,
        type: 'info',
        message: 'Please enter customer name',
      });
      return false;
    }

    if (!name.match(nameValidateRegex)) {
      setToastType({
        show: true,
        type: 'info',
        message:
          'Please use only characters and avoid extra spaces in customer name',
      });
      return false;
    }

    if (isBillingGST && !gstin) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Enter GST number',
      });
      return false;
    }
    if (isBillingGST && !gstValid && gstin !== '') {
      setToastType({
        show: true,
        type: 'info',
        message: 'Please validate GST number before placing order',
      });
      return false;
    }
    if (!isBillingGST) {
      if (!address1) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter Address 1',
        });
        return false;
      }
      if (!address2) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter Address 2',
        });
        return false;
      }
      if (!company) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter company name',
        });
        return false;
      }

      if (company && company.length<4) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Company name must have a minimum of four characters',
        });
        return false;
      }

      if (!company.match(companyNameValidate)) {
        setToastType({
          show: true,
          type: 'info',
          message:
            'Please use only characters and avoid extra spaces in company name',
        });
        return false;
      }
      if (!city) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter city name',
        });
        return false;
      }
      if (!city.match(nameValidateRegex)) {
        setToastType({
          show: true,
          type: 'info',
          message:
            'Please use only characters and avoid extra spaces in city name',
        });
        return false;
      }
      if (!zip) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter zip code',
        });
        return false;
      }
      if (zip.length !== 6) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter a valid zip code',
        });
        return false;
      }
      if (!phone) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter phone number',
        });
        return false;
      }
      if (!phone?.match(phoneValidate)) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter a valid phone number',
        });
        return false;
      }
      // if(stateCode && !stateCode.match(codesValidate))
      // {
      //   setToastType({
      //     show: true,
      //     type: 'info',
      //     message: 'State code must be in capital letters.',
      //   });
      //   return false;
      // }
      if (
        stateCode &&
        (!stateCodes.includes(stateCode) || !stateCode.match(codesValidate))
      ) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter a valid state code',
        });
        return false;
      }
      // if(stateCode && !stateCodes.includes(stateCode) && !stateCode.match(codesValidate))
      // {
      //   setToastType({
      //     show: true,
      //     type: 'info',
      //     message: 'State code not valid.',
      //   });
      //   return false;
      // }
    }
    return true;
  };
  // specify in clear way for title , price
  const checkFields = () => {
    let err = false;
    collectiveArray.forEach((item: {
      otherValue: any; title: any; price: any 
}) => {
      if (!item.title) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Enter Product Name',
        });
        err = true;
      } else if (item.title === 'Others' && !item.otherValue) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Enter Other Product Event',
        });
        err = true;
      } else if (!item.price) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Enter Price',
        });
        err = true;
      }
    });
    return err;
  };

  const makeDraftOrder = async () => {
    if (!errorCheck() || checkFields()) return;
    setBtnDisable(true);

    const draftNotesBody = collectiveArray.map((item: any) => ({
      handle: capitalizeText(item.title),
      building_title: building && building.name,
      building_id: building && building.id ? building.id : '',
      micromarket: building ? building.micromarket : '',
      city: building ? building.city : '',
      building_zone: building ? building.region : '',
      isDashboard: true,
      custom_product_info: [{ productName: capitalizeText(item.title) }],
      eventTypeOthersDetails: item.otherValue
    }));

    if (state && state.addOnState && state.addOnState.customerEmail) {
      draftNotesBody.parent_order_no = state.addOnState.orderNo;
      draftNotesBody.booking_firebase_id = state.addOnState.bookingFirebaseId;
    }
    const lineItemsArray = collectiveArray.map(
      (item: { title: any; price: any; taxable: any; quantity: any }) => ({
        title: item.title,
        price: item.price,
        taxable: item.taxable,
        quantity: item.quantity,
      }),
    );
    try {
      const body = {
        line_items: lineItemsArray,
        dashboard_order_type: 'postpaid-event',
        customer_email: email,
        customer_name: name,
        notes: notes,
        order_creator: userInfo ? userInfo.id : null,
        buildingId: building && building.id ? building.id : '',
        draft_notes: JSON.stringify(draftNotesBody),
        isBillingManual: !isBillingGST,
        gstin: gstin,
        ...(!isBillingGST && {
          userBillingDetails: {
            address1: address1,
            address2: address2,
            company: company,
            city: city,
            zip: zip,
            phone: phone,
            state_code: stateCode,
          },
        }),
      };
     
      await axios.post(`shop/create-postpaid-order`, body, {
        headers: {
          Authorization: authState?.accessToken?.accessToken,
        },
      });
      setToastType({
        show: true,
        type: 'success',
        message: 'Payment invoice sent to customer',
      });
      push('/shop-purchases');
      setBtnDisable(false);
    } catch (err) {
      const error: any = err;
      console.error(error.response);
      setToastType({
        show: true,
        type: 'error',
        message: error.response.data.message,
      });
      setBtnDisable(false);
    }
  };
  const getTotalPrice = () => {
    return price ? stringifyPrice(Number(price)) : '₹0';
  };
  const getSubtotal = () => {
    return price ? stringifyPrice(Number(price)) : '₹0';
  };

  return (
    <div className={classes.root}>
      <div className={classes.headText}>
        <h4 className="ray-text--h4">{`Total: ${getTotalPrice()}`}</h4>
      </div>
      <Box style={{ flexGrow: 1, overflow: 'hidden' }}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item className={classes.detailCaption}>
            {cPName && <div className="ray-text--body-small">Event Name:</div>}
          </Grid>
          <Grid item xs className={classes.valueCaptions}>
            <Typography variant="caption">{cPName}</Typography>
          </Grid>
        </Grid>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item className={classes.detailCaption}>
            {building && building.id && (
              <div className="ray-text--body-small">Property:</div>
            )}
          </Grid>
          <Grid item xs className={classes.valueCaptions}>
            {building && building.id && (
              <div className="ray-text--body-small">{building.name}</div>
            )}
          </Grid>
        </Grid>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item className={classes.detailCaption}>
            {quantity && quantity !== 0 && (
              <div className="ray-text--body-small">Quantity:</div>
            )}
          </Grid>
          <Grid item xs className={classes.valueCaptions}>
            {quantity && quantity !== 0 && (
              <div className="ray-text--body-small">{quantity}</div>
            )}
          </Grid>
        </Grid>
        {email && (
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item className={classes.detailCaption}>
              {email && <div className="ray-text--body-small">Email:</div>}
            </Grid>
            <Grid item xs className={classes.valueCaptions}>
              {email && <div className="ray-text--body-small">{email}</div>}
            </Grid>
          </Grid>
        )}
        {name && (
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item className={classes.detailCaption}>
              {name && <div className="ray-text--body-small">Name:</div>}
            </Grid>
            <Grid item xs className={classes.valueCaptions}>
              {name && <div className="ray-text--body-small">{name}</div>}
            </Grid>
          </Grid>
        )}
        {gstin && (
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item className={classes.detailCaption}>
              {gstin && <div className="ray-text--body-small">GSTIN:</div>}
            </Grid>
            <Grid item xs className={classes.valueCaptions}>
              {gstin && <div className="ray-text--body-small">{gstin}</div>}
            </Grid>
          </Grid>
        )}
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item className={classes.detailCaption}>
            <div className="ray-text--body-small">Sub total:</div>
          </Grid>
          <Grid item xs className={classes.valueCaptions}>
            <div className="ray-text--body-small">{getSubtotal()}</div>
          </Grid>
        </Grid>
      </Box>

      <RayButton
        type="button"
        buttonType="primary"
        onClick={makeDraftOrder}
        className={classes.buttonRow}
        disabled={btnDisable || disableOrderBtn}
      >
        {btnDisable ? (
          <CircularProgress size={20} style={{ color: '#FFFFFF' }} />
        ) : (
          <>
            <span>Place Order & Send Invoice</span>
            <BsArrowRightShort />
          </>
        )}
      </RayButton>
    </div>
  );
}

export default CheckoutCard;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      border: '2.5px solid #E3E3E3',
      borderRadius: '4px',
      padding: '1em',
    },
    headText: {
      '& small': {
        color: '#424242',
      },
    },
    detailCaption: {
      '& div': {
        fontWeight: 'bolder',
      },
    },
    valueCaptions: {
      '& div': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
        textOverflow: 'ellipsis',
      },
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '& svg': {
        fontSize: '1.5em',
      },
    },
  }),
);
