import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { useFetchRequest } from 'utils/hooks/useFetchRequest';

import { H1 } from 'components/Typography';

import RayInput from 'components/RayInput';
import SelectInput from 'components/MaterialSelect/Autocomplete';
import { BuildingOption } from './types';
import CheckoutCard from './CheckoutCard';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import GSTValidate from 'containers/ShopDetailPage/GSTValidate';
import EventhandlerComponent from 'components/EventhandlerComponent';

function CustomProductPurchasePage() {
  // state variables
  const [index , setIndex] = useState<number>(0);
  const [quantity , setQuantity] = useState<number>(1);
  const [email, setEmail] = useState<string>('');
  const [showNotes, toggleNotes] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const [gstin, setGstin] = useState<string>('');
  const [gstinError, setGstinError] = useState('');
  const [cPrice, setCPrice] = useState<number>(0);
  const [gstValid, setGstValid] = useState<boolean>(false);
  const [cPName, setCPName] = useState<string>('');
  const [customEventTypeTrigger] = useState<boolean>(true);
  const [collectiveArray , setCollectiveArray] = useState<any[]>([{
    id:0,
    title:'',
    price:0,
    taxable:true,
    quantity:1 ,
    otherValue: ''
  }]);
  
  const [
    selectedBuilding,
    setSelectedBuilding,
  ] = useState<BuildingOption | null>(null);
  
  // third party hooks
  const classes = useStyles();
  const { state }: any = useLocation();

  // custom hooks
  const [buildingsData, buildingsLoader, buildingsError] = useFetchRequest(
    '/buildings/get-user-buildings',
  );

  // effects

  useEffect(() => {
    if (state && state.addOnState && state.addOnState.customerEmail) {
      setEmail(state.addOnState.customerEmail);
      if (buildingsData && buildingsData.length > 0) {
        const foundBuilding = buildingsData.find(
          (item: any) => item.name === state.addOnState.buildingName,
        );
        setSelectedBuilding(foundBuilding);
      }
    }
  }, [state, buildingsData]);


  const handleChangeBuilding = (
    event: React.ChangeEvent<{}>,
    value: BuildingOption | null,
  ) => {
    if (value && value.id) setSelectedBuilding(value);
    else if (value === null) {
      setSelectedBuilding(null);
    }
  };
  
  const addEventHandler = () => {
    const id = index +1;
    setQuantity(quantity+1)
    setIndex(id);
      setCollectiveArray((prev) => [...prev , {
        id : id ,
        title:'',
        price:0,
        taxable:true,
        quantity:1,
        otherValue : ''
    }])
  }
  const deleteEventHandler = (index:number , id:number) => {
    const objIndex = collectiveArray.findIndex(((obj: { id: number; }) => obj.id === id))
    setCPrice(cPrice - collectiveArray[objIndex].price)
    const productArr = cPName.split(',')
    const finalProductArr = productArr.slice(0,index).concat(productArr.slice(index+1))
    setCPName(finalProductArr.join(','))
    setQuantity(quantity-1)
    const arr = collectiveArray.filter((item)=>item.id !== id)
    setCollectiveArray(arr)
  }
  return (
    <>
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12}>
          {state && state.addOnState && state.addOnState.customerEmail && (
            <div className={classes.backgroundYellow}>
              <small className="ray-text--body ">
                <b>
                  You are currently creating an add-on order associated with
                  order #{state.addOnState.orderNo}
                  <br />
                  If you do not want to associate this order with an existing
                  order, please visit the <Link to="/shop">Shop</Link> and
                  continue.
                </b>
              </small>
            </div>
          )}
          <H1>Custom Events</H1>
        </Grid>

        <Grid item xs={5}>
         
            <Grid item xs={11}>
              <SelectInput
                key="building"
                options={
                  buildingsData && buildingsData.length > 0
                    ? buildingsData.filter(
                        ({ disabled }: BuildingOption) => !disabled,
                      )
                    : []
                }
                getOptionLabel={option => option.name}
                disabled={buildingsLoader || buildingsError}
                label="Select Building"
                name="select-building"
                onChange={handleChangeBuilding}
              />
            </Grid>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justify="flex-start"
            spacing={2}
            className={classes.mt}
          >

            {collectiveArray && collectiveArray.map((obj:any,index)=> 
            <EventhandlerComponent 
            cPrice={cPrice} 
            setCPrice={setCPrice} 
            setCPName = {setCPName}
            cPName = {cPName}
            collectiveArray={collectiveArray}
            setCollectiveArray={setCollectiveArray}
            customEventTypeTrigger={customEventTypeTrigger}
            index={index}
            id={obj.id}
            key={obj.id}
            classes
            deleteEventHandler={()=>{deleteEventHandler(index,obj.id)
            }}/>
            )}
    
            <Grid item xs={11}>
                    <p
                    onClick={addEventHandler}
                    className={classes.addNoteBtn}
                  >
                   {collectiveArray.length !== 5 ? '+ Add event' : '' }
                  </p>
                </Grid>
            <Grid item xs={11}>
              <RayInput
                placeholder="arya.stark@wework.co.in"
                id="email__id"
                fullWidth
                autoMargin={false}
                value={email}
                onChange={e => setEmail(e.target.value)}
                label="Customer Email"
              />
              <small className="ray-text--body-x-small">
                This is the email that the payment link and invoice will be sent
                to.
              </small>
            </Grid>
            <Grid item xs={11}>
              <GSTValidate
                gstin={gstin}
                setGstin={setGstin}
                gstinError={gstinError}
                setGstinError={setGstinError}
                gstValid={gstValid}
                setGstValid={setGstValid}
              />
            </Grid>
            <Grid item xs={11}>
              <p
                onClick={() => toggleNotes(!showNotes)}
                className={classes.addNoteBtn}
              >
                {!showNotes ? '+ Add Notes' : 'Hide Notes'}
              </p>
              {showNotes && (
                <div className="ray-text-area">
                  <textarea
                    rows={4}
                    className="ray-text-area__input"
                    id="notes"
                    value={notes}
                    onChange={e =>
                      setNotes(
                        e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
                      )
                    }
                  />
                  <label className="ray-text-area__label" htmlFor="notes">
                    Notes (optional)
                  </label>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <CheckoutCard
            collectiveArray={collectiveArray}
            price={cPrice}
            email={email}
            notes={notes}
            building={selectedBuilding}
            quantity={quantity}
            gstin={gstin.toUpperCase()}
            productName={cPName}
            disableOrderBtn={Boolean(gstinError.length)}
            gstValid={gstValid}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default CustomProductPurchasePage;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundYellow: {
      backgroundColor: '#FFD500',
      borderRadius: '2px',
      padding: '0.5em',
      marginBottom: '1em',
    },

    variantWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    addNoteBtn: {
      fontSize: '1.1em',
      color: '#0000FF',
      marginBottom: '1em',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    loaderGrid: {
      textAlign: 'center',
      '& svg': {
        color: '#0000FF',
      },
    },
    mt: {
      marginTop: '0.6em',
    },
    fieldError: {
      color: '#CC0000',
    },
  }),
);
