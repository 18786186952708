import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import set from 'date-fns/set';
import differenceInHours from 'date-fns/differenceInHours';
import moment from 'moment';
import add from 'date-fns/add';

// let count = 1;

export const shouldDisableDate = (date: MaterialUiPickersDate) => {
  if (!date) return true;

  const weekday = date.weekday();
  if (weekday < 1 || weekday > 5) {
    return true;
  }
  const [presentHour, presentMinutes] = [
    new Date().getHours(),
    new Date().getMinutes(),
  ];

  const modifiedDate = set(date.toDate(), {
    hours: presentHour,
    minutes: presentMinutes,
  });

  const presentDate = set(new Date(), { hours: 18, minutes: 0, seconds: 0 });
  const diff = differenceInHours(presentDate, modifiedDate);

  if (diff <= 0 && diff >= -6) {
    return true;
  }
  return false;
};

let recurseCount = 0;

export const getBookingDateString = (todaysDate: Date): Date => {
  if (!shouldDisableDate(moment(todaysDate))) {
    recurseCount = 0;
    return todaysDate;
  } else {
    if (recurseCount >= 60) {
      recurseCount = 0;
      return todaysDate;
    }
    recurseCount++;
    const nextDay = add(todaysDate, { days: 1 });
    return getBookingDateString(nextDay);
  }
};
