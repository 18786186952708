export const emailValidateRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const emailInputRegex = /[*|":<>[\]{}`\\()';&$=\s+]/g;
export const numberInputRegex = /[^0-9]/g;
export const leadIdRegex = /[^a-zA-Z0-9-]/g;
export const nameValidateRegex = /^[a-zA-Z]+( [a-zA-Z]+)*$/i;
export const addressValidateRegex = /^[a-zA-Z0-9'.\-,]+( [a-zA-Z0-9'.\-,]+)*$/i;
export const companyNameValidate = /^[a-zA-Z0-9!@#$%^*_|&-]+( [a-zA-Z0-9!@#$%^*_|&-]+)*$/i;
export const phoneValidate = /^[6-9][0-9]{9}$/i;
export const codesValidate = /^[A-Z]{2}$/gm;
export const stateCodes = [
  'AD',
  'AR',
  'AS',
  'BR',
  'CG',
  'DL',
  'GA',
  'GJ',
  'HR',
  'HP',
  'JK',
  'JH',
  'KA',
  'KL',
  'LD',
  'MP',
  'MH',
  'MN',
  'ML',
  'MZ',
  'NL',
  'OD',
  'PY',
  'PB',
  'RJ',
  'SK',
  'TN',
  'TS',
  'TR',
  'UP',
  'UK',
  'WB',
  'AN',
  'CH',
  'LA',
  'DD',
  'DN',
];

export const availabilityMapTemplate = [
  '0900',
  '0930',
  '1000',
  '1030',
  '1100',
  '1130',
  '1200',
  '1230',
  '1300',
  '1330',
  '1400',
  '1430',
  '1500',
  '1530',
  '1600',
  '1630',
  '1700',
  // "1730",
];
