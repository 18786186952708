import React, { useState } from 'react';
import axios from 'utils/axios';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { useLocation, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { BsArrowRightShort } from 'react-icons/bs';
import { stringifyPrice } from 'utils/functions/stringifyPrice';
import RayButton from 'components/RayButton';
import { BuildingOption } from './types';
import { capitalizeText } from 'utils/functions/capitalizeText';
import { emailValidateRegex } from 'utils/constants';
import { Box, Typography } from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';

type Props = {
  email: string | undefined;
  productName: string;
  building: BuildingOption | null;
  quantity: any;
  notes: string;
  gstin: string | undefined;
  price: number | undefined;
  disableOrderBtn?: boolean;
  gstValid: boolean;
  collectiveArray: any;
};

function CheckoutCard({
  collectiveArray,
  email,
  building,
  quantity,
  notes,
  gstin,
  price,
  productName,
  disableOrderBtn = false,
  gstValid,
}: Props) {
  const classes = useStyles();

  const { state }: any = useLocation();
  const { push } = useHistory();

  const { userInfo, setToastType } = useAuthContext()!;
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const { authState} = useOktaAuth();

  const errorCheck = () => {
    if (!building?.id) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Select Building',
      });
      return false;
    }

    if (!email || email === '' || !email.match(emailValidateRegex)) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Enter a valid email address',
      });
      return false;
    }

    if (!gstValid && gstin !== '') {
      setToastType({
        show: true,
        type: 'info',
        message: 'Please validate GST number before placing order',
      });
      return false;
    }
    return true;
  };
  const checkFields = () => {
    let err = false;
    collectiveArray.forEach((item: {
      otherValue: any; title: any; price: any 
}) => {
      if (!item.title) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Enter Product Name',
        });
        err = true;
      } else if (item.title === 'Others' && !item.otherValue) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Enter Other Product Event',
        });
        err = true;
      } else if (!item.price) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Enter Price',
        });
        err = true;
      }
    });
    return err;
  };
  const makeDraftOrder = async () => {
    if (!errorCheck() || checkFields()) return;
    setBtnDisable(true);

    const draftNotesBody: any = collectiveArray.map((item: any) => ({
      handle: capitalizeText(item.title),
      building_title: building && building.name,
      building_id: building && building.id ? building.id : '',
      micromarket: building ? building.micromarket : '',
      city: building ? building.city : '',
      building_zone: building ? building.region : '',
      isDashboard: true,
      custom_product_info: [{ productName: capitalizeText(item.title) }],
      eventTypeOthersDetails: item.otherValue
    }));

    if (state && state.addOnState && state.addOnState.customerEmail) {
      draftNotesBody.parent_order_no = state.addOnState.orderNo;
      draftNotesBody.booking_firebase_id = state.addOnState.bookingFirebaseId;
    }
    const lineItemsArray = collectiveArray.map(
      (item: { title: any; price: any; taxable: any; quantity: any }) => ({
        title: item.title,
        price: item.price,
        taxable: item.taxable,
        quantity: item.quantity,
      }),
    );
    try {
      const body = {
        line_items: lineItemsArray,
        dashboard_order_type: 'custom-event',
        customer_email: email,
        notes: notes,
        order_creator: userInfo ? userInfo.id : null,
        buildingId: building && building.id ? building.id : '',
        draft_notes: JSON.stringify(draftNotesBody),
        gstin: '',
      };
      if (gstin) body.gstin = gstin;
      

      await axios.post(`shop/create-draft-order`, body, {
        headers: {
          Authorization: authState?.accessToken?.accessToken,
        },
      });
      setToastType({
        show: true,
        type: 'success',
        message: 'Payment link sent to customer',
      });
      push('/shop-purchases');
      setBtnDisable(false);
    } catch (err) {
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message: error.response.data.message,
      });
      setBtnDisable(false);
    }
  };
  const getTotalPrice = () => {
    return price ? stringifyPrice(Number(price)) : '₹0';
  };
  const getSubtotal = () => {
    return price ? stringifyPrice(Number(price)) : '₹0';
  };
  return (
    <div className={classes.root}>
      <div className={classes.headText}>
        <h4 className="ray-text--h4">{`Total: ${getTotalPrice()}`}</h4>
      </div>

      <Box style={{ flexGrow: 1, overflow: 'hidden' }}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item className={classes.detailCaption}>
            {productName && (
              <div className="ray-text--body-small">Event Name:</div>
            )}
          </Grid>
          <Grid item xs className={classes.valueCaptions}>
            <Typography variant="caption">{productName}</Typography>
          </Grid>
        </Grid>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item className={classes.detailCaption}>
            {building && building.id && (
              <div className="ray-text--body-small">Property:</div>
            )}
          </Grid>
          <Grid item xs className={classes.valueCaptions}>
            {building && building.id && (
              <div className="ray-text--body-small">{building.name}</div>
            )}
          </Grid>
        </Grid>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item className={classes.detailCaption}>
            {quantity && quantity !== 0 && (
              <div className="ray-text--body-small">Quantity:</div>
            )}
          </Grid>
          <Grid item xs className={classes.valueCaptions}>
            {quantity && quantity !== 0 && (
              <div className="ray-text--body-small">{quantity}</div>
            )}
          </Grid>
        </Grid>
        {email && (
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item className={classes.detailCaption}>
              {email && <div className="ray-text--body-small">Email:</div>}
            </Grid>
            <Grid item xs className={classes.valueCaptions}>
              {email && <div className="ray-text--body-small">{email}</div>}
            </Grid>
          </Grid>
        )}
        {gstin && (
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item className={classes.detailCaption}>
              {gstin && <div className="ray-text--body-small">GSTIN:</div>}
            </Grid>
            <Grid item xs className={classes.valueCaptions}>
              {gstin && <div className="ray-text--body-small">{gstin}</div>}
            </Grid>
          </Grid>
        )}
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item className={classes.detailCaption}>
            <div className="ray-text--body-small">Sub total:</div>
          </Grid>
          <Grid item xs className={classes.valueCaptions}>
            <div className="ray-text--body-small">{getSubtotal()}</div>
          </Grid>
        </Grid>
      </Box>

      <RayButton
        type="button"
        buttonType="primary"
        onClick={makeDraftOrder}
        className={classes.buttonRow}
        disabled={btnDisable || disableOrderBtn}
      >
        {btnDisable ? (
          <CircularProgress size={20} style={{ color: '#FFFFFF' }} />
        ) : (
          <>
            <span>Place Order & Send Invoice</span>
            <BsArrowRightShort />
          </>
        )}
      </RayButton>
    </div>
  );
}

export default CheckoutCard;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      border: '2.5px solid #E3E3E3',
      borderRadius: '4px',
      padding: '1em',
    },
    headText: {
      '& small': {
        color: '#424242',
      },
    },
    detailCaption: {
      '& div': {
        fontWeight: 'bolder',
      },
    },
    valueCaptions: {
      '& div': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
        textOverflow: 'ellipsis',
      },
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '& svg': {
        fontSize: '1.5em',
      },
    },
  }),
);
