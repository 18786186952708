import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CardWrapper = styled.div`
  color: #000;
`;

type Props = {
  detail: any;
};

function ShopCard({ detail }: Props) {
  const { state }: any = useLocation();
  return (
    <CardWrapper>
      <div className="ray-card">
        <div className="ray-card__image ray-image ray-image--16by9">
          <img
            src={
              detail.images && detail.images.length > 0 && detail.images[0].src
                ? detail.images[0].src
                : 'https://via.placeholder.com/150'
            }
            alt="card graphic"
          />
        </div>

        <div className="ray-card__content">
          <div className="ray-card__heading">
            {detail.title === 'Conference Room Credit'
              ? 'Conference Room Seat'
              : detail.title}
          </div>
          <Link
            className="ray-button ray-button--primary"
            style={{ width: '100%' }}
            to={
              state && state.addOnState
                ? {
                    pathname: `/shop/${
                      detail?.handle === 'gift-card'
                        ? process.env.REACT_APP_GIFTCARD_SHOP_ID
                        : detail.id
                    }`, // hardcoded shop id for myhq transition of gift card.
                    state: { addOnState: state.addOnState },
                  }
                : `/shop/${
                    detail?.handle === 'gift-card'
                      ? process.env.REACT_APP_GIFTCARD_SHOP_ID
                      : detail.id
                  }` // hardcoded shop id for myhq transition of gift card.
            }
          >
            Select
          </Link>
        </div>
      </div>
    </CardWrapper>
  );
}

export default ShopCard;
