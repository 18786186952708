import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ShopCard from './ShopCard';
import { useFetchRequest } from 'utils/hooks/useFetchRequest';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import Loader from 'components/Loader';

function ShopPage() {
  const { state }: any = useLocation();

  const classes = useStyles();

  const [data, loader, error] = useFetchRequest(
    '/shop/fetch-products-by-collection',
    {
      collection_id: process.env.REACT_APP_SHOP_COLLECTION_ID,
    },
  );

  const { setToastType, userInfo } = useAuthContext()!;

  if (loader) return <Loader maxHeight />;

  if (error) return <p>Error Fetching Collection's Products</p>;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {state && state.addOnState && state.addOnState.customerEmail && (
            <div className={classes.backgroundYellow}>
              <small className="ray-text--body ">
                <b>
                  You are currently creating an add-on order associated with
                  order #{state.addOnState.orderNo}
                  <br />
                  If you do not want to associate this order with an existing
                  order, please visit the <Link to="/shop">Shop</Link> and
                  continue.
                </b>
              </small>
            </div>
          )}
        </Grid>
        {data &&
          data.products &&
          data.products.length > 0 &&
          data.products
            .filter(
              ({ handle }: any) =>
                !['conference-room', 'conference-room-seat'].includes(handle),
            )
            .map((item: any, index: number) =>
              item.title === 'Postpaid Events' ? (
                userInfo.is_postpaid_user ? (
                  <Grid key={index.toString()} item xs={6} md={3}>
                    <ShopCard detail={item} />
                  </Grid>
                ) : null
              ) : (
                <Grid key={index.toString()} item xs={6} md={3}>
                  <ShopCard detail={item} />
                </Grid>
              ),
            )}
      </Grid>
      {error &&
        setToastType({
          show: true,
          type: 'error',
          message: 'Error occurred while fetching shops',
        })}
    </>
  );
}

export default ShopPage;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    backgroundYellow: {
      backgroundColor: '#FFD500',
      borderRadius: '2px',
      padding: '0.5em',
      marginBottom: '1em',
    },
  }),
);
