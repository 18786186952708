export type ShopDetailPageParams = { shopId: string };
export type BuildingOption = {
  id: string;
  name: string;
  disabled?: boolean;
  micromarket?: string;
  city?: string;
  region?: string;
  locationUuid?: string;
  partnerProductId?: number;
};
export type ProductNested = {
  handle: string;
  quantityEditorLabel: string;
  variantSelectorLabel: string;
};

export type TypeProduct = {
  [key: string]: ProductNested;
};

export const PRODUCT_TYPES: TypeProduct = {
  'conference-room': {
    handle: 'conference-room',
    quantityEditorLabel: 'SELECT NO. OF HOURS',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
  'gift-card': {
    handle: 'gift-card',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT DENOMINATION',
  },
  'lost-keycard': {
    handle: 'lost-keycard',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT VARIANT',
  },
  parking: {
    handle: 'parking',
    quantityEditorLabel: 'SELECT NO. OF VEHICLES',
    variantSelectorLabel: 'SELECT VEHICLE',
  },
  printing: {
    handle: 'printing',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT VARIANT',
  },
  'conference-room-seat': {
    handle: 'conference-room-seat',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
  'conference-room-credit': {
    handle: 'conference-room-credit',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
  'custom-events': {
    handle: 'custom-events',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
  'postpaid-events': {
    handle: 'postpaid-events',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
  'ode-events': {
    handle: 'ode-purchases',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
  'security-deposit': {
    handle: 'security-deposit',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT VARIANT',
  },
  'event-space': {
    handle: 'event-space',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT VARIANT',
  },
};

interface LocationData {
  id: string;
  name: string;
  phoneNumber: string;
  addresses: [
    {
      addressType: string;
      line1: string;
      line2: string;
      city: string;
      country: string;
      state: string;
      zip: string;
      __typename: string;
    }
  ];
  __typename: string;
}

export interface ConferenceRoom {
  id: string;
  name: string;
  capacity: number;
  productId?: number;
  variantId?: number;
  partnerProductId?: number;
  partnerVariantId?: number;
  __typename: string;
  globalInventoryId?: string;
  location: LocationData | LocationData[];
}
interface IPagination {
  limit: number;
  pageNumber: number;
  totalPages: number;
}

export interface ConferenceRoomResponseData {
  success: boolean;
  message: string;
  data: {
    pagination: IPagination;
    result: ConferenceRoom[];
  };
}
