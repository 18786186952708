import React, { useState,useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { H1 } from 'components/Typography';
import SelectInput from 'components/MaterialSelect/Autocomplete';
// import CheckoutCard from './CheckoutCard';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import Form from './Form';
import {
  CONF_ROOM_PROD_ID,
  LOST_KEYCARD_PROD_ID,
  PRINTING_PROD_ID,
} from './types';
import { useFetchRequest } from 'utils/hooks/useFetchRequest';
import { CircularProgress } from '@material-ui/core';

function OdePurchasesPage() {
  // third party hooks
  const classes = useStyles();
  const { setToastType } = useAuthContext()!;

  const ODE_OPTIONS = [
    {
      option: 'Conference room',
      id: CONF_ROOM_PROD_ID,
    },
    {
      option: 'Lost Keycard',
      id: LOST_KEYCARD_PROD_ID,
    },
    { option: 'Printing', id: PRINTING_PROD_ID },
  ];
  // state variables
  const [enterprises, loader, error] = useFetchRequest(
    '/api/automation/enterprise/',
    undefined,
    {
      useODE: true,
    },
  );
  const [purchase, setPurchase] = useState<any>(null);
  const [enterprise, setEnterprise] = useState<any>(null);
  // if (error) {
  //   const errorMessage = error?.response?.data?.message || error?.message;
  //   setToastType({ show: true, type: 'error', message: errorMessage });
  // }
  useEffect(() => {
    if(error){
      const errorMessage = error?.response?.data?.message || error?.message;
      setToastType({ show: true, type: 'error', message: errorMessage });
    }   
  }, [error,setToastType])
  

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    setEnterprise(value);
  };

  const handlePurchaseChange = (event: React.ChangeEvent<{}>, value: any) => {
    setPurchase(value);
  };

  return (
    <>
      <Grid container spacing={3} justify="space-between" className={classes.removeMargin}>
        <Grid item xs={12}>
          <H1>ODE Purchases</H1>
        </Grid>
        <Grid item xs={5}>
          {loader && (
            <Grid item xs={12} className={classes.loaderGrid}>
              <CircularProgress size={25} style={{ color: '#0000FF' }} />
            </Grid>
          )}
          {!loader && enterprises && enterprises.length > 0 && (
            <Grid container item xs={12}>
              <SelectInput
                options={enterprises}
                getOptionLabel={option => option.enterprise_name}
                label="Select Enterprise"
                name="enterprise"
                disabled={loader}
                onChange={handleChange}
              />
            </Grid>
          )}
          {error && !loader && (
            <Grid item xs={12} className={classes.loaderGrid}>
              <span style={{ color: '#990000' }} className="ray-text--body">
                {error?.response?.data?.message || error?.message}
              </span>
            </Grid>
          )}
          <Grid container item xs={12} className={classes.mt}>
            <SelectInput
              options={ODE_OPTIONS}
              getOptionLabel={option => option.option}
              label="Select product type"
              name="product type"
              disabled={loader || enterprises?.length === 0 || !enterprise}
              onChange={handlePurchaseChange}
            />
          </Grid>
        </Grid>
        <Grid className={classes.formStyle}>
          {enterprise && purchase && (
            <Form id={purchase.id} enterprise={enterprise} />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default OdePurchasesPage;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundYellow: {
      backgroundColor: '#FFD500',
      borderRadius: '2px',
      padding: '0.5em',
      marginBottom: '1em',
    },

    formStyle: {
      marginTop: '12px',
      padding: '0 12px',
      width: '100%',
    },
    variantWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    addNoteBtn: {
      fontSize: '1.1em',
      color: '#0000FF',
      marginBottom: '1em',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    loaderGrid: {
      textAlign: 'center',
      '& svg': {
        color: '#0000FF',
      },
    },
    mt: {
      marginTop: '0.6em',
    },
    fieldError: {
      color: '#CC0000',
    },
    removeMargin: {
      margin: 0
    }
  }),
);
