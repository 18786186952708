import React, { useMemo, useState } from 'react';
import axios from 'utils/axios';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { format } from 'date-fns';
import { useLocation, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { BsArrowRightShort } from 'react-icons/bs';
import { stringifyPrice } from 'utils/functions/stringifyPrice';
import RayButton from 'components/RayButton';
import { PRODUCT_TYPES, BuildingOption, ConferenceRoom } from './types';
import {
  codesValidate,
  emailValidateRegex,
  nameValidateRegex,
  phoneValidate,
  stateCodes,
} from 'utils/constants';
import axiosGlobal from 'utils/axiosGlobal';
import { useOktaAuth } from '@okta/okta-react';

type Props = {
  email: string | undefined;
  name?: string | null;
  address?: {
    address1: string;
    address2: string;
    city: string;
    zip: string;
    phone: string;
    stateCode: string;
  } | null;
  description?: string | null;
  periodOfDepositDate?: any;
  building: BuildingOption | null;
  // memberCount: number | undefined;
  noOfHours: number;
  bookingDetails: { date: any; startTime?: any } | undefined;
  selectedVariant: any;
  quantity: any;
  productType: string;
  notes: string;
  gstin?: string | undefined;
  disableOrderBtn?: boolean;
  // creditCount: number;
  selectedRoom: ConferenceRoom;
  price: number | undefined;
  gstValid: boolean;
  hasSecurityDespositFormErrors?: (() => boolean) | null;
};
const CONFERENCE_HANDLE = PRODUCT_TYPES['conference-room-credit'].handle;
const PARKING_HANDLE = PRODUCT_TYPES['parking'].handle;
const SECURITY_DEPOSIT_HANDLE = PRODUCT_TYPES['security-deposit'].handle;

function CheckoutCard({
  email,
  name = null,
  address = null,
  description = null,
  periodOfDepositDate = undefined,
  building,
  // memberCount,
  bookingDetails,
  quantity,
  selectedVariant,
  productType,
  notes,
  noOfHours,
  gstin,
  disableOrderBtn = false,
  // creditCount,
  selectedRoom,
  price,
  gstValid,
}: Props) {
  const memberCount = selectedRoom?.capacity || undefined;

  const classes = useStyles();
  const isProductConference = productType === CONFERENCE_HANDLE;
  const isSecurityDeposit = productType === SECURITY_DEPOSIT_HANDLE;
  const { state }: any = useLocation();
  const { push } = useHistory();

  const { userInfo, setToastType } = useAuthContext()!;

  const {authState} = useOktaAuth();

  const [btnDisable, setBtnDisable] = useState<boolean>(false);

  const errorCheck = () => {
    if (
      !isProductConference &&
      (!selectedVariant || (selectedVariant && !selectedVariant.id))
    ) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Select Variant to Proceed',
      });
      return false;
    }
    if (!building?.id) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Select Building',
      });
      return false;
    }
    if (isProductConference) {
      if (
        !selectedRoom ||
        !(selectedRoom.globalInventoryId && selectedRoom.id)
      ) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Select Cabin to Proceed',
        });
        return false;
      }
      if (!noOfHours) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Enter Meeting Duration',
        });
        return false;
      }
      if (bookingDetails && !bookingDetails.startTime) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Select Start Time Slot',
        });
        return false;
      }
    }
    if (!email || email === '' || !email.match(emailValidateRegex)) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Enter a valid email address',
      });
      return false;
    }
    if (
      !isProductConference &&
      productType !== 'gift-card' &&
      (!price || price === 0)
    ) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Enter a valid price',
      });
      return false;
    }
    if (isSecurityDeposit) {
      if (!name || name === '') {
        setToastType({
          show: true,
          type: 'info',
          message: "Please enter customer's name",
        });
        return false;
      }
      if (address) {
        const { address1, address2, city, zip, phone, stateCode } = address;
        if (!address1 || !address2 || !city || !zip || !stateCode) {
          setToastType({
            show: true,
            type: 'info',
            message: 'Please enter the complete address of the customer',
          });
          return false;
        }
        if (!city.match(nameValidateRegex)) {
          setToastType({
            show: true,
            type: 'info',
            message:
              'Please use only characters and avoid extra spaces in city name',
          });
          return false;
        }
        if (zip.length !== 6) {
          setToastType({
            show: true,
            type: 'info',
            message: 'Please enter a valid zip code',
          });
          return false;
        }
        if (!phone) {
          setToastType({
            show: true,
            type: 'info',
            message: 'Please enter phone number',
          });
          return false;
        }
        if (!phone?.match(phoneValidate)) {
          setToastType({
            show: true,
            type: 'info',
            message: 'Please enter a valid phone number',
          });
          return false;
        }
        if (
          stateCode &&
          (!stateCodes.includes(stateCode) || !stateCode.match(codesValidate))
        ) {
          setToastType({
            show: true,
            type: 'info',
            message: 'Please enter a valid state code',
          });
          return false;
        }
      }
      if (!description) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter the description',
        });
        return false;
      }
      if (periodOfDepositDate === undefined) {
        setToastType({
          show: true,
          type: 'info',
          message: 'Please enter the period date',
        });
        return false;
      }
    }
    if (!quantity) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Quantity must be greater than zero',
      });
      return false;
    }
    if (!gstValid && gstin !== '') {
      setToastType({
        show: true,
        type: 'info',
        message: 'Please validate GST number before placing order',
      });
      return false;
    }
    return true;
  };
  // console.log(productType,selectedVariant.title)

  const title = useMemo(() => {
    switch (productType) {
      case 'printing':
        return 'Printing - ' + selectedVariant?.title;
      case 'parking':
        return 'Parking - ' + selectedVariant?.title;
      case 'lost-keycard':
        return 'Lost Keycard';
      case 'security-deposit':
        return 'Security Deposit';
      default:
        return 'Conference Room';
    }
  }, [productType, selectedVariant]);

  const handle = useMemo(() => {
    switch (productType) {
      case 'printing':
        return 'printing-' + selectedVariant?.title;
      case 'parking':
        return 'parking-' + selectedVariant?.title;
      case 'lost-keycard':
        return 'lost-keycard';
      case 'gift-card':
        return 'gift-card';
      case 'security-deposit':
        return 'security-deposit';
      default:
        return 'conference-room';
    }
  }, [productType, selectedVariant]);

  const makeDraftOrder = async () => {
    if (!errorCheck()) return;
    setBtnDisable(true);
    const conferenceRoomCheck = isProductConference;
    const giftCardCheck = productType === 'gift-card';
    const parkingCheck = productType === PARKING_HANDLE;
    const securityDepositCheck = productType === SECURITY_DEPOSIT_HANDLE;

    const draftNotesBody: any = {
      handle: conferenceRoomCheck || giftCardCheck ? productType : handle,
      building_title: building && building.name,
      building_id: building && building.id ? building.id : '',
      micromarket: building ? building.micromarket : '',
      city: building ? building.city : '',
      building_zone: building ? building.region : '',
      isDashboard: true,
    };
    if (
      conferenceRoomCheck &&
      bookingDetails &&
      bookingDetails.startTime &&
      memberCount
      // && creditCount
    ) {
      const conferenceBody = {
        booking_date: bookingDetails.date,
        booking_start_time: bookingDetails.startTime,
        variant_title: selectedVariant.title,
        member_count: memberCount,
        // credit_count: creditCount,
        no_of_hrs: noOfHours,
        building_title: building && building.name,
      };
      draftNotesBody.conference_rooms_info = [conferenceBody];
    }
    if (parkingCheck && bookingDetails && bookingDetails.date) {
      const parkingInfoObject = {
        booking_date: bookingDetails.date,
        variant_title: selectedVariant.title,
      };
      draftNotesBody.parking_info = [parkingInfoObject];
    }
    if (state && state.addOnState && state.addOnState.customerEmail) {
      draftNotesBody.parent_order_no = state.addOnState.orderNo;
      draftNotesBody.booking_firebase_id = state.addOnState.bookingFirebaseId;
    }
    if (securityDepositCheck) {
      draftNotesBody.custom_product_info = [
        { productName: 'Security Deposit' },
      ];
      draftNotesBody.eventTypeOthersDetails = '';
    }
    try {
      const body: any = {
        line_items:
          conferenceRoomCheck || giftCardCheck
            ? [
                {
                  variant_id: selectedVariant.id,
                  quantity: giftCardCheck ? quantity : noOfHours,
                },
              ]
            : [
                {
                  title: title,
                  price: price,
                  taxable: !securityDepositCheck,
                  quantity: quantity,
                },
              ],
        customer_email: email,
        notes: notes,
        order_creator: userInfo ? userInfo.id : null,
        buildingId: building && building.id ? building.id : '',
        draft_notes: JSON.stringify(
          securityDepositCheck ? [draftNotesBody] : draftNotesBody,
        ),
      };
      if (conferenceRoomCheck) {
        body.noOfHrs = noOfHours;
        body.globalInventoryId = selectedRoom.globalInventoryId;
        body.order_type = 'B2C';
        body.userAnalytics = {
          productInfo: {
            product: 'conference room',
            created_at: new Date().getTime(),
          },
        };
      }
      if (gstin) body.gstin = gstin;

      if (securityDepositCheck) {
        const period_of_deposit = format(periodOfDepositDate, 'dd/MM/yy');

        body.dashboard_order_type = 'security-deposit';
        body.gstin = '';
        body.tags = ['DEPOSIT', 'DRAFT_ORDER'];
        body.period_of_deposit = period_of_deposit;
        body.customer_name = name;
        body.userBillingDetails = {
          address1: address?.address1,
          address2: address?.address2,
          city: address?.city,
          zip: address?.zip,
          phone: address?.phone,
          state_code: address?.stateCode,
        };
        body.description = description;
      }
      // console.log('GOT BODY', { body });

      if (conferenceRoomCheck) {
        await axiosGlobal.post(`api/v1/admin/conference-rooms/book`, body, {
          headers: {
            Authorization: authState?.accessToken?.accessToken,
          },
        });
      } else {
        await axios.post(`shop/create-draft-order`, body, {
          headers: {
            Authorization: authState?.accessToken?.accessToken,
          },
        });
      }
      // console.log(body)
      setToastType({
        show: true,
        type: 'success',
        message: 'Payment link sent to customer',
      });
      push('/shop-purchases');
      setBtnDisable(false);
    } catch (err) {
      const error: any = err;
      console.error(error.response);
      setToastType({
        show: true,
        type: 'error',
        message: error?.response?.data?.message || error?.message,
      });
      setBtnDisable(false);
    }
  };
  const getTotalPrice = () => {
    if (selectedVariant && selectedVariant.price) {
      if (isProductConference) {
        if (
          bookingDetails &&
          bookingDetails.startTime &&
          selectedRoom &&
          noOfHours
        )
          return stringifyPrice(Number(selectedVariant.price) * noOfHours);
        else {
          return '₹0';
        }
      } else {
        return productType === 'gift-card'
          ? stringifyPrice(Number(selectedVariant.price) * quantity)
          : stringifyPrice(Number(price ? price : 0) * quantity);
      }
    } else {
      return '₹0';
    }
  };

  const fullAddress = useMemo(() => {
    let fullAddress = '';
    if (address) {
      const { address1, address2, city, stateCode, zip, phone } = address;
      if (address1) fullAddress += address1 + ', ';
      if (address2) fullAddress += address2 + ', ';
      if (city) fullAddress += city + ', ';
      if (stateCode) fullAddress += stateCode + ', ';
      if (zip) fullAddress += zip + ', ';
      if (phone) fullAddress += phone + ', ';
    }
    return fullAddress;
  }, [address]);

  return (
    <div className={classes.root}>
      <div className={classes.headText}>
        <h4 className="ray-text--h4">{`Total: ${getTotalPrice()}`}</h4>
      </div>
      <Grid container spacing={1}>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          className={classes.detailCaption}
        >
          {building && building.id && (
            <div className="ray-text--body-small">Property:</div>
          )}
          {isProductConference &&
            selectedRoom &&
            selectedRoom.name &&
            selectedRoom.capacity && (
              <div className="ray-text--body-small">Conference Room:</div>
            )}
          {!isProductConference && quantity && quantity !== 0 && (
            <div className="ray-text--body-small">Quantity:</div>
          )}
          {bookingDetails && bookingDetails.date && (
            <div className="ray-text--body-small">Meeting Date:</div>
          )}
          {bookingDetails && bookingDetails.startTime && (
            <div className="ray-text--body-small">Meeting Time:</div>
          )}
          {isProductConference && noOfHours && noOfHours !== 0 && (
            <div className="ray-text--body-small">Meeting Duration:</div>
          )}
          {email && <div className="ray-text--body-small">Email:</div>}
          {name && <div className="ray-text--body-small">Name:</div>}
          {fullAddress && <div className="ray-text--body-small">Address:</div>}
          {description && (
            <div className="ray-text--body-small">Description:</div>
          )}
          {periodOfDepositDate && (
            <div className="ray-text--body-small">Period of deposit:</div>
          )}
          {gstin && <div className="ray-text--body-small">GSTIN:</div>}
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          className={classes.valueCaptions}
        >
          {building && building.id && (
            <div className="ray-text--body-small">{building.name}</div>
          )}
          {isProductConference &&
            selectedRoom &&
            selectedRoom.name &&
            selectedRoom.capacity && (
              <div className="ray-text--body-small">
                {selectedRoom.name} ({selectedRoom.capacity} Seater)
              </div>
            )}
          {!isProductConference && quantity && quantity !== 0 && (
            <div className="ray-text--body-small">{quantity}</div>
          )}
          {bookingDetails && bookingDetails.date && (
            <div className="ray-text--body-small">
              {format(bookingDetails.date, 'do MMM yyyy')}
            </div>
          )}
          {bookingDetails && bookingDetails.startTime && (
            <div className="ray-text--body-small">{`${format(
              bookingDetails.startTime,
              'h:mm aaaa',
            )}`}</div>
          )}

          {isProductConference && noOfHours && noOfHours !== 0 && (
            <div className="ray-text--body-small">{`${noOfHours} ${
              noOfHours === 1 ? 'hr.' : 'hrs.'
            }`}</div>
          )}
          {email && <div className="ray-text--body-small">{email}</div>}
          {name && <div className="ray-text--body-small">{name}</div>}

          {fullAddress && (
            <div className="ray-text--body-small">{fullAddress}</div>
          )}
          {description && (
            <div className="ray-text--body-small">{description}</div>
          )}
          {periodOfDepositDate && (
            <div className="ray-text--body-small">
              {format(periodOfDepositDate, 'dd/MM/yyyy')}
            </div>
          )}
          {gstin && <div className="ray-text--body-small">{gstin}</div>}
        </Grid>
      </Grid>

      <RayButton
        type="button"
        buttonType="primary"
        onClick={makeDraftOrder}
        className={classes.buttonRow}
        disabled={btnDisable || disableOrderBtn}
      >
        {btnDisable ? (
          <CircularProgress size={20} style={{ color: '#FFFFFF' }} />
        ) : (
          <>
            <span>Place Order & Send Invoice</span>
            <BsArrowRightShort />
          </>
        )}
      </RayButton>
    </div>
  );
}

export default CheckoutCard;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      border: '2.5px solid #E3E3E3',
      borderRadius: '4px',
      padding: '1em',
    },
    headText: {
      '& small': {
        color: '#424242',
      },
    },
    detailCaption: {
      '& div': {
        fontWeight: 'bolder',
      },
    },
    valueCaptions: {
      '& div': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
        textOverflow: 'ellipsis',
      },
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '& svg': {
        fontSize: '1.5em',
      },
    },
  }),
);
