import { gql } from '@apollo/client';

export const FETCH_CONFERENCE_VARIANTS_BY_ID = gql`
  query getProduct($id: ID!) {
    product(id: $id) {
      id
      title
      handle
      variants(first: 100) {
        edges {
          node {
            id
            title
            price {
              amount
            }
            availableForSale
            quantityAvailable
          }
        }
      }
    }
  }
`;
