import React, { useState, useMemo } from 'react';
import axios from 'utils/axios';
import RayButton from 'components/RayButton';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import ModalDialog from 'components/ModalDialog';
import ViewPurchasesDetails from './ViewPurchasesDetails';
import format from 'date-fns/format';
import { useOktaAuth } from '@okta/okta-react';

type Props = {
  cell: any;
  index: number;
  reloadPurchases: () => void;
};

// const toTitleCase = (status: string): string => {
//   const splittedString = status.split('');
//   return (
//     splittedString.slice(0, 1).join('') +
//     splittedString
//       .slice(1)
//       .join('')
//       .toLowerCase()
//   );
// };

function TableCell({ cell, index, reloadPurchases }: Props) {
  const [disabled, setDisabled] = useState<boolean>(false);

  const { setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();
  const [toggleDetailsModal, setToggleDetailsModal] = useState<boolean>(false);

  const isCancelledOrder = useMemo(() => {
    if (
      cell.status !== 'CANCELLED' &&
      cell.product_name !== 'Gift Card' &&
      cell.product_name !== 'Gift-card' &&
      !cell.isGlobalPrintOrder
    ) {
      return false;
    }
    return true;
  }, [cell]);

  const isDepositPaid = cell.productName === "Security-deposit" && cell.status === "COMPLETED";

  const cancelPurchase = async () => {
    setDisabled(true);
    if (cell.isGlobalPrintOrder) {
      return;
    }
    try {
      await axios.post(`/cancel/purchases`, null, {
        params:
          cell.dashBoardOrderType === 'postpaid-event'
            ? {
                purchaseDocId: cell.objectID,
                isPostpaid: true,
              }
            : {
                purchaseDocId: cell.objectID,
              },
        headers: {
          Authorization: authState?.accessToken?.accessToken,
        },
      });
      await reloadPurchases();
      setToastType({
        show: true,
        type: 'success',
        message: 'Cancelled Purchase, Please refresh to see the changes.',
      });
      setDisabled(false);
    } catch (err) {
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message: error.response.data.message || 'Error occured',
      });
      setDisabled(false);
    }
  };

  const resendCheckoutEmail = async (id: string) => {
    setDisabled(true);
    try {
      await axios.post(`/shop/resend-checkout-email/${id}`);
      setToastType({
        show: true,
        type: 'success',
        message: 'Sent the email to customer',
      });
      setDisabled(false);
    } catch (error) {
      console.log(error);
      setToastType({
        show: true,
        type: 'error',
        message: 'Error occurred while sending the email to customer',
      });
      setDisabled(false);
    }
  };

  /* // unused function for cancel order cta
  const gotoPaymentLink = () => {
    window.open(cell.checkoutUrl, '_blank')?.focus();
  } */

  return (
    <>
      <tr key={+index} className="purchase_row">
        <td>{cell.orderNumber}</td>
        <td>{cell.customerEmail}</td>
        <td>
          {cell.productInformation[0]?.title === 'Security Deposit' && cell.productName === 'Event-space' ? cell.productInformation[0]?.title : cell.productName}
          {cell.isGlobalPrintOrder && (
            <>
              <br />
              <span className={'user-annotation-text green-box'}>
                Print Hub
              </span>
            </>
          )}
          {cell.productName === 'Others' &&
          cell.productInformation?.[0]?.eventTypeOthersDetails
            ? ' (' + cell.productInformation[0].eventTypeOthersDetails + ')'
            : ''}
        </td>
        <td>{cell.buildingName}</td>
        <td>{format(new Date(cell.createdOn), 'do MMM yyyy, hh:mm aaa')}</td>
        {/* <td>{toTitleCase(cell.status)}</td> */}
        <td>
          <RayButton
            compact
            buttonType="secondary"
            disabled={disabled}
            style={{ marginBottom: '-3px' }}
            onClick={() => setToggleDetailsModal(true)}
          >
            View Details
          </RayButton>
        </td>
        {/* <td>
        {queryType !== "CANCELLED" && cell.checkoutUrl !== "--" && (
          <RayButton
            compact
            disabled={disabled}
            style={{ marginBottom: "-3px" }}
            onClick={gotoPaymentLink}
          >
            View Order
          </RayButton>
        )}
      </td> */}
        <td>
          {cell.status === 'PENDING' && cell.checkoutUrl !== '--' && (
            <RayButton
              compact
              buttonType="secondary"
              disabled={disabled}
              style={{ marginBottom: '-3px' }}
              onClick={() => resendCheckoutEmail(cell.objectID)}
            >
              Resend
            </RayButton>
          )}
        </td>
        <td>
          {!isCancelledOrder && !cell.isGlobalPrintOrder && !isDepositPaid && (
            <RayButton
              compact
              buttonType="secondary"
              danger
              disabled={disabled}
              style={{ marginBottom: '-3px' }}
              onClick={cancelPurchase}
            >
              Cancel
            </RayButton>
          )}
        </td>
      </tr>
      <ModalDialog
        isOpen={toggleDetailsModal}
        showModal={setToggleDetailsModal}
        fullWidth
        maxWidth="md"
        title="Purchase Info"
      >
        <ViewPurchasesDetails
          orderId={cell.objectID}
          buildingName={cell.buildingName}
          isCancelledOrder={isCancelledOrder}
        />
      </ModalDialog>
    </>
  );
}

export default TableCell;
