import { FormControlLabel, Grid } from '@material-ui/core';
import SelectInput from 'components/MaterialSelect/Autocomplete';
import { BlueSwitch } from 'components/RaySwitch';
import { H1 } from 'components/Typography';
import { BuildingOption } from 'containers/Bookings/types';
import React, { useState } from 'react';
import { useFetchRequest } from 'utils/hooks/useFetchRequest';
import Reservation from './Reservation';
import RayInput from 'components/RayInput';
import CheckoutReservation from './CheckoutReservation';
import { DEFAULT_ERROR_STATES } from 'containers/ShopDetailPage';
import Payment from './Payment';
import CheckoutPayment from './CheckoutPayment';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const DEFAULT_RESERVATION_STATE = {
  name: '',
  email: '',
  selectedBuilding: null,
  bookingDate: new Date(),
  noOfHours: 1,
  bookingStartTime: undefined,
  selectedRoom: null,
  selectedConfVariant: null,
  errorState: DEFAULT_ERROR_STATES,
};

export const DEFAULT_PAYMENT_STATE = {
  email: '',
  gstin: '',
  gstinError: '',
  gstValid: false,
  securityDeposit: 0,
  amount: 0,
  paymentType: '',
  isPaymentModeOnline: true,
  lead: '',
  reservation: {},
  errorState: DEFAULT_ERROR_STATES,
  emailInUse: '',
  phone: '',
  eventLeadId: '',
};

const EventsPage = () => {
  const classes = useStyles();
  const [isPaymentScreen, setIsPaymentScreen] = useState<boolean>(false);

  const [reservationState, setReservationState] = useState(
    DEFAULT_RESERVATION_STATE,
  );
  const [paymentState, setPaymentState] = useState(DEFAULT_PAYMENT_STATE);

  const updateReservationState = (newState: any) => {
    setReservationState(prevState => ({ ...prevState, ...newState }));
  };
  const updatePaymentState = (newState: any) => {
    setPaymentState(prevState => ({ ...prevState, ...newState }));
  };
  const [buildingsData, buildingsLoader, buildingsError] = useFetchRequest(
    '/buildings/get-user-buildings',
  );

  const handleChangeBuilding = (
    event: React.ChangeEvent<{}>,
    value: BuildingOption | null,
  ) => {
    updateReservationState({ selectedBuilding: value?.id ? value : null });
  };

  const {
    name,
    email,
    selectedBuilding,
    bookingDate,
    bookingStartTime,
    selectedRoom,
    noOfHours,
  } = reservationState;

  return (
    <Grid
      container
      spacing={3}
      className={classes.removeMargin}
      justify="space-between"
    >
      <Grid item xs={12}>
        <H1>Events Space</H1>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <BlueSwitch
              checked={isPaymentScreen}
              onChange={() => {
                setIsPaymentScreen(prev => !prev);
                !isPaymentScreen &&
                  updateReservationState(DEFAULT_RESERVATION_STATE);
                isPaymentScreen && updatePaymentState(DEFAULT_PAYMENT_STATE);
              }}
              name="payment"
            />
          }
          label={isPaymentScreen ? 'Payment' : 'Reservation'}
        />
      </Grid>
      <Grid container={!isPaymentScreen} item xs={6} spacing={2}>
        {!isPaymentScreen && (
          <Grid item xs={12}>
            <SelectInput
              key="building"
              size="medium"
              options={
                buildingsData && buildingsData.length > 0
                  ? buildingsData.filter(
                      ({ disabled }: BuildingOption) => !disabled,
                    )
                  : []
              }
              value={selectedBuilding}
              getOptionLabel={option => option.name}
              disabled={buildingsLoader || buildingsError}
              label="Select Building"
              name="select-building"
              onChange={handleChangeBuilding}
            />
          </Grid>
        )}
        <Grid item xs={12} spacing={2}>
          <RayInput
            placeholder="arya.stark@wework.co.in"
            id="email__id"
            fullWidth
            autoMargin={false}
            value={
              isPaymentScreen ? paymentState.email : reservationState.email
            }
            onChange={e =>
              isPaymentScreen
                ? updatePaymentState({ email: e.target.value })
                : updateReservationState({ email: e.target.value })
            }
            label="Customer Email"
          />
          {!isPaymentScreen && (
            <small className="ray-text--body-x-small">
              This is the email that the payment link and invoice will be sent
              to.
            </small>
          )}
        </Grid>
        {isPaymentScreen ? (
          <Payment
            paymentState={paymentState}
            updateState={updatePaymentState}
          />
        ) : (
          <Reservation
            reservationState={reservationState}
            updateState={updateReservationState}
          />
        )}
      </Grid>
      <Grid item xs={5}>
        {isPaymentScreen ? (
          <CheckoutPayment {...paymentState} />
        ) : (
          <CheckoutReservation
            name={name}
            email={email}
            building={selectedBuilding}
            selectedRoom={selectedRoom!}
            noOfHours={noOfHours}
            bookingDetails={{
              date: bookingDate,
              startTime: bookingStartTime,
            }}
            resetReservation={() =>
              updateReservationState(DEFAULT_RESERVATION_STATE)
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default EventsPage;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    removeMargin: {
      margin: 0,
    },
  }),
);
