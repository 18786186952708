import React, { useState } from 'react';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { BsArrowRightShort } from 'react-icons/bs';
import { stringifyPrice } from 'utils/functions/stringifyPrice';
import RayButton from 'components/RayButton';
import { emailValidateRegex, phoneValidate } from 'utils/constants';
import { format } from 'date-fns';
import axios from 'utils/axios';
import { useOktaAuth } from '@okta/okta-react';

type Props = {
  emailInUse: string | undefined;
  gstin: string;
  gstValid: boolean;
  amount: number;
  securityDeposit: number;
  isPaymentModeOnline: boolean;
  lead: string;
  reservation: any;
  phone: string;
  eventLeadId: string;
};

function CheckoutPayment({
  emailInUse,
  gstin,
  amount,
  securityDeposit,
  isPaymentModeOnline,
  lead,
  reservation,
  phone,
  eventLeadId
}: Props) {
  const classes = useStyles();

  const { push } = useHistory();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const { userInfo, setToastType } = useAuthContext()!;
  const { authState} = useOktaAuth();
  const errorCheck = () => {
    if (
      !emailInUse ||
      emailInUse === '' ||
      !emailInUse.match(emailValidateRegex)
    ) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Enter a valid email address',
      });
      return false;
    }

    if (!reservation?.metadata) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Please select a reservation',
      });
      return false;
    }
    if (!amount || amount === 0) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Amount cannot be 0',
      });
      return false;
    }
    if (!lead || lead === '') {
      setToastType({
        show: true,
        type: 'info',
        message: 'Please select a lead',
      });
      return false;
    }
    if (!eventLeadId || eventLeadId === '') {
      setToastType({
        show: true,
        type: 'info',
        message: 'Please enter unique event lead ID',
      });
      return false;
    }
    if (!!phone && !phone?.match(phoneValidate)) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Please enter a valid phone number',
      });
      return false;
    }
    return true;
  };

  const makeDraftOrder = async () => {
    if (!errorCheck()) return;
    setBtnDisable(true);
    try {
      const draftNotesBody: any = {
        handle: 'event-space',
        building_title: reservation.building_title,
        building_id: reservation.buildingId,
        micromarket: reservation.micromarket_name,
        isDashboard: true,
      };
      const conferenceBody = {
        bookingToken: reservation.bookingToken,
        booking_date: new Date(
          reservation.metadata?.booking_date?._seconds * 1000,
        ),
        booking_start_time: new Date(
          reservation.metadata?.booking_start_time?._seconds * 1000,
        ),
        variant_title: reservation.metadata?.variant_title,
        member_count: reservation.metadata?.member_count,
        // credit_count: creditCount,
        no_of_hrs: reservation.metadata?.no_of_hrs,
        building_title: reservation.metadata?.building_name,
      };
      draftNotesBody.conference_rooms_info = [conferenceBody];
      const body: any = {
        line_items: [
          {
            title: 'Event Space',
            price: amount,
            taxable: true,
            quantity: 1,
          },
          ...(securityDeposit > 0
            ? [
                {
                  title: 'Security Deposit',
                  price: securityDeposit,
                  taxable: false,
                  quantity: 1,
                },
              ]
            : []),
        ],
        customer_email: emailInUse,
        notes: '',
        order_creator: userInfo ? userInfo.id : null,
        dashboard_order_type: 'event-space',
        buildingId: reservation.buildingId,
        draft_notes: JSON.stringify(draftNotesBody),
        bookingToken: reservation.bookingToken,
        lead: lead,
        eventId: eventLeadId,
      };
      if (isPaymentModeOnline) {
        body.userAnalytics = {
          productInfo: {
            product: 'conference room',
            created_at: new Date().getTime(),
          },
        };
      }
      if (phone) body.customer_phone = phone;
      if (gstin) body.gstin = gstin;

      if (isPaymentModeOnline) {
        await axios.post(`shop/book-event-space-online`, body, {
          headers: {
            Authorization: authState?.accessToken?.accessToken,
          },
        });
      } else {
        await axios.post(`shop/book-event-space-offline`, body, {
          headers: {
            Authorization: authState?.accessToken?.accessToken,
          },
        });
      }
      setToastType({
        show: true,
        type: 'success',
        message: isPaymentModeOnline
          ? 'Payment link sent to customer'
          : 'Order successful',
      });
      push('/shop-purchases');
      setBtnDisable(false);
    } catch (err) {
      const error: any = err;
      //   console.error(error.response);
      const message = error?.response?.data?.message || 'Something went wrong';
      setToastType({
        show: true,
        type: 'error',
        message: message,
      });
      setBtnDisable(false);
    }
  };
  const getAmount = () => {
    return amount ? stringifyPrice(Number(amount)) : '₹0';
  };
  const getSecurityDeposit = () => {
    return securityDeposit ? stringifyPrice(Number(securityDeposit)) : '₹0';
  };
  return (
    <div className={classes.root}>
      <div className={classes.headText}>
        <h4 className="ray-text--h4">{`Amount: ${getAmount()}`}</h4>
        <h5 className="ray-text--h5">{`Security deposit: ${getSecurityDeposit()}`}</h5>
      </div>
      <Grid container spacing={1}>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          className={classes.detailCaption}
        >
          {reservation && reservation.metadata && (
            <>
              <div className="ray-text--body-small">Property:</div>
              <div className="ray-text--body-small">Event Space:</div>
              <div className="ray-text--body-small">Meeting Date:</div>
              <div className="ray-text--body-small">Meeting Time:</div>
              <div className="ray-text--body-small">Meeting Duration:</div>
            </>
          )}
          {emailInUse && <div className="ray-text--body-small">Email:</div>}
          {reservation?.metadata && (
            <div className="ray-text--body-small">Payment mode:</div>
          )}
          {lead && <div className="ray-text--body-small">Source of lead:</div>}
          {eventLeadId && <div className="ray-text--body-small">Event lead ID:</div>}
          {phone && <div className="ray-text--body-small">Phone number:</div>}
          {gstin && <div className="ray-text--body-small">GSTIN:</div>}
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          className={classes.valueCaptions}
        >
          {reservation && reservation.metadata && (
            <>
              <div className="ray-text--body-small">
                {reservation.metadata?.building_name}
              </div>
              <div className="ray-text--body-small">
                {reservation.metadata?.global_room_name} (
                {reservation.metadata?.member_count} Seater)
              </div>
              <div className="ray-text--body-small">
                {format(
                  new Date(reservation.metadata?.booking_date?._seconds * 1000),
                  'do MMM yyyy',
                )}
              </div>
              <div className="ray-text--body-small">{`${format(
                new Date(
                  reservation.metadata?.booking_start_time?._seconds * 1000,
                ),
                'h:mm aaaa',
              )}`}</div>
              <div className="ray-text--body-small">{`${
                reservation.metadata?.no_of_hrs
              } ${
                reservation.metadata?.no_of_hrs === 1 ? 'hr.' : 'hrs.'
              }`}</div>
            </>
          )}
          {emailInUse && (
            <div className="ray-text--body-small">{emailInUse}</div>
          )}
          {reservation?.metadata && (
            <div className="ray-text--body-small">
              {isPaymentModeOnline ? 'Online' : 'Offline'}
            </div>
          )}
          {lead && <div className="ray-text--body-small">{lead}</div>}
          {eventLeadId && <div className="ray-text--body-small">{eventLeadId}</div>}
          {phone && <div className="ray-text--body-small">{phone}</div>}
          {gstin && <div className="ray-text--body-small">{gstin}</div>}
        </Grid>
      </Grid>

      <RayButton
        type="button"
        buttonType="primary"
        onClick={makeDraftOrder}
        className={classes.buttonRow}
        disabled={btnDisable || (reservation.metadata ? false : true)}
      >
        {btnDisable ? (
          <CircularProgress size={20} style={{ color: '#FFFFFF' }} />
        ) : (
          <>
            <span>
              {isPaymentModeOnline
                ? 'Place Order & Send Invoice'
                : 'Place Order'}
            </span>
            <BsArrowRightShort />
          </>
        )}
      </RayButton>
    </div>
  );
}

export default CheckoutPayment;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      border: '2.5px solid #E3E3E3',
      borderRadius: '4px',
      padding: '1em',
    },
    headText: {
      '& small': {
        color: '#424242',
      },
    },
    detailCaption: {
      '& div': {
        fontWeight: 'bolder',
      },
    },
    valueCaptions: {
      '& div': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
        textOverflow: 'ellipsis',
      },
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '& svg': {
        fontSize: '1.5em',
      },
    },
  }),
);
