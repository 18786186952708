import React from "react";
import { setHours, setMinutes } from "date-fns";
import ReactTimePicker from "react-datepicker";
import RayInput from "../RayInput";

type DatePickerProps = {
  timeIntervals?: number | undefined;
  placeholder?: string;
  selected?: Date | null;
  onChange(
    date: Date | [Date, Date] | null,
    event: React.SyntheticEvent<any> | undefined
  ): void;
  label?: string;
  name?: string;
  ref?: React.Ref<any>;
  className?: string | undefined;
  disabled?: boolean | undefined;
  dateFormat?: string | string[] | undefined;
  timeCaption?: string | undefined;
  minTime?: [number, number] | undefined;
  maxTime?: [number, number] | undefined;
  excludeTimes?:  Date[] | undefined;
};

const ExampleCustomInput = ({ value, label, onClick }: any) => (
  <RayInput fullWidth value={value} label={label} onClick={onClick} />
);

function TimePicker({
  selected,
  onChange,
  className,
  timeIntervals,
  dateFormat,
  placeholder = "Select Time",
  timeCaption = "Time",
  label = "Select Time",
  disabled = false,
  minTime,
  maxTime,
  excludeTimes
}: DatePickerProps) {
  return (
    <ReactTimePicker
      selected={selected}
      onChange={onChange}
      showTimeSelect
      showTimeSelectOnly
      className={className}
      timeInputLabel={label}
      placeholderText={placeholder}
      timeIntervals={timeIntervals}
      excludeTimes={excludeTimes}
      customInput={<ExampleCustomInput />}
      minTime={
        minTime && minTime.length > 0
          ? setHours(setMinutes(new Date(), minTime[1]), minTime[0])
          : undefined
      }
      maxTime={
        maxTime && maxTime.length > 0
          ? setHours(setMinutes(new Date(), maxTime[1]), maxTime[0])
          : undefined
      }
      timeCaption={timeCaption}
      dateFormat={dateFormat}
      disabled={disabled}
    />
  );
}

export default TimePicker;
