import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { setHours, setMinutes, format as formatTime } from 'date-fns';

type Props = {
  setStartTime: (e: any) => void;
  selectedStartTime: any;
  timeSlot: string;
  bookingDate: Date;
};

function TimePill({
  timeSlot,
  setStartTime,
  selectedStartTime,
  bookingDate,
}: Props) {
  const classes = useStyles();
  const [checked, setChecked] = useState<boolean>(false);

  const [isoDate, setIsoDate] = useState<Date | undefined>();

  useEffect(() => {
    const minutes = Number(timeSlot.slice(2));
    const hours = Number(timeSlot.slice(0, 2));
    setIsoDate(setHours(setMinutes(new Date(bookingDate), minutes), hours));
  }, [timeSlot, bookingDate]);

  useEffect(() => {
    if (selectedStartTime && isoDate && selectedStartTime === isoDate)
      setChecked(true);
    else setChecked(false);
  }, [selectedStartTime, isoDate]);

  const handleChange = () => {
    if (!checked) {
      setStartTime(isoDate ? isoDate : null);
    } else setStartTime(null);
    setChecked(!checked);
  };
  return (
    <div
      onClick={handleChange}
      className={`${classes.root} ${
        checked ? classes.selected : ''
      } ray-grid__cell--span-4`}
    >
      <span>{timeSlot && isoDate ? formatTime(isoDate, 'h:mm a') : '--'}</span>
    </div>
  );
}
export default TimePill;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '2px solid #E3E3E3',
      borderRadius: '4px',
      padding: '0.5em',
      marginTop: '8px',
      marginBottom: '8px',
      '&:hover': {
        cursor: 'pointer',
        border: '2px solid #0000FF',
      },
    },
    selected: {
      border: '2px solid #0000FF',
    },
    priceText: {
      color: '#858585',
    },
  }),
);
