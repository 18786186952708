export type ShopDetailPageParams = { shopId: string };
export type BuildingOption = {
  id: string;
  name: string;
  disabled?: boolean;
  micromarket?: string;
  city?: string;
  region?: string;
  locationUuid?: string;
};

export type ProductNested = {
  handle: string;
  quantityEditorLabel: string;
  variantSelectorLabel: string;
};

export type TypeProduct = {
  [key: string]: ProductNested;
};

export const PRODUCT_TYPES: TypeProduct = {
  'conference-room': {
    handle: 'conference-room',
    quantityEditorLabel: 'SELECT NO. OF HOURS',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
  'gift-card': {
    handle: 'gift-card',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT DENOMINATION',
  },
  'lost-keycard': {
    handle: 'lost-keycard',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT VARIANT',
  },
  parking: {
    handle: 'parking',
    quantityEditorLabel: 'SELECT NO. OF VEHICLES',
    variantSelectorLabel: 'SELECT VEHICLE',
  },
  printing: {
    handle: 'printing',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT VARIANT',
  },
  'conference-room-seat': {
    handle: 'conference-room-seat',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
  'conference-room-credit': {
    handle: 'conference-room-credit',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
  'custom-events': {
    handle: 'custom-events',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
  'postpaid-events': {
    handle: 'postpaid-events',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
  'ode-events': {
    handle: 'ode-purchases',
    quantityEditorLabel: 'SELECT QUANTITY',
    variantSelectorLabel: 'SELECT CAPACITY',
  },
};

export const CONF_ROOM_PROD_ID =
  process.env.REACT_APP_ENV === 'production'
    ? '7871858802926'
    : '7368912208028';
export const LOST_KEYCARD_PROD_ID =
  process.env.REACT_APP_ENV === 'production'
    ? '7871858311406'
    : '7367393640604';
export const PRINTING_PROD_ID =
  process.env.REACT_APP_ENV === 'production'
    ? '6209585676439'
    : '5939296764060';

export const LOST_KEYCARD_VARIANT_ID =
  process.env.REACT_APP_ENV === 'production' ? 43403342348526 : 41832814608540;
export const PRINTING_BW_VARIANT_ID =
  process.env.REACT_APP_ENV === 'production' ? 38230857580695 : 36979324289180;
export const PRINTING_COLOR_VARIANT_ID =
  process.env.REACT_APP_ENV === 'production' ? 38230857547927 : 36979324256412;
export const CONF_ROOM_VARIANT_ID =
  process.env.REACT_APP_ENV === 'production' ? 43403343364334 : 41836497141916;

interface LocationData {
  id: string;
  name: string;
  phoneNumber: string;
  addresses: {
    addressType: string;
    line1: string;
    line2: string;
    city: string;
    country: string;
    state: string;
    zip: string;
    __typename: string;
  }[];
  __typename: string;
}

export interface ConferenceRoom {
  id: string;
  name: string;
  capacity: number;
  productId?: number;
  variantId?: number;
  __typename: string;
  globalInventoryId?: string;
  location: LocationData | LocationData[];
}
interface IPagination {
  limit: number;
  pageNumber: number;
  totalPages: number;
}

export interface ConferenceRoomResponseData {
  success: boolean;
  message: string;
  data: {
    pagination: IPagination;
    result: ConferenceRoom[];
  };
}

export interface Enterprise {
  primary_member_email: string;
  hsnCode: number;
  created_at: { _seconds: number; _nanoseconds: number };
  type: string;
  gstin: string;
  enterprise_legal_name: string;
  created_by: string;
  company_address: CompanyAddress;
  location_code: string;
  currency: string;
  primary_member: string;
  enterprise_name: string;
  pricing: Pricing;
  short_code: string;
  po_bookings_limited: boolean;
  od_bookings_limited: boolean;
  domains: string[];
  accessible_buildings: string[] | '__all__';
  enterprise_logo_urls: EnterpriseLogoUrls;
  url_handle: string;
  permissions: Permissions;
  enterpriseDomain: string;
  enterpriseName: string;
}

interface CompanyAddress {
  address: string;
  city: string;
  pin_code: string;
  state: string;
  state_code: string;
}

interface EnterpriseLogoUrls {
  desktop: string;
  mobile: string;
  main: string;
}

interface Permissions {
  parking: PermissionsParking;
  day_pass: DayPassPermission;
  conference_room: ConferenceRoomPermission;
  global: Global;
}

interface ConferenceRoomPermission {
  is_enabled: boolean;
  settings: ConferenceRoomSettings;
}

interface ConferenceRoomSettings {
  can_book_multiple_rooms: boolean;
}

interface DayPassPermission {
  is_enabled: boolean;
  settings: DayPassSettings;
}

interface DayPassSettings {
  can_add_members: boolean;
}

interface Global {
  is_enabled: boolean;
  settings: { [key: string]: boolean };
}

interface PermissionsParking {
  is_enabled: boolean;
  settings: ParkingSettings;
}

interface ParkingSettings {
  can_book_2_wheeler: boolean;
  can_book_4_wheeler: boolean;
}

interface Pricing {
  parking: PricingParking;
  conference_room: number;
  printing: Printing;
  day_pass: number;
}

interface PricingParking {
  four_wheeler: number;
  two_wheeler: number;
}

interface Printing {
  color: number;
  black_white: number;
}
