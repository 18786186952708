import { useQuery } from '@apollo/client';
import { myHqClient, weworkClient } from 'utils/apolloClient';

export type Client = 'wework' | 'myhq';
type UseQueryParams = Parameters<typeof useQuery>;

type Args = [client: Client, ...params: UseQueryParams];

const useApolloQuery = (...args: Args) => {
  const [client, query, options] = args;
  const clientToUse = client === 'wework' ? weworkClient : myHqClient;
  return useQuery(query, { ...options, client: clientToUse });
};

export default useApolloQuery;
