import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation, Link, useParams, Redirect } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import {
  makeStyles,
  createStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useFetchRequest } from 'utils/hooks/useFetchRequest';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import Loader from 'components/Loader';
import { H1, H6 } from 'components/Typography';
import QuantitySelector from 'components/QuantitySelector';
import RayInput from 'components/RayInput';
import SelectInput from 'components/MaterialSelect/Autocomplete';
import {
  ShopDetailPageParams,
  PRODUCT_TYPES,
  BuildingOption,
  ConferenceRoom,
} from './types';
import OldConferenceFlow from './OldConferenceFlow';
import CheckoutCard from './CheckoutCard';
import Variant from './VariantCard';
import GSTValidate from './GSTValidate';
import { addDays } from 'date-fns';
import {
  shouldDisableDate,
} from 'utils/functions/shouldDisableDate';
import { numberInputRegex } from 'utils/constants';
import { BlueSwitch } from 'components/RaySwitch';
import ConferenceReusable from 'components/ConferenceReusable';


export const DEFAULT_ERROR_STATES = {
  timesError: '',
  roomsError: '',
  gstinError: '',
  varaintsError: '',
};

const DEFAULT_CONF_STATE = {
  bookingDate: new Date(),
  noOfHours: 1,
  bookingStartTime: undefined,
  selectedRoom: {} as ConferenceRoom,
  errorState: DEFAULT_ERROR_STATES,
  selectedConfVariant: null,
};

// !TODO change handle for this conference seat to conf-room-seat
const CONFERENCE_HANDLE = PRODUCT_TYPES['conference-room-credit'].handle;
const CUSTOM_EVENT_HANDLE = PRODUCT_TYPES['custom-events'].handle;
const POSTPAID_EVENT_HANDLE = PRODUCT_TYPES['postpaid-events'].handle;
const ODE_EVENT_HANDLE = PRODUCT_TYPES['ode-events'].handle;
const SECURITY_DEPOSIT_HANDLE = PRODUCT_TYPES['security-deposit'].handle;
const EVENTS_SPACE_HANDLE = PRODUCT_TYPES['event-space'].handle;

function ShopDetailPage() {
  const classes = useStyles();
  const { state }: any = useLocation();
  const { setToastType } = useAuthContext()!;

  const { shopId }: ShopDetailPageParams = useParams();
  const [data, loader, error] = useFetchRequest('/shop/fetch-product-by-id', {
    product_id: shopId,
    partnerOrder: shopId === process.env.REACT_APP_GIFTCARD_SHOP_ID ? true : '',
  });
  // Conf room setup - Get Buildings Data
  const [buildingsData, buildingsLoader, buildingsError] = useFetchRequest(
    '/buildings/get-user-buildings',
  );

  // For events other than Conference Room
  const [filteredVariants, setFilteredVariants] = useState<any[]>([]);
  const [receivedVariants, setReceivedVariants] = useState<any[]>([]);

  const [confState, setConfState] = useState(DEFAULT_CONF_STATE);
  const updateState = (newState: any) => {
    setConfState((prevState) => ({ ...prevState, ...newState }));
  };
  const { bookingDate, noOfHours,
    bookingStartTime,
    selectedRoom,
    errorState, selectedConfVariant } = confState;

  const [selectedVariant, setSelectedVariant] = useState<any | null>();
  const [quantity, setQuantity] = useState<number>(1);

  const [email, setEmail] = useState<string>('');
  const [showNotes, toggleNotes] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const [
    selectedBuilding,
    setSelectedBuilding,
  ] = useState<BuildingOption | null>(null);
  const [bookingStartDate, setBookingStartDate] = useState<Date>(new Date());

  const [price, setPrice] = useState<number | undefined>();

  const [gstin, setGstin] = useState<string>('');
  const [gstValid, setGstValid] = useState<boolean>(false);

  const [oldConferenceType, toggleOldConferenceType] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [address1, setAddress1] = useState<string>('');
  const [address2, setAddress2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [stateCode, setStateCode] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [periodOfDepositDate, setPeriodOfDepositDate] = useState<
    Date | undefined
  >(new Date());

  const handlePeriodOfDepositDate = (e: any) => {
    if (e) {
      setPeriodOfDepositDate(new Date(e));
    }
  };

  const [errorStates, setErrorStates] = useState(DEFAULT_ERROR_STATES);

  const changeErrorsState = (type: keyof typeof errorStates, value: any) => {
    setErrorStates(prev => ({ ...prev, [type]: value }));
  };

  const [productType, setProductType] = useState<string>('');

  useEffect(() => {
    if (data && data.product) {
      if (data.product.variants.length > 0)
        setSelectedVariant(data.product.variants[0]);
      setProductType(data.product.handle);
      if (data.product.handle === CONFERENCE_HANDLE) {
        toggleOldConferenceType(true);
      }
      if (data.product.variants && data.product.variants.length > 0) {
        setReceivedVariants(data.product.variants);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, CONFERENCE_HANDLE]);



  useEffect(() => {
    if (productType === CONFERENCE_HANDLE) {
      setFilteredVariants([]);
    } else {
      setFilteredVariants(receivedVariants);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productType]);

  // Effect for autofilling email and building when coming from Bookings Page START ----->
  useEffect(() => {
    if (state && state.addOnState && state.addOnState.customerEmail) {
      setEmail(state.addOnState.customerEmail);
      if (buildingsData && buildingsData.length > 0) {
        const foundBuilding = buildingsData.find(
          (item: any) => item.name === state.addOnState.buildingName,
        );
        setSelectedBuilding({
          ...foundBuilding,
        });
      }
    }
  }, [state, buildingsData]);
  // <------ Effect for autofilling email and building when coming from Bookings Page END ---

  const setVariant = (data: any) => {
    setSelectedVariant(data);
  };

  const handleStartDate = (e: any) => {
    if (e && e.toDate()) {
      productType === CONFERENCE_HANDLE ? updateState({ 'bookingDate': e.toDate() }) :
        setBookingStartDate(e.toDate());
    }
  };

  // On Change of Building
  const handleChangeBuilding = (
    event: React.ChangeEvent<{}>,
    value: BuildingOption | null,
  ) => {
    if (value && value.id)
      setSelectedBuilding({
        ...value,
      });
    else if (value === null) {
      setSelectedBuilding(null);
    }
    setErrorStates(DEFAULT_ERROR_STATES);
  };

  useLayoutEffect(() => {
    if (productType === CONFERENCE_HANDLE) {
      updateState({ bookingDate: new Date() });
    }
  }, [productType]);

  // Rest Products
  if (productType === CUSTOM_EVENT_HANDLE)
    return <Redirect to="/shop/purchase-custom-products" />;
  if (productType === POSTPAID_EVENT_HANDLE)
    return <Redirect to="/shop/purchase-postpaid-products" />;
  if (productType === ODE_EVENT_HANDLE)
    return <Redirect to="/shop/ode-purchases" />;
  if (productType === EVENTS_SPACE_HANDLE)
    return <Redirect to="/shop/events" />;
  if (loader) return <Loader maxHeight />;
  return oldConferenceType ? (
    <OldConferenceFlow
      conferenceType={oldConferenceType}
      setConferenceType={toggleOldConferenceType}
    />
  ) : (
    <>
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12}>
          {state && state.addOnState && state.addOnState.customerEmail && (
            <div className={classes.backgroundYellow}>
              <small className="ray-text--body ">
                <b>
                  You are currently creating an add-on order associated with
                  order #{state.addOnState.orderNo}
                  <br />
                  If you do not want to associate this order with an existing
                  order, please visit the <Link to="/shop">Shop</Link> and
                  continue.
                </b>
              </small>
            </div>
          )}
          <H1>
            {data.product.title === 'Conference Room Credit'
              ? 'Conference Room Seat'
              : data.product.title}
          </H1>
        </Grid>
        {productType === CONFERENCE_HANDLE && (
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <BlueSwitch
                    checked={oldConferenceType}
                    onChange={() => toggleOldConferenceType(true)}
                    name="superAdminSwitch"
                  />
                }
                label="OFFLINE MODE"
              />
            </Grid>

            <Grid item xs={12}>
              <div className={classes.backgroundYellow}>
                <small className="ray-text--body ">
                  <b>
                    Note: Please use this mode ONLY if there is no other
                    conference room available in the building
                  </b>
                </small>
              </div>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {ReactHtmlParser(data.product.body_html)}
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justify="flex-start"
            spacing={2}
          >
            <Grid item xs={12}>
              <SelectInput
                key="building"
                size="medium"
                options={
                  buildingsData && buildingsData.length > 0
                    ? buildingsData.filter(
                      ({ disabled }: BuildingOption) => !disabled,
                    )
                    : []
                }
                getOptionLabel={option => option.name}
                disabled={buildingsLoader || buildingsError}
                label="Select Building"
                name="select-building"
                onChange={handleChangeBuilding}
              />
            </Grid>
            {(productType === CONFERENCE_HANDLE ||
              productType === PRODUCT_TYPES['parking'].handle) && (
                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <ThemeProvider theme={materialTheme}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          inputVariant="outlined"
                          style={{ width: '100%' }}
                          disableToolbar
                          variant="inline"
                          format="Do MMMM YYYY"
                          label="Booking Start Date"
                          value={productType === CONFERENCE_HANDLE ? bookingDate : bookingStartDate}
                          onChange={handleStartDate}
                          disablePast
                          shouldDisableDate={shouldDisableDate}
                          autoOk
                          maxDate={addDays(new Date(), 29)}
                        />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                  </Grid>
                </Grid>
              )}
            {/* Online conf room Selection */}
            {productType === CONFERENCE_HANDLE && <ConferenceReusable errorState={errorState} noOfHours={noOfHours} bookingStartTime={bookingStartTime} selectedRoom={selectedRoom}
              bookingDate={bookingDate} selectedBuilding={selectedBuilding} updateState={updateState} type={'online-conference-room'}/>}
            {data &&
              data.product &&
              data.product.variants &&
              data.product.variants.length > 1 && (
                <Grid item xs={12}>
                  <div className={classes.variantWrapper}>
                    {React.Children.toArray(
                      filteredVariants.map(variant => (
                        <Variant
                          info={variant}
                          selectedVariant={selectedVariant}
                          setVariant={setVariant}
                          productType={productType}
                        />
                      )),
                    )}
                  </div>
                </Grid>
              )}
            {productType !== 'gift-card' && productType !== CONFERENCE_HANDLE && (
              <Grid item xs={12}>
                <RayInput
                  type="text"
                  id="price"
                  pattern="[0-9]"
                  value={price}
                  placeholder={'Enter price'}
                  onChange={e =>
                    setPrice(
                      parseInt(e.target.value) > 0
                        ? parseInt(e.target.value)
                        : 0,
                    )
                  }
                  label="Price"
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <RayInput
                placeholder="arya.stark@wework.co.in"
                id="email__id"
                fullWidth
                autoMargin={false}
                value={email}
                onChange={e => setEmail(e.target.value)}
                label="Customer Email"
              />
              <small className="ray-text--body-x-small">
                This is the email that the payment link and invoice will be sent
                to.
              </small>
            </Grid>
            {productType === SECURITY_DEPOSIT_HANDLE && (
              <>
                <Grid item xs={12}>
                  <RayInput
                    placeholder="Arya Stark"
                    id="name"
                    type="text"
                    fullWidth
                    autoMargin={false}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label="Customer Name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <RayInput
                    placeholder="Enter address line 1"
                    id="address1__customer"
                    type="text"
                    fullWidth
                    autoMargin={false}
                    value={address1}
                    onChange={e => {
                      setAddress1(e.target.value);
                    }}
                    label="Address 1"
                  />
                </Grid>
                <Grid item xs={12}>
                  <RayInput
                    placeholder="Enter address line 2"
                    id="address2__customer"
                    type="text"
                    fullWidth
                    autoMargin={false}
                    value={address2}
                    onChange={e => {
                      setAddress2(e.target.value);
                    }}
                    label="Address 2"
                  />
                </Grid>
                <Grid item xs={12}>
                  <RayInput
                    placeholder="Enter city"
                    id="city__customer"
                    type="text"
                    fullWidth
                    autoMargin={false}
                    value={city}
                    onChange={e => {
                      setCity(e.target.value);
                    }}
                    label="City"
                  />
                </Grid>
                <Grid item xs={12}>
                  <RayInput
                    placeholder="Enter zip code"
                    id="zip__customer"
                    type="text"
                    pattern="[-+]?[0-9]*[.,]?[0-9]+"
                    fullWidth
                    autoMargin={false}
                    value={zip}
                    onChange={e => {
                      setZip(e.target.value.replace(numberInputRegex, ''));
                    }}
                    label="Zip code"
                  />
                </Grid>
                <Grid item xs={12}>
                  <RayInput
                    placeholder="Enter phone number"
                    id="phone__customer"
                    type="text"
                    pattern="[0-9]"
                    fullWidth
                    autoMargin={false}
                    value={phone}
                    onChange={e => {
                      setPhone(e.target.value.replace(numberInputRegex, ''));
                    }}
                    label="Phone number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <RayInput
                    placeholder="Enter state code (MH, KA)"
                    id="state_code__customer"
                    type="text"
                    fullWidth
                    autoMargin={false}
                    value={stateCode}
                    onChange={e => {
                      setStateCode(e.target.value.toUpperCase());
                    }}
                    label="State code"
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="ray-text-area">
                    <textarea
                      rows={4}
                      className="ray-text-area__input"
                      id="notes"
                      value={description}
                      onChange={e =>
                        setDescription(
                          e.target.value.replace(
                            /[*|":<>[\]{}`\\()';&$=]/g,
                            '',
                          ),
                        )
                      }
                    />
                    <label className="ray-text-area__label" htmlFor="notes">
                      Description
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <ThemeProvider theme={materialTheme}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          inputVariant="outlined"
                          style={{ width: '100%' }}
                          disableToolbar
                          variant="inline"
                          format="Do MMMM YYYY"
                          label="Period Date"
                          value={periodOfDepositDate}
                          onChange={handlePeriodOfDepositDate}
                          disablePast
                          autoOk
                        />
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                  </Grid>
                </Grid>
              </>
            )}
            {productType !== SECURITY_DEPOSIT_HANDLE && (
              <>
                <Grid item xs={12}>
                  <GSTValidate
                    gstin={gstin}
                    setGstin={setGstin}
                    gstinError={errorStates.gstinError}
                    setGstinError={(message: string) => {
                      changeErrorsState('gstinError', message);
                    }}
                    gstValid={gstValid}
                    setGstValid={setGstValid}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p
                    onClick={() => toggleNotes(!showNotes)}
                    className={classes.addNoteBtn}
                  >
                    {!showNotes ? 'Add Notes' : 'Hide Notes'}
                  </p>
                  {showNotes && (
                    <div className="ray-text-area">
                      <textarea
                        rows={4}
                        className="ray-text-area__input"
                        id="notes"
                        value={notes}
                        onChange={e =>
                          setNotes(
                            e.target.value.replace(
                              /[*|":<>[\]{}`\\()';&$=]/g,
                              '',
                            ),
                          )
                        }
                      />
                      <label className="ray-text-area__label" htmlFor="notes">
                        Notes (optional)
                      </label>
                    </div>
                  )}
                </Grid>

                <Grid item xs={12}>
                  {productType !== CONFERENCE_HANDLE && (
                    <div>
                      <H6>
                        {productType &&
                          PRODUCT_TYPES[productType]?.quantityEditorLabel}
                      </H6>
                      <QuantitySelector
                        onDecrement={() =>
                          setQuantity(prev => {
                            if (prev - 1 === 0) return prev;
                            return prev - 1;
                          })
                        }
                        onIncrement={() => setQuantity(quantity + 1)}
                        quantity={quantity}
                      />
                    </div>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <CheckoutCard
            email={email}
            name={name}
            address={{
              address1,
              address2,
              city,
              zip,
              phone,
              stateCode,
            }}
            description={description}
            periodOfDepositDate={periodOfDepositDate}
            notes={notes}
            building={selectedBuilding}
            // creditCount={2}
            selectedRoom={selectedRoom!}
            noOfHours={noOfHours}
            price={price}
            bookingDetails={
              productType === CONFERENCE_HANDLE
                ? {
                  date: bookingDate,
                  startTime: bookingStartTime,
                }
                : productType === PRODUCT_TYPES['parking'].handle
                  ? {
                    date: bookingStartDate,
                  }
                  : undefined
            }
            selectedVariant={productType === CONFERENCE_HANDLE
              ? selectedConfVariant : selectedVariant}
            quantity={quantity}
            productType={productType}
            gstin={gstin.toUpperCase()}
            disableOrderBtn={Boolean(errorStates.gstinError)}
            gstValid={gstValid}
          />
        </Grid>
      </Grid>
      {error &&
        setToastType({
          show: true,
          type: 'error',
          message: 'Details not fetched',
        })}
    </>
  );
}

export default ShopDetailPage;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    backgroundYellow: {
      backgroundColor: '#FFD500',
      borderRadius: '2px',
      padding: '0.5em',
      marginBottom: '1em',
    },
    buildingSelector: {
      width: '100%',
    },
    variantWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    addNoteBtn: {
      fontSize: '1.1em',
      color: '#0000FF',
      marginBottom: '1em',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    loaderGrid: {
      textAlign: 'center',
      '& svg': {
        color: '#0000FF',
      },
    },
    fieldError: {
      color: '#CC0000',
    },
  }),
);

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#0000FF',
      },
      current: {
        color: '#9999FF',
      },
    },
  },
});
