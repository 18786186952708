import { useQuery, UseQueryOptions } from 'react-query';
import axios from 'utils/axios';
import { useOktaAuth } from '@okta/okta-react';

type FetchApiOptions = {
  url: string;
  params?: any;
};

const fetchApiData = async (
  url: string,
  token: string,
  params: any
) => {
  const { data } = await axios.get(url, {
    params,
    headers: {
      Authorization: token,
    },
  });
  return data;
};

export const useReactQuery = (
  identifier: (string | number | undefined)[],
  { url, params = {} }: FetchApiOptions,
  options?:
    | UseQueryOptions<any, unknown, any, (string | number | undefined)[]>
    | undefined
) => {
  const {authState} = useOktaAuth();

  return useQuery(
    identifier,
    () => fetchApiData(url, authState?.accessToken?.accessToken || '', params),
    options
  );
};
