import React, { useState, useEffect, useImperativeHandle } from 'react';
import styled from 'styled-components';
import ModalDialog from 'components/ModalDialog';
import ViewBookingDetails from './ViewBookingDetails';
import { Pagination } from '@material-ui/lab';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import Loader from 'components/Loader';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'utils/axios';
import { DropdownProps } from './types';
import { format } from 'date-fns';
import { useOktaAuth } from '@okta/okta-react';
import { useAuthContext } from 'utils/hooks/useAuthContext';

const Wrapper = styled.div`
  width: 100%;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  table {
    font-size: 14px;
    width: 100%;
  }
  tr.booking_row:hover {
    cursor: pointer;
  }
  .no-bookings {
    margin-top: 20%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  .user-annotation-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
  }
  .yellow-box {
    background: #efa942;
    color: black;
  }
  .green-box {
    background: #12872a;
    color: white;
  }
  .user-annotation-banner {
    display: flex;
    margin-left: 5px;
  }
  .user-annotation-banner > span {
    height: 20px;
    width: 42px;
    align-items: center;
    padding: 0px 5px;
    border-radius: 3px;
  }
  .user-annotation-sticker {
    display: flex;
  }
`;

const PaginationWrapper = styled.div`
  margin-top: 1.5em;
  display: flex;
  justify-content: inherit;
`;

type Props = {
  className?: string;
  bookingsType: string;
  setBookingsTypeCount: React.Dispatch<number>;
  setMembersCount: React.Dispatch<number>;
  searchString: string;
  reference: any;
  buildingId?: number;
  hits: number;
  orderType: DropdownProps | null;
  selectedDateRange: any;
};

export type InvoiceData = {
  pdfURL: string | null;
  typeOfInvoice: string | null;
  isDiscountedOrder: boolean | null;
  GSTINValidity: string | null;
  checkoutUserEmail: string | null;
};

const formatToDesiredDate = (dateString: string): string => {
  const stringInFormat = dateString.split('/');
  const dateInDDMMYYY =
    stringInFormat[1] + '/' + stringInFormat[0] + '/' + stringInFormat[2];
  return format(new Date(dateInDDMMYYY), 'do MMM yyyy');
};

const TableContainer = React.forwardRef(
  ({
    bookingsType,
    setBookingsTypeCount,
    setMembersCount,
    hits,
    searchString,
    reference,
    buildingId,
    orderType,
    selectedDateRange,
  }: Props) => {
    const { useAlgolia } = useAuthContext()!;
    const classes = useStyles();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [countData, setCountData] = useState<any>({});
    const [countLoading, setCountLoading] = useState<boolean>(true);
    const { data, isLoading, isFetching, refetch } = useReactQuery(
      ['dayPassBookings', bookingsType, hits, currentPage],
      {
        url: useAlgolia ? '/orders/bookings' : 'api/v1/admin/bookings/list',
        params: {
          bookingType: bookingsType,
          buildingId,
          isB2bOrder: orderType === null ? 'nil' : orderType.label,
          bookingsStartDate: selectedDateRange[0].startDate.toDateString(),
          bookingsEndDate: selectedDateRange[0].endDate.toDateString(),
          ...(useAlgolia
            ? { pageNo: currentPage, pageSize: hits, searchString }
            : { page: currentPage - 1, limit: hits, value: searchString }),
        },
      },
      {
        enabled: !!bookingsType,
        // refetchOnWindowFocus: false, use this to stop refetching every time we enter to the application.
        // keepPreviousData: true,
      },
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      const getCountData = async () => {
        try {
          const { data: countData } = await axios.get(
            '/orders/bookings/passes/count',
            {
              params: {
                userEmails: (useAlgolia
                  ? data.bookingInfo
                  : data?.data?.hits
                ).map((item: any) =>
                  item.customerEmail ? item.customerEmail : null,
                ),
              },
              headers: {
                Authorization: authState?.accessToken?.accessToken,
              },
            },
          );
          setCountData(countData);
          setCountLoading(false);
        } catch (error) {
          console.log('Error Fetching count info', error);
          setCountLoading(false);
        } finally {
          setCountLoading(false);
        }
      };
      setCountLoading(true);
      if (
        useAlgolia
          ? data && data.bookingInfo && data.bookingInfo.length
          : data?.data?.hits?.length
      ) {
        getCountData();
      } else {
        setCountLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
      if (buildingId) {
        refetch();
      }
      refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hits, buildingId]);

    const [detailModal, toggleDetailModal] = useState<boolean>(false);
    const [selectedBooking, setSelectedBooking] = useState<any>();

    const { authState } = useOktaAuth();
    const [invoiceResponse, setInvoiceResponse] = useState<InvoiceData>({
      GSTINValidity: null,
      isDiscountedOrder: null,
      pdfURL: null,
      typeOfInvoice: null,
      checkoutUserEmail: null,
    });

    useImperativeHandle(reference, () => ({
      refreshLoad() {
        setCurrentPage(1);
        setTimeout(() => {
          refetch();
        }, 1000);
      },
    }));

    useEffect(() => {
      if (!isLoading) {
        setBookingsTypeCount(
          useAlgolia ? data?.noOfHits : data?.data?.pagination?.count || 0,
        );
        setMembersCount(useAlgolia ? data?.noOfMembers : data?.data?.noOfMembers);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading]);

    const handleBookingRowClick = async (cell: any) => {
      setSelectedBooking(cell);
      // Reset Previous State.
      setInvoiceResponse({
        pdfURL: null,
        isDiscountedOrder: null,
        GSTINValidity: null,
        typeOfInvoice: null,
        checkoutUserEmail: null,
      });
      if (!cell.is_b2b_order && cell.orderId) {
        try {
          const { data } = await axios.get('/invoices/admin/invoices', {
            params: {
              order_id: cell.orderId,
            },
            headers: {
              Authorization: authState?.accessToken?.accessToken,
            },
          });

          const { data: invoiceData } = data;
          setInvoiceResponse(invoiceData as InvoiceData);
          toggleDetailModal(true);
          return;
        } catch (error) {
          console.log('Error Fetching invoice info', error);
        }
      }
      toggleDetailModal(true);
    };

    const handlePageChange = (
      event: React.ChangeEvent<unknown>,
      pageNo: number,
    ) => {
      setCurrentPage(pageNo);
    };
    if (isLoading || isFetching || countLoading) {
      return <Loader customHeight="75vh" />;
    }
    return (
      <>
        <Wrapper>
          {(useAlgolia
            ? data && data.bookingInfo && data.bookingInfo.length > 0
            : data && data.data?.hits?.length > 0) && (
            <table className="ray-table">
              <thead>
                <tr>
                  <th>Booking Date</th>
                  <th>Location</th>
                  <th>User Name</th>
                  <th>Email</th>
                  {/* <th>Phone</th>
                <th>Order No</th>
                <th>Is B2B Order</th> */}
                  <th>Company Name</th>
                  <th>Items</th>
                </tr>
              </thead>
              <tbody>
                {React.Children.toArray(
                  (useAlgolia ? data.bookingInfo : data.data?.hits).map(
                    (cell: any) => (
                      <tr
                        className="booking_row"
                        onClick={() => handleBookingRowClick(cell)}
                      >
                        <td>
                          {cell.dateString
                            ? formatToDesiredDate(cell.dateString)
                            : '--'}
                        </td>
                        <td>{cell.locationName ? cell.locationName : '--'}</td>
                        <div className="user-annotation-sticker">
                          <u>{`${
                            cell.customerName ? cell.customerName : '--'
                          }`}</u>
                          {countData && countData.data ? (
                            <span className={`user-annotation-banner`}>
                              {countData.data[cell.customerEmail] > 10 ? (
                                <span
                                  className={'user-annotation-text green-box'}
                                >
                                  +10
                                </span>
                              ) : countData.data[cell.customerEmail] === 0 ? (
                                <span
                                  className={'user-annotation-text yellow-box'}
                                >
                                  New
                                </span>
                              ) : (
                                <></>
                              )}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <td>
                          {cell.customerEmail ? cell.customerEmail : '--'}
                        </td>
                        {/* <td>{cell.customerPhone ? cell.customerPhone : '--'}</td>
                      <td>{cell.orderNumber ? cell.orderNumber : '--'}</td>
                      <td>{cell.is_b2b_order ? 'Yes' : 'No'}</td> */}
                        <td>
                          {cell.customerCompany ? cell.customerCompany : '--'}
                        </td>
                        {/* <td>{cell.quantity ? cell.quantity : '0'}</td> */}
                        <td>
                          {cell.quantity > 1
                            ? cell.quantity + ' Items'
                            : cell.quantity === 1
                            ? cell.quantity + ' Item'
                            : '0'}
                        </td>
                      </tr>
                    ),
                  ),
                )}
              </tbody>
            </table>
          )}
          {(useAlgolia
            ? data && data.bookingInfo && data.bookingInfo.length === 0
            : data && data.data?.hits?.length === 0) && (
            <p className="no-bookings">
              No bookings available for the selected filter
            </p>
          )}
          {(useAlgolia
              ? data?.noOfPagesAvailable
              : data?.data?.pagination?.totalPages) > 0 && (
            <PaginationWrapper>
              <span>
                <div className="user-annotation-sticker">
                  <span className={`user-annotation-banner`}>
                    <span
                      className={'user-annotation-text green-box'}
                      style={{ marginRight: '10px' }}
                    >
                      +10
                    </span>
                    Recurring user with more than 10 bookings
                  </span>
                  <span className={`user-annotation-banner`}>
                    <span
                      className={'user-annotation-text yellow-box'}
                      style={{ margin: '0px 10px 0px 20px' }}
                    >
                      New
                    </span>{' '}
                    First time booking
                  </span>
                </div>
              </span>
              {data &&
            (useAlgolia
              ? data?.noOfPagesAvailable
              : data?.data?.pagination?.totalPages) > 0 && (
              <Pagination
                count={
                  useAlgolia
                    ? data?.noOfPagesAvailable
                    : data?.data?.pagination?.totalPages
                }
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
                classes={{ ul: classes.ul }}
              />
              )}
            </PaginationWrapper>
            )}
        </Wrapper>
        <ModalDialog
          isOpen={detailModal}
          showModal={toggleDetailModal}
          fullWidth
          maxWidth="md"
          title="Booking Info"
        >
          <ViewBookingDetails
            bookingInfo={selectedBooking}
            showModal={toggleDetailModal}
            invoiceResponse={invoiceResponse}
          />
        </ModalDialog>
      </>
    );
  },
);

export default TableContainer;

const useStyles = makeStyles(() => ({
  ul: {
    '& .Mui-selected': {
      backgroundColor: '#0000ff',
      color: '#fff',
    },
  },
}));
