import { Grid, createMuiTheme } from '@material-ui/core'
import React from 'react'
import {
    ThemeProvider,
} from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { addDays } from 'date-fns';
import { shouldDisableDate } from 'utils/functions/shouldDisableDate';
import ConferenceReusable from 'components/ConferenceReusable';
import RayInput from 'components/RayInput';

const Reservation = ({ reservationState, updateState }: any) => {
    const { selectedBuilding, bookingDate, noOfHours,name,
        bookingStartTime,
        selectedRoom,
     errorState } = reservationState;

    const reservationProps = {
        selectedBuilding, bookingDate, noOfHours,
        bookingStartTime,
        selectedRoom,
        errorState
    };

    const handleStartDate = (e: any) => {
        if (e && e.toDate()) {
            updateState({ 'bookingDate': e.toDate() })
        }
    };

    return (
        <Grid container item spacing={2}>
            <Grid item xs={12}>
              <RayInput
                placeholder="Arya Stark"
                id="name"
                type="text"
                fullWidth
                autoMargin={false}
                value={name}
                onChange={e =>  updateState({ name: e.target.value })}
                label="Name"
              />
            </Grid>
            <Grid item xs={12}>
                <ThemeProvider theme={materialTheme}>
                    
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            inputVariant="outlined"
                            style={{ width: '100%' }}
                            disableToolbar
                            variant="inline"
                            format="Do MMMM YYYY"
                            label="Booking Start Date"
                            value={reservationState.bookingDate}
                            onChange={handleStartDate}
                            disablePast
                            shouldDisableDate={shouldDisableDate}
                            autoOk
                            maxDate={addDays(new Date(), 29)}
                        />
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </Grid>
            <Grid item xs={12}>
                <ConferenceReusable {...reservationProps} updateState={updateState} type={'event-space'} />
            </Grid>
        </Grid>
    )
}

export default Reservation

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersDay: {
            daySelected: {
                backgroundColor: '#0000FF',
            },
            current: {
                color: '#9999FF',
            },
        },
    },
});