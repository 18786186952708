import React from "react";
import styled from "styled-components";

type InputProps = {
  type?: React.InputHTMLAttributes<unknown>["type"];
  placeholder?: string;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  onClick?:
    | ((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void)
    | undefined;
  label?: string;
  name?: string;
  ref?: React.Ref<any>;
  style?: React.CSSProperties;
  id?: string;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  value?: number | undefined;
  autoMargin?: boolean;
  increment?: () => void;
  decrement?: () => void;
};

const Input = ({
  id,
  name,
  type = "number",
  placeholder,
  onChange,
  onClick,
  label,
  style,
  ref,
  className,
  value,
  fullWidth = false,
  autoMargin = true,
  disabled = false,
  increment,
  decrement,
}: InputProps) => {
  return (
    <div className={`ray-text-field ${className}`}>
      <input
        id={id}
        onChange={onChange}
        name={name}
        disabled={disabled}
        onClick={onClick}
        className="ray-text-field__input"
        ref={ref}
        type={type}
        placeholder={placeholder}
        style={style}
        value={value}
      />
      <label className="ray-text-field__label" htmlFor={id}>
        {label}
      </label>
      <button
        type="button"
        onClick={decrement}
        className="ray-button ray-button--primary ray-button--dark"
      >
        -
      </button>
      <button
        type="button"
        onClick={increment}
        className="ray-button ray-button--primary ray-button--dark"
      >
        +
      </button>
    </div>
  );
};

const InputWrapper = styled(Input)`
  margin: ${(props) => (props.autoMargin ? "0.5em" : "0")};
  width: ${(props) => (props.fullWidth ? "100%" : "20%")};
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  };
  button{
    font-size: 1.5em;
    display:flex;
    justify-content: center;
    align-items: center;
  };
`;

export default InputWrapper;
