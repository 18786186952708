import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import {
  makeStyles,
  createStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { createMuiTheme, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFetchRequest } from 'utils/hooks/useFetchRequest';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import { H6 } from 'components/Typography';
import QuantitySelector from 'components/QuantitySelector';
import RayNumInput from 'components/RayInput/RayNumInput';
import RayInput from 'components/RayInput';
import SelectInput from 'components/MaterialSelect/Autocomplete';
import {
  PRODUCT_TYPES,
  BuildingOption,
  ConferenceRoom,
  ConferenceRoomResponseData,
} from './types';
import CheckoutCard from './CheckoutCard';
import TimePill from '../ShopDetailPage/TimePill';
import OldConferenceFlow from './OldConferenceFlow';
import VariantCardOde from './VariantOde';
import Loader from 'components/Loader';
import axiosGlobal from 'utils/axiosGlobal';
import { addDays, format } from 'date-fns';
import {
  getBookingDateString,
  shouldDisableDate,
} from 'utils/functions/shouldDisableDate';
import { useOktaAuth } from '@okta/okta-react';

const RedSwitch = withStyles({
  switchBase: {
    color: '#CC0000',
    '&$checked': {
      color: '#0000CC',
    },
    '&$checked + $track': {
      backgroundColor: '#CC0000',
    },
  },
  checked: {
    color: '#FF0000',
    '&$checked': {
      color: '#FF0000',
    },
    '&$checked + $track': {
      backgroundColor: '#FF0000',
    },
  },
  track: {},
})(Switch);

const getStartAndEndTime = (date: Date | string) => {
  const bookingDate = new Date(date).toDateString();
  const selectedBookingDate = format(new Date(bookingDate), 'yyyy-MM-dd');
  const startTime = selectedBookingDate + 'T03:30:00.000Z';
  const endTime = selectedBookingDate + 'T11:30:00.000Z';
  return [startTime, endTime];
};

const fetchBookingStartTimes = async (
  params: {
    startTime: string;
    endTime: string;
    id: string;
    noOfHrs: string;
  },
  token: string | undefined,
) => {
  try {
    type ResponseType = {
      data: {
        data: string[];
        message: string;
        success: boolean;
      };
    };
    const response: ResponseType = await axiosGlobal.get(
      `/api/v1/admin/conference-rooms/availability`,
      {
        params,
        headers: {
          Authorization: token,
        },
      },
    );
    return { success: true, items: response.data.data };
  } catch (error) {
    console.error(error);
    return { success: false, items: [] };
  }
};

const DEFAULT_LOADING_STATES = {
  timesLoader: false,
  roomsLoader: false,
};
const DEFAULT_ERROR_STATES = {
  timesError: '',
  roomsError: '',
  gstinError: '',
};

type Props = {
  id: string;
  enterprise: any;
};

// !TODO change handle for this conference seat to conf-room-seat
const CONFERENCE_HANDLE = 'conference-room-shop-purchase';

function Form({ id: product_id, enterprise }: Props) {
  const classes = useStyles();
  const { state }: any = useLocation();
  const { setToastType } = useAuthContext()!;
  const {authState} = useOktaAuth();

  const [data, loader, error] = useFetchRequest(
    '/shop/fetch-product-by-id',
    {
      product_id,
    },
    { refetchOn: product_id },
  );

  const [buildingsData, buildingsLoader, buildingsError] = useFetchRequest(
    '/buildings/get-user-buildings',
  );
  const [credits, setCredits] = useState<number>(1);

  // For events other than Conference Room
  const [filteredVariants, setFilteredVariants] = useState<any[]>([]);
  const [receivedVariants, setReceivedVariants] = useState<any[]>([]);

  const [authToken, setAuthToken] = useState<string | undefined >('');

  const [selectedVariant, setSelectedVariant] = useState<any | null>();
  const [quantity, setQuantity] = useState<number>(1);

  const [email, setEmail] = useState<string>('');
  const [showNotes, toggleNotes] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const [memberCount, setMemberCount] = useState<number | any>();

  const [noOfHours, setNoOfHours] = useState<number | any>();
  const [
    selectedBuilding,
    setSelectedBuilding,
  ] = useState<BuildingOption | null>(null);
  const [bookingStartDate, setBookingStartDate] = useState<any>(new Date());
  const [bookingStartTime, setBookingStartTime] = useState<any>();

  const [oldConferenceType, toggleOldConferenceType] = useState<boolean>(false);

  const [loaders, setLoaders] = useState(DEFAULT_LOADING_STATES);

  const changeLoadersState = (type: keyof typeof loaders, value: boolean) => {
    setLoaders(prev => ({
      ...prev,
      [type]: value,
    }));
  };
  const [errorStates, setErrorStates] = useState(DEFAULT_ERROR_STATES);

  const changeErrorsState = (type: keyof typeof errorStates, value: any) => {
    setErrorStates(prev => ({
      ...prev,
      [type]: value,
    }));
  };
  const [availableTimes, setAvailableTimes] = useState<string[]>([]);

  const [productType, setProductType] = useState<string>('');
  const [selectedRoom, setSelectedRoom] = useState<ConferenceRoom>();

  const [roomList, setRoomList] = useState<ConferenceRoom[]>([]);

  const [rightHotStamp, setRightHotStamp] = useState('');
  const [leftHotStamp, setLeftHotStamp] = useState('');

  useLayoutEffect(() => {
    if (productType === CONFERENCE_HANDLE) {
      setBookingStartDate(getBookingDateString(new Date()));
    }
  }, [productType, product_id]);

  useLayoutEffect(() => {
    setNotes('');
    setEmail('');
    toggleNotes(false);
    setQuantity(1);
    setBookingStartDate(new Date());
    setAvailableTimes([]);
    // eslint-disable-next-line
  }, [product_id, enterprise]);

  useEffect(() => {
    if (data && data.product) {
      if (data?.product?.variants?.length > 0)
        setSelectedVariant(data?.product?.variants[0]);
      setProductType(data?.product?.handle);
      if (data?.product?.handle === CONFERENCE_HANDLE) {
        setMemberCount(2);
        setNoOfHours(1);
        fetchAuthToken();
        toggleOldConferenceType(true);
      } else {
        toggleOldConferenceType(false);
        setMemberCount(null);
      }
      if (data?.product?.variants && data?.product?.variants.length > 0) {
        setReceivedVariants(data?.product?.variants);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, CONFERENCE_HANDLE]);

  const fetchAuthToken = async () => {
    const token = authState?.accessToken?.accessToken;
    setAuthToken(token);
  };

  useEffect(() => {
    if (productType === CONFERENCE_HANDLE) {
      setFilteredVariants([]);
    } else {
      setFilteredVariants(receivedVariants);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCount, productType]);

  // Effect for autofilling email and building when coming from Bookings Page START ----->
  useEffect(() => {
    if (state && state.addOnState && state.addOnState.customerEmail) {
      setEmail(state.addOnState.customerEmail);
      if (buildingsData && buildingsData.length > 0) {
        const foundBuilding = buildingsData.find(
          (item: any) => item.name === state.addOnState.buildingName,
        );
        setSelectedBuilding(foundBuilding);
      }
    }
  }, [state, buildingsData]);
  // <------ Effect for autofilling email and building when coming from Bookings Page END ---

  const fetchTimeSlots = AwesomeDebouncePromise(
    async (params: {
      startTime: string;
      endTime: string;
      id: string;
      noOfHrs: string;
    }) => {
      changeLoadersState('timesLoader', true);
      const timesResponse = await fetchBookingStartTimes(params, authToken);
      setAvailableTimes(timesResponse.items);
      if (timesResponse.items.length === 0)
        changeErrorsState(
          'timesError',
          'Sorry, we dont have any slots available.',
        );
      else changeErrorsState('timesError', '');

      changeLoadersState('timesLoader', false);
    },
    700,
  );

  useEffect(() => {
    if (roomList.length && bookingStartDate && noOfHours && selectedRoom) {
      const [startTime, endTime] = getStartAndEndTime(bookingStartDate);
      const id = selectedRoom?.id;
      if (!startTime || !endTime || !id) {
        return;
      }
      const params = {
        startTime,
        endTime,
        id,
        noOfHrs: noOfHours,
      };
      fetchTimeSlots(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingStartDate, noOfHours, selectedRoom, roomList]);

  const getAllConferenceRooms = async (
    pageNumber: number,
    totalConferenceRooms: ConferenceRoom[],
  ): Promise<void> => {
    const bodyParams = {
      pageNumber,
      limit: 50,
      locationId: selectedBuilding?.locationUuid,
    };
    // API CALL
    const {
      data: {
        data: { pagination, result },
      },
    }: {
      data: ConferenceRoomResponseData;
    } = await axiosGlobal.post('/api/v1/admin/conference-rooms/', bodyParams, {
      headers: {
        Authorization: authToken,
      },
    });

    if (result) {
      for (const room of result) {
        if (
          room &&
          'productId' in room &&
          'variantId' in room &&
          'capacity' in room &&
          room.capacity &&
          room.productId &&
          room.variantId
        ) {
          totalConferenceRooms.push(room);
        }
      }
    }
    if (pagination.pageNumber + 1 >= pagination.totalPages) {
      return;
    }
    await getAllConferenceRooms(
      pagination.pageNumber + 1,
      totalConferenceRooms,
    );
  };

  const fetchConferenceRoomsFromGlobal = async () => {
    try {
      changeLoadersState('roomsLoader', true);
      const totalConferenceRooms: ConferenceRoom[] = [];
      await getAllConferenceRooms(0, totalConferenceRooms);
      if (!totalConferenceRooms.length) {
        throw new Error('No rooms available');
      }
      setRoomList(totalConferenceRooms);
    } catch (err) {
      let error: any = err;
      console.error(error);
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'Something went wrong please try again in sometime!';
      // dispatchToast("error", err?.response?.data?.message);
      changeErrorsState('roomsError', errorMessage);
    } finally {
      changeLoadersState('roomsLoader', false);
    }
  };

  // Effect that runs all the APIs
  useEffect(() => {
    if (
      productType === CONFERENCE_HANDLE &&
      selectedBuilding &&
      'locationUuid' in selectedBuilding &&
      selectedBuilding.locationUuid
    ) {
      fetchConferenceRoomsFromGlobal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuilding, productType]);

  const setVariant = (data: any) => {
    setSelectedVariant(data);
  };

  const handleStartDate = (e: any) => {
    if (e && e.toDate()) {
      setBookingStartDate(e.toDate());
    }
  };

  const handleStartTime = (e: any) => {
    setBookingStartTime(e);
  };

  // On Change of Cabin
  const handleCabinChange = (
    _: React.ChangeEvent<{}>,
    value: ConferenceRoom | null,
  ) => {
    handleStartTime(undefined);
    if (value) {
      setSelectedRoom(value);
      return;
    }
    setSelectedRoom(undefined);
    setAvailableTimes([]);
  };

  // On Change of Building
  const handleChangeBuilding = (
    event: React.ChangeEvent<{}>,
    value: BuildingOption | null,
  ) => {
    if (value && value.id)
      setSelectedBuilding({
        ...value,
      });
    else if (value === null) {
      setSelectedBuilding(null);
    }
    handleStartTime(undefined);
    setRoomList([]);
    setSelectedRoom(undefined);
    setAvailableTimes([]);
    setErrorStates(DEFAULT_ERROR_STATES);
  };

  if (loader) {
    return <Loader />;
  }
  return oldConferenceType ? (
    <OldConferenceFlow
      conferenceType={oldConferenceType}
      setConferenceType={toggleOldConferenceType}
      enterprise={enterprise}
      data={data}
    />
  ) : (
    <>
      <Grid container justify="space-between">
        <Grid item xs={12}>
          {state && state.addOnState && state.addOnState.customerEmail && (
            <div className={classes.backgroundYellow}>
              <small className="ray-text--body ">
                <b>
                  You are currently creating an add-on order associated with
                  order #{state.addOnState.orderNo}
                  <br />
                  If you do not want to associate this order with an existing
                  order, please visit the <Link to="/shop">Shop</Link> and
                  continue.
                </b>
              </small>
            </div>
          )}
        </Grid>
        {productType === CONFERENCE_HANDLE && (
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <RedSwitch
                    checked={oldConferenceType}
                    onChange={() => toggleOldConferenceType(true)}
                    name="superAdminSwitch"
                  />
                }
                label="OFFLINE MODE"
              />
            </Grid>

            <Grid item xs={12}>
              <div className={classes.backgroundYellow}>
                <small className="ray-text--body ">
                  <b>
                    Note: Please use this mode ONLY if there is no other
                    conference room available in the building
                  </b>
                </small>
              </div>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {ReactHtmlParser(data?.product?.body_html)}
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justify="flex-start"
            spacing={2}
            className={classes.gridWidth}
          >
            <Grid item xs={12}>
              <SelectInput
                key="building"
                size="medium"
                options={
                  buildingsData && buildingsData.length > 0
                    ? buildingsData.filter(
                        ({ disabled }: BuildingOption) => !disabled,
                      )
                    : []
                }
                getOptionLabel={option => option.name}
                disabled={buildingsLoader || buildingsError}
                label="Select Building"
                name="select-building"
                onChange={handleChangeBuilding}
              />
            </Grid>
            {productType === CONFERENCE_HANDLE && (
              <Grid item xs={12}>
                <ThemeProvider theme={materialTheme}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      style={{
                        width: '100%',
                      }}
                      disablePast
                      inputVariant="outlined"
                      disableToolbar
                      variant="inline"
                      format="Do MMMM YYYY"
                      label="Booking Start Date"
                      value={bookingStartDate}
                      onChange={handleStartDate}
                      autoOk
                      shouldDisableDate={shouldDisableDate}
                      maxDate={addDays(new Date(), 29)}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Grid>
            )}
            {/* Cabin Room Selection */}
            {productType === CONFERENCE_HANDLE && (
              <>
                {!loaders['roomsLoader'] &&
                  !errorStates['roomsError'] &&
                  roomList.length > 0 && (
                    <Grid item xs={12}>
                      <SelectInput
                        size="medium"
                        key="building"
                        options={roomList}
                        getOptionLabel={option =>
                          `${option.name} (${option.capacity} Seater)`
                        }
                        disabled={
                          roomList.length === 0 ||
                          loaders['roomsLoader'] ||
                          !!errorStates['roomsError']
                        }
                        label="Select a cabin"
                        name="select-conference-room"
                        onChange={handleCabinChange}
                      />
                    </Grid>
                  )}
                {loaders['roomsLoader'] && (
                  <Grid item xs={12} className={classes.loaderGrid}>
                    <CircularProgress size={25} style={{ color: '#0000FF' }} />
                  </Grid>
                )}
                {!loaders['roomsLoader'] &&
                  availableTimes.length === 0 &&
                  errorStates.roomsError.length > 0 && (
                    <Grid item xs={12} className={classes.loaderGrid}>
                      <span
                        style={{ color: '#990000' }}
                        className="ray-text--body"
                      >
                        {errorStates.roomsError}
                      </span>
                    </Grid>
                  )}
              </>
            )}
            {productType === CONFERENCE_HANDLE && (
              <>
                <Grid item xs={12}>
                  <RayNumInput
                    id="no_of_credits"
                    value={credits}
                    increment={() => {
                      setCredits(prev => prev + 1);
                    }}
                    decrement={() => {
                      if (!(credits - 1 < 1)) setCredits(prev => prev - 1);
                    }}
                    onChange={e => setCredits(Number(e.target.value))}
                    label="Credits"
                    fullWidth
                  />
                </Grid>
              </>
            )}
            {data &&
              data.product &&
              data.product.variants &&
              data.product.variants.length > 1 && (
                <Grid item xs={12}>
                  <div className={classes.variantWrapper}>
                    {React.Children.toArray(
                      filteredVariants.map(variant => (
                        <VariantCardOde
                          info={variant}
                          selectedVariant={selectedVariant}
                          setVariant={setVariant}
                          productType={productType}
                        />
                      )),
                    )}
                  </div>
                </Grid>
              )}
            {productType === CONFERENCE_HANDLE && (
              <Grid item xs={12}>
                <RayNumInput
                  id="no_of_hours"
                  value={noOfHours}
                  increment={() => {
                    if (noOfHours + 1 > 9) return;
                    setNoOfHours(noOfHours + 1);
                    handleStartTime(undefined);
                  }}
                  decrement={() => {
                    if (noOfHours - 1 < 1) return;
                    setNoOfHours(noOfHours - 1);
                    handleStartTime(undefined);
                  }}
                  onChange={e => {
                    const value = Number(e.target.value);
                    if (Number.isNaN(value) || value <= 0 || value > 9) return;
                    setNoOfHours(value);
                    handleStartTime(undefined);
                  }}
                  label="No. of Hours"
                  fullWidth
                />
              </Grid>
            )}
            {productType === CONFERENCE_HANDLE && (
              <>
                {!loaders['timesLoader'] && availableTimes.sort().length > 0 && (
                  <Grid item xs={12}>
                    <div className={`${classes.variantWrapper} ray-grid`}>
                      {React.Children.toArray(
                        availableTimes.map(slot => (
                          <TimePill
                            timeSlot={slot}
                            key={slot}
                            selectedStartTime={bookingStartTime}
                            setStartTime={handleStartTime}
                            bookingDate={bookingStartDate}
                          />
                        )),
                      )}
                    </div>
                  </Grid>
                )}

                {loaders['timesLoader'] && (
                  <Grid item xs={12} className={classes.loaderGrid}>
                    <CircularProgress size={25} style={{ color: '#0000FF' }} />
                  </Grid>
                )}
                {!loaders['timesLoader'] &&
                  availableTimes.length === 0 &&
                  errorStates.timesError.length > 0 && (
                    <Grid item xs={12} className={classes.loaderGrid}>
                      <span
                        style={{ color: '#990000' }}
                        className="ray-text--body"
                      >
                        {errorStates.timesError}
                      </span>
                    </Grid>
                  )}
              </>
            )}
            <Grid item xs={12}>
              <RayInput
                placeholder="arya.stark@wework.co.in"
                id="email__id"
                type="text"
                fullWidth
                autoMargin={false}
                value={email}
                onChange={e => setEmail(e.target.value)}
                label="Member Email"
              />
              <small className="ray-text--body-x-small">
                This email should include the members email of the same
                enterprise.
              </small>
            </Grid>
            {productType === "lost-keycard-ode" &&  
            <Grid container justify='space-between' style={{padding: "0 8px"}}>
              <Grid item>
              <RayInput
                placeholder="Left hot stamp"
                id="left_hot_stamp"
                type="text"
                fullWidth
                autoMargin={false}
                value={leftHotStamp}
                onChange={e => setLeftHotStamp(e.target.value)}
                label="Left Hot Stamp"
              />
              <small className="ray-text--body-x-small">
              Enter a 10 digit number, Only numbers are allowed
              </small>
              </Grid>
              <Grid item>
              <RayInput
                placeholder="Right hot stamp"
                id="right_hot_stamp"
                type="text"
                fullWidth
                autoMargin={false}
                value={rightHotStamp}
                onChange={e => setRightHotStamp(e.target.value)}
                label="Right Hot Stamp"
              />
              <small className="ray-text--body-x-small">
              Enter a 7 digit number, Only numbers are allowed
              </small>
              </Grid>
            </Grid>}

            <Grid item xs={12}>
              <p
                onClick={() => toggleNotes(!showNotes)}
                className={classes.addNoteBtn}
              >
                {!showNotes ? 'Add Notes' : 'Hide Notes'}
              </p>
              {showNotes && (
                <div className="ray-text-area">
                  <textarea
                    rows={4}
                    className="ray-text-area__input"
                    id="notes"
                    onChange={e => setNotes(e.target.value)}
                  />
                  <label className="ray-text-area__label" htmlFor="notes">
                    Notes (optional)
                  </label>
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              {productType !== CONFERENCE_HANDLE && (
                <div>
                  <H6>
                    {productType &&
                      PRODUCT_TYPES[productType]?.quantityEditorLabel}
                  </H6>
                  <QuantitySelector
                    onDecrement={() =>
                      setQuantity(prev => {
                        if (prev - 1 === 0) return prev;
                        return prev - 1;
                      })
                    }
                    onIncrement={() => setQuantity(quantity + 1)}
                    quantity={quantity}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <CheckoutCard
            email={email}
            notes={notes}
            building={selectedBuilding}
            creditCount={credits}
            selectedRoom={selectedRoom!}
            noOfHours={noOfHours}
            bookingDetails={
              productType === CONFERENCE_HANDLE
                ? {
                    date: new Date(),
                    startTime: bookingStartTime,
                  }
                : productType === PRODUCT_TYPES['parking'].handle
                ? {
                    date: bookingStartDate,
                  }
                : undefined
            }
            selectedVariant={selectedVariant}
            quantity={quantity}
            productType={productType}
            enterprise={enterprise}
            leftHotStamp={leftHotStamp}
            rightHotStamp={rightHotStamp}
          />
        </Grid>
      </Grid>
      {error &&
        setToastType({
          show: true,
          type: 'error',
          message: 'Details not fetched',
        })}
    </>
  );
}

export default Form;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    backgroundYellow: {
      backgroundColor: '#FFD500',
      borderRadius: '2px',
      padding: '0.5em',
      marginBottom: '1em',
    },
    buildingSelector: {
      width: '100%',
    },
    variantWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    gridWidth: {
      width: '100%',
    },
    addNoteBtn: {
      fontSize: '1.1em',
      color: '#0000FF',
      marginBottom: '1em',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    loaderGrid: {
      textAlign: 'center',
      '& svg': {
        color: '#0000FF',
      },
    },
    fieldError: {
      color: '#CC0000',
    },
  }),
);

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#0000FF',
      },
      current: {
        color: '#9999FF',
      },
    },
  },
});
