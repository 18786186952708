import React, { useState, useEffect } from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import {
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useFetchRequest } from 'utils/hooks/useFetchRequest';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import Loader from 'components/Loader';
import { H1, H6 } from 'components/Typography';
import QuantitySelector from 'components/QuantitySelector';
import RayInput from 'components/RayInput';
import SelectInput from 'components/MaterialSelect/Autocomplete';
import { ShopDetailPageParams, PRODUCT_TYPES, BuildingOption } from './types';
import CheckoutCard from './CheckoutCard';
import Variant from './VariantCard';
import GSTValidate from '../GSTValidate';
// import axios from 'utils/axios';

const RedSwitch = withStyles({
  switchBase: {
    color: '#0000CC',
    '&$checked': {
      color: '#0000CC',
    },
    '&$checked + $track': {
      backgroundColor: '#CC0000',
    },
  },

  checked: {
    color: '#FF0000',
    '&$checked': {
      color: '#FF0000',
    },
    '&$checked + $track': {
      backgroundColor: '#FF0000',
    },
  },
  track: {},
})(Switch);

type Props = {
  conferenceType: boolean;
  setConferenceType: React.Dispatch<boolean>;
};

function ShopDetailPage({ conferenceType, setConferenceType }: Props) {
  const classes = useStyles();

  // !TODO change handle for this conference seat to conf-room-seat
  const CONFERENCE_HANDLE = PRODUCT_TYPES['conference-room-credit'].handle;

  const { state }: any = useLocation();

  const { shopId }: ShopDetailPageParams = useParams();
  const [data, loader, error] = useFetchRequest('/shop/fetch-product-by-id', {
    product_id: shopId,
  });

  const [filteredVariants, setFilteredVariants] = useState<any[]>([]);
  const [receivedVariants, setReceivedVariants] = useState<any[]>([]);

  const [selectedVariant, setSelectedVariant] = useState<any | null>();
  const [quantity, setQuantity] = useState<number>(1);
  const [email, setEmail] = useState<string>('');
  const [showNotes, toggleNotes] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const [memberCount, setMemberCount] = useState<number | any>();
  // const [roomCapacity, setRoomCapacity] = useState<number>(2);
  const [conferenceId, setConferenceId] = useState<string>('');
  const [
    selectedBuilding,
    setSelectedBuilding,
  ] = useState<BuildingOption | null>(null);
  // const [bookingStartDate, setBookingStartDate] = useState<any>(new Date());
  // const [bookingStartTime, setBookingStartTime] = useState<any>(
  //   setHours(setMinutes(new Date(), 0), 9),
  // );
  // const [bookingEndTime, setBookingEndTime] = useState<any>(
  //   setHours(setMinutes(new Date(), 0), 10),
  // );
  const [gstin, setGstin] = useState<string>('');
  const [gstinError, setGstinError] = useState('');
  const [gstValid, setGstValid] = useState<boolean>(false);

  // const [excludedTimes, setExcludedTimes] = useState<any[]>([]);
  const [price,setPrice] = useState<number|undefined>();

  const [productType, setProductType] = useState<string>('');

  // const [creditsPerHourByBuilding, setCreditsPerHourByBuilding] = useState<any>(
  //   {},
  // );

  const { setToastType } = useAuthContext()!;

  const [buildingsData, buildingsLoader, buildingsError] = useFetchRequest(
    '/buildings/get-user-buildings',
  );

  useEffect(() => {
    if (data && data.product) {
      if (data.product.variants.length > 0)
        setSelectedVariant(data.product.variants[0]);
      setProductType(data.product.handle);
      if (data.product.handle === CONFERENCE_HANDLE) setMemberCount(1);
      if (data.product.variants && data.product.variants.length > 0) {
        setReceivedVariants(data.product.variants);
      }
    }
  }, [data, CONFERENCE_HANDLE]);

  useEffect(() => {
    if (productType === CONFERENCE_HANDLE) {
      setFilteredVariants([]);
    } else {
      setFilteredVariants(receivedVariants);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCount, productType]);

  // Effect for autofilling email and building when coming from Bookings Page START ----->
  useEffect(() => {
    if (state && state.addOnState && state.addOnState.customerEmail) {
      setEmail(state.addOnState.customerEmail);
      if (buildingsData && buildingsData.length > 0) {
        const foundBuilding = buildingsData.find(
          (item: any) => item.name === state.addOnState.buildingName,
        );
        setSelectedBuilding(foundBuilding);
      }
    }
  }, [state, buildingsData]);
  // <------ Effect for autofilling email and building when coming from Bookings Page END ---

  // Effect for disabling end time acc. to start time START ----->
  // useEffect(() => {
  //   const disabledHrs: number[] = [];
  //   const beforeHrs: number[] = [];
  //   const bookingStartHour = getHours(bookingStartTime);
  //   for (let i = 9; i < 19; i++) {
  //     disabledHrs.push(i);
  //   }
  //   for (let i = 9; i <= bookingStartHour; i++) {
  //     beforeHrs.push(i);
  //   }
  //   const disabledMinute = Number(getMinutes(bookingStartTime)) === 30 ? 0 : 30;
  //   const activeMinute = Number(getMinutes(bookingStartTime)) === 30 ? 30 : 0;
  //   setBookingEndTime(
  //     setHours(setMinutes(new Date(), activeMinute), bookingStartHour + 1),
  //   );
  //   setExcludedTimes(
  //     disabledHrs
  //       .map(index => setHours(setMinutes(new Date(), disabledMinute), index))
  //       .concat(
  //         beforeHrs.map(index =>
  //           setHours(setMinutes(new Date(), activeMinute), index),
  //         ),
  //       ),
  //   );
  // }, [bookingStartTime]);
  // <------ Effect for disabling end time acc. to start time END ---

  const setVariant = (data: any) => {
    setSelectedVariant(data);
  };

  // const handleStartDate = (e: any) => {
  //   if (e && e.toDate()) {
  //     setBookingStartDate(e.toDate());
  //   }
  // };

  const handleChangeBuilding = async (
    event: React.ChangeEvent<{}>,
    value: BuildingOption | null,
  ) => {
    if (value && value.id) setSelectedBuilding(value);
    else if (value === null) {
      setSelectedBuilding(null);
    }
    // if (value && value.id) {
    //   const getBuildingHoursCredits = await axios.get(
    //     '/buildings/settings/conference-room-credits',
    //     {
    //       params: {
    //         buildingId: value.id,
    //       },
    //       headers: {
    //         Authorization: authState?.accessToken?.accessToken,
    //       },
    //     },
    //   );
      // setCreditsPerHourByBuilding(getBuildingHoursCredits.data.data);
    // }
  };

  if (loader) return <Loader maxHeight />;
  return (
    <>
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12}>
          {state && state.addOnState && state.addOnState.customerEmail && (
            <div className={classes.backgroundYellow}>
              <small className="ray-text--body ">
                <b>
                  You are currently creating an add-on order associated with
                  order #{state.addOnState.orderNo}
                  <br />
                  If you do not want to associate this order with an existing
                  order, please visit the <Link to="/shop">Shop</Link> and
                  continue.
                </b>
              </small>
            </div>
          )}
          <H1>
            {data.product.title === 'Conference Room Credit'
              ? 'Conference Room Seat'
              : data.product.title}
          </H1>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <RedSwitch
                checked={conferenceType}
                onChange={() => setConferenceType(false)}
                name="superAdminSwitch"
              />
            }
            label="OFFLINE MODE"
          />
        </Grid>
        <Grid item xs={12}>
          {ReactHtmlParser(data.product.body_html)}
        </Grid>
        <Grid item xs={5}>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justify="flex-start"
            spacing={2}
          >
            <Grid item xs={12}>
              <SelectInput
                key="building"
                options={
                  buildingsData && buildingsData.length > 0
                    ? buildingsData.filter(
                        ({ disabled }: BuildingOption) => !disabled,
                      )
                    : []
                }
                getOptionLabel={option => option.name}
                disabled={buildingsLoader || buildingsError}
                label="Select Building"
                name="select-building"
                onChange={handleChangeBuilding}
              />
            </Grid>
            {/* {(productType === CONFERENCE_HANDLE ||
              productType === PRODUCT_TYPES['parking'].handle) && (
              <Grid
                item
                xs={12}
                container
                spacing={1}
              >
                <Grid
                  item
                  xs={productType === PRODUCT_TYPES['parking'].handle ? 12 : 6}
                >
                  <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        style={{ width: '100%' }}
                        disableToolbar
                        variant="inline"
                        format="Do MMMM YYYY"
                        label="Booking Date"
                        value={bookingStartDate}
                        onChange={handleStartDate}
                        disablePast
                      />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </Grid>
                {/* {productType === CONFERENCE_HANDLE && (
                  <>
                    <Grid item xs={3}>
                      <TimePicker
                        selected={bookingStartTime}
                        onChange={date => setBookingStartTime(date)}
                        timeIntervals={30}
                        minTime={[9, 0]}
                        maxTime={[17, 0]}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TimePicker
                        selected={bookingEndTime}
                        onChange={date => setBookingEndTime(date)}
                        timeIntervals={30}
                        minTime={[10, 0]}
                        maxTime={[18, 0]}
                        excludeTimes={excludedTimes}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                      />
                    </Grid>
                  </>
                )} 
              </Grid>*/}
            {/* {productType === CONFERENCE_HANDLE && (
              <Grid item xs={12}>
                <RayNumInput
                  id="no_of_member"
                  value={roomCapacity}
                  increment={() => {
                    if (roomCapacity === 20) return;
                    setRoomCapacity((prevCount: number) => prevCount + 1);
                  }}
                  decrement={() =>
                    setRoomCapacity((prevCount: number) =>
                      Math.max(2, prevCount - 1),
                    )
                  }
                  onChange={e => setMemberCount(e.target.value)}
                  label="Conference Room Size"
                  fullWidth
                />
              </Grid>
            )} */}
            <Grid item xs={12}>
              <RayInput
                type="text"
                id="price"
                pattern="[0-9]"
                value={price}
                placeholder={'Enter price'}
                onChange={e =>
                  setPrice(
                    parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0,
                  )
                }
                label="Price"
                fullWidth
              />
            </Grid>
            {data &&
              data.product &&
              data.product.variants &&
              data.product.variants.length > 1 && (
                <Grid item xs={12}>
                  <div className={classes.variantWrapper}>
                    {React.Children.toArray(
                      filteredVariants.map(variant => (
                        <Variant
                          info={variant}
                          selectedVariant={selectedVariant}
                          setVariant={setVariant}
                          productType={productType}
                        />
                      )),
                    )}
                  </div>
                </Grid>
              )}
            {productType === CONFERENCE_HANDLE && (
              <Grid item xs={12}>
                <RayInput
                  placeholder="Conference Room"
                  id="conference_room__id"
                  type="text"
                  fullWidth
                  autoMargin={false}
                  value={conferenceId}
                  onChange={e =>
                    setConferenceId(
                      e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
                    )
                  }
                  label="Conference Room"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <RayInput
                placeholder="arya.stark@wework.co.in"
                id="email__id"
                type="text"
                fullWidth
                autoMargin={false}
                value={email}
                onChange={e => setEmail(e.target.value)}
                label="Customer Email"
              />
              <small className="ray-text--body-x-small">
                This is the email that the payment link and invoice will be sent
                to.
              </small>
            </Grid>

            <GSTValidate
              gstin={gstin}
              setGstin={setGstin}
              gstinError={gstinError}
              setGstinError={setGstinError}
              gstValid={gstValid}
              setGstValid={setGstValid}
            />

            <Grid item xs={12}>
              <p
                onClick={() => toggleNotes(!showNotes)}
                className={classes.addNoteBtn}
              >
                {!showNotes ? 'Add Notes' : 'Hide Notes'}
              </p>
              {showNotes && (
                <div className="ray-text-area">
                  <textarea
                    rows={4}
                    className="ray-text-area__input"
                    id="notes"
                    value={notes}
                    onChange={e =>
                      setNotes(
                        e.target.value.replace(/[*|":<>[\]{}`\\()';&$=]/g, ''),
                      )
                    }
                  />
                  <label className="ray-text-area__label" htmlFor="notes">
                    Notes (optional)
                  </label>
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              {productType !== CONFERENCE_HANDLE && (
                <div>
                  <H6>
                    {productType &&
                      PRODUCT_TYPES[productType]?.quantityEditorLabel}
                  </H6>
                  <QuantitySelector
                    onDecrement={() =>
                      setQuantity(prev => {
                        if (prev - 1 === 0) return prev;
                        return prev - 1;
                      })
                    }
                    onIncrement={() => setQuantity(quantity + 1)}
                    quantity={quantity}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <CheckoutCard
            email={email}
            notes={notes}
            building={selectedBuilding}
            // memberCount={
            //   productType === CONFERENCE_HANDLE ? roomCapacity : memberCount
            // }
            // creditCount={
            //   Object.keys(creditsPerHourByBuilding).length > 0 &&
            //   creditsPerHourByBuilding.credits_mapping__capacity_x_credits[
            //     roomCapacity
            //   ]
            // }
            price={price}
            // bookingDetails={
            //   productType === CONFERENCE_HANDLE
            //     ? {
            //         date: bookingStartDate,
            //         // startTime: bookingStartTime,
            //         // endTime: bookingEndTime,
            //       }
            //     : productType === PRODUCT_TYPES['parking'].handle
            //     ? {
            //         date: bookingStartDate,
            //       }
            //     : undefined
            // }
            confRoom={conferenceId}
            selectedVariant={selectedVariant}
            quantity={quantity}
            productType={productType}
            gstin={gstin.toUpperCase()}
            disableOrderBtn={Boolean(gstinError.length)}
            gstValid={gstValid}
          />
        </Grid>
      </Grid>
      {error &&
        setToastType({
          show: true,
          type: 'error',
          message: 'Details not fetched',
        })}
    </>
  );
}

export default ShopDetailPage;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    backgroundYellow: {
      backgroundColor: '#FFD500',
      borderRadius: '2px',
      padding: '0.5em',
      marginBottom: '1em',
    },
    buildingSelector: {
      width: '100%',
    },
    variantWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    addNoteBtn: {
      fontSize: '1.1em',
      color: '#0000FF',
      marginBottom: '1em',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    fieldError: {
      color: '#CC0000',
    },
  }),
);

// const materialTheme = createMuiTheme({
//   overrides: {
//     MuiPickersDay: {
//       daySelected: {
//         backgroundColor: '#0000FF',
//       },
//       current: {
//         color: '#9999FF',
//       },
//     },
//   },
// });
