import { CircularProgress, FormControlLabel, Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import RayInput from 'components/RayInput';
import { BlueSwitch } from 'components/RaySwitch';
import SelectInput from 'components/MaterialSelect/Autocomplete';
import RayButton from 'components/RayButton';
import {
  leadIdRegex,
  emailValidateRegex,
  numberInputRegex,
} from 'utils/constants';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import axiosGlobal from 'utils/axiosGlobal';
import { addHours, format } from 'date-fns';
import GSTValidate from 'containers/ShopDetailPage/GSTValidate';
import { DEFAULT_PAYMENT_STATE } from '.';
import { useOktaAuth } from '@okta/okta-react';

const leadList = [
  'Events Distro',
  'CRM',
  'Repeat Customers',
  'Member Referrals',
  'Walk In/ Inbound',
  'Outreach',
];

const Payment = ({ paymentState, updateState }: any) => {
  const classes = useStyles();
  const [btnDisable, setBtnDisable] = React.useState<boolean>(false);
  const [showOptions, setShowOptions] = React.useState<boolean>(false);
  const [reservations, setReservations] = React.useState<any[]>([]);
  const {
    email,
    emailInUse,
    gstin,
    gstinError,
    gstValid,
    isPaymentModeOnline,
    securityDeposit,
    amount,
    phone,
    eventLeadId,
  } = paymentState;
  const { setToastType } = useAuthContext()!;
  const { authState } = useOktaAuth();

  const gstProps = { gstValid, gstin, gstinError };

  const fetchTokens = async () => {
    setBtnDisable(true);
    if (!email || email === '' || !email.match(emailValidateRegex)) {
      setToastType({
        show: true,
        type: 'info',
        message: 'Enter a valid email address',
      });
      setBtnDisable(false);
      return;
    }
    setShowOptions(false);
    try {
      const response = await axiosGlobal.get(
        `/api/v1/admin/conference-rooms/reserve-event-space?status=PENDING&email=${email}`,
        {
          headers: {
            Authorization: authState?.accessToken?.accessToken,
          },
        },
      );
      if (response.data.data.reservations.length === 0) {
        setToastType({
          show: true,
          type: 'info',
          message: 'No reservations found for the selected user',
        });
        updateState({ ...DEFAULT_PAYMENT_STATE, email, emailInUse });
        return;
      }
      //reservations will have booking details along with booking token.
      setReservations(response?.data?.data?.reservations);
      setShowOptions(true);
      updateState({ emailInUse: email, reservation: {} });
    } catch (error: any) {
      const message = error?.response?.data?.message || 'Something went wrong';
      setToastType({
        show: true,
        type: 'error',
        message,
      });
      updateState({ ...DEFAULT_PAYMENT_STATE, email, emailInUse });
    } finally {
      setBtnDisable(false);
    }
    return;
  };

  const handleChangeReservation = (
    event: React.ChangeEvent<{}>,
    value: any | null,
  ) => {
    if (value) {
      updateState({ reservation: value });
    } else {
      updateState({ reservation: {} });
    }
  };

  return (
    <Grid container item spacing={2} className={classes.container}>
      <Grid item xs={4}>
        <RayButton
          type="button"
          buttonType="primary"
          onClick={fetchTokens}
          className={classes.buttonRow}
          disabled={btnDisable || emailInUse === email}
        >
          {btnDisable ? (
            <CircularProgress size={20} style={{ color: '#FFFFFF' }} />
          ) : (
            <>
              <span>Fetch Reservations</span>
            </>
          )}
        </RayButton>
      </Grid>
      {showOptions && (
        <Grid item xs={12}>
          <SelectInput
            key="reservation"
            size="medium"
            options={reservations}
            groupBy={(option: any) => option.metadata?.building_name}
            getOptionLabel={option =>
              `${option.metadata?.global_room_name} - ${
                option.metadata?.member_count
              } seater - ${format(
                new Date(option.metadata?.booking_date._seconds * 1000),
                'do MMMM yyyy',
              )}(${format(
                new Date(option.metadata?.booking_start_time._seconds * 1000),
                'hh:mm a',
              )} - ${format(
                addHours(
                  new Date(option.metadata?.booking_start_time._seconds * 1000),
                  option.metadata?.no_of_hrs,
                ),
                'hh:mm a',
              )})`
            }
            label="Select reservation"
            name="select-reservation"
            onChange={handleChangeReservation}
          />
        </Grid>
      )}
      {showOptions && (
        <>
          <Grid item xs={12}>
            <RayInput
              type="text"
              id="security__deposit"
              fullWidth
              pattern="[0-9]"
              autoMargin={false}
              value={securityDeposit}
              onChange={e =>
                updateState({
                  securityDeposit:
                    parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0,
                })
              }
              label="Security Deposit"
            />
          </Grid>
          <Grid item xs={12}>
            <RayInput
              type="text"
              id="amount"
              fullWidth
              pattern="[0-9]"
              autoMargin={false}
              value={amount}
              onChange={e =>
                updateState({
                  amount:
                    parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0,
                })
              }
              label="Amount"
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.title}>Mode of payment</div>
            <FormControlLabel
              control={
                <BlueSwitch
                  checked={isPaymentModeOnline}
                  onChange={() =>
                    updateState({ isPaymentModeOnline: !isPaymentModeOnline })
                  }
                  name="mode_of_payment"
                />
              }
              label={'Online'}
            />
          </Grid>
          <Grid item xs={12}>
            <RayInput
              placeholder="Enter phone number"
              id="phone__customer"
              type="text"
              pattern="[0-9]"
              fullWidth
              autoMargin={false}
              value={phone}
              onChange={e => {
                updateState({
                  phone: e.target.value.replace(numberInputRegex, ''),
                });
              }}
              label="Phone number"
            />
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              key="source_of_lead"
              size="medium"
              options={leadList}
              getOptionLabel={option => option}
              label="Source of lead"
              name="source_of_lead"
              onChange={(e: any, value: any) => updateState({ lead: value })}
            />
          </Grid>

          <Grid item xs={12}>
            <RayInput
              placeholder="Enter event lead id"
              id="eventLeadId"
              type="text"
              pattern="[a-zA-Z0-9]+"
              fullWidth
              autoMargin={false}
              value={eventLeadId}
              onChange={e => {
                updateState({
                  eventLeadId: e.target.value.replace(leadIdRegex, ''),
                });
              }}
              label="Event Lead ID"
            />
          </Grid>

          <Grid item xs={12}>
            <GSTValidate
              {...gstProps}
              setGstin={(value: any) => updateState({ gstin: value })}
              setGstinError={(value: any) => updateState({ gstinError: value })}
              setGstValid={(value: any) => updateState({ gstValid: value })}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Payment;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: '8px',
    },
    title: {
      fontSize: '16px',
      fontWeight: 600,
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  }),
);
