import { CircularProgress, Grid } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useReactQuery } from 'utils/hooks/useReactQuery';
import styled from 'styled-components';
import pdfIcon from '../../assets/pdf_icon.png';
import { InvoiceData } from 'containers/Bookings/TableContainer';
import axios from 'utils/axios';
import { useOktaAuth } from '@okta/okta-react';

const LoaderWrapper = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InvoiceWrapper = styled.div`
  width: 100%;
  a {
    display: flex;
    align-items: center;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`;

type Props = {
  orderId: String;
  buildingName: String;
  isCancelledOrder: boolean;
};

function ViewPurchasesDetails({
  orderId,
  buildingName,
  isCancelledOrder,
}: Props) {
  const { isLoading, error, data } = useReactQuery([`${orderId}`], {
    url: '/shop/fetch-purchase-by-id',
    params: {
      docId: orderId,
    },
  });

  const purchaseData = useMemo(() => {
    if (data) return data.purchaseData;
    return {};
  }, [data]);

  const productName = useMemo(() => {
    let productName = '--';
    if (Array.isArray(purchaseData?.productInformation)) {
      productName = purchaseData.productInformation
        .map((product: any) =>
          product.title !== 'Others'
            ? product.title
            : 'Others ( ' + product.eventTypeOthersDetails + ' )' ||
              product.type,
        )
        .join(', ');
      if (purchaseData.metadata?.eventDraftOrderId)
        productName += ' (Event Space)';
    } else if (purchaseData.productInformation?.title) {
      productName = purchaseData.productInformation?.title;
    } else if (purchaseData.metadata?.handle) {
      productName = purchaseData.metadata?.handle;
      if (purchaseData.shopify_data?.variant_title) {
        productName += ' (' + purchaseData.shopify_data?.variant_title + ')';
      }
    } else if (purchaseData.shopify_data?.title) {
      productName = purchaseData.shopify_data?.title;
    }
    return productName;
  }, [purchaseData]);

  const { authState } = useOktaAuth();

  const [invoiceResponse, setInvoiceResponse] = useState<InvoiceData | null>({
    GSTINValidity: null,
    isDiscountedOrder: null,
    pdfURL: null,
    typeOfInvoice: null,
    checkoutUserEmail: null,
  });

  React.useEffect(() => {
    const {
      status,
      dashBoardOrderType,
      shopify_data,
      draft_order_id,
    } = purchaseData;

    const orderId = ['postpaid-event', 'security-deposit'].includes(
      dashBoardOrderType,
    )
      ? draft_order_id
      : shopify_data?.order_id;
    const invoiceURL =
      dashBoardOrderType === 'security-deposit'
        ? '/invoices/deposit-receipt'
        : '/invoices/admin/invoices';
    // Invoice/receipt generated for postpaid & security deposit orders after creation of payment link
    if (
      orderId &&
      (status === 'COMPLETED' ||
        ['postpaid-event', 'security-deposit', 'custom-event'].includes(
          dashBoardOrderType,
        ))
    ) {
      (async () => {
        try {
          const { data } = await axios.get(invoiceURL, {
            params: {
              order_id: orderId,
            },
            headers: {
              Authorization: authState?.accessToken?.accessToken,
            },
          });
          const { data: invoiceData } = data;
          setInvoiceResponse(invoiceData as InvoiceData);
        } catch (error) {
          console.log('Error fetching Invoice Response ', error);
          setInvoiceResponse(null);
        }
      })();
      return;
    }
    setInvoiceResponse(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseData]);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <CircularProgress size={30} color="inherit" />
      </LoaderWrapper>
    );
  }
  if (error)
    return (
      <LoaderWrapper>
        <p>Error fetching records</p>
      </LoaderWrapper>
    );
  if (Object.keys(purchaseData).length === 0)
    return (
      <LoaderWrapper>
        <p>Invalid data recieved</p>
      </LoaderWrapper>
    );

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <h5>Shopify Order Number:</h5>
        <p>{purchaseData.shopify_data?.order_number || '--'}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Shopify Draft Order Id:</h5>
        <p>{purchaseData.draft_order_id || '--'}</p>
      </Grid>
      {purchaseData?.dashBoardOrderType === 'postpaid-event' && (
        <Grid item xs={4}>
          <h5>Invoice Number:</h5>
          <p>{purchaseData?.postpaid_invoice_number || '--'}</p>
        </Grid>
      )}
      <Grid item xs={4}>
        <h5>Building Name:</h5>
        <p>{buildingName}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Customer Email:</h5>
        <p>{purchaseData.customer_email || '--'}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Product Name:</h5>
        <p>{productName}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Quantity:</h5>
        <p>
          {/* The paymentMode is mainly for event space offline orders. */}
          {Array.isArray(purchaseData?.productInformation) &&
          purchaseData.metadata?.paymentMode !== 'offline'
            ? purchaseData.productInformation.reduce(
                (sum: number, product: any) => (sum += product.quantity || 1),
                0,
              )
            : purchaseData?.productInformation?.quantity ||
              purchaseData?.shopify_data?.quantity ||
              '--'}
        </p>
      </Grid>
      <Grid item xs={4}>
        <h5>Order Price(₹):</h5>
        {/* The paymentMode is mainly for event space offline orders. */}
        <p>
          {purchaseData.shopify_data?.parent_order_total_price +
            (purchaseData.metadata?.paymentMode ?? purchaseData.paymentMode
              ? ' (' +
                (purchaseData.metadata?.paymentMode ?? purchaseData.paymentMode)
                  .charAt(0)
                  .toUpperCase() +
                (
                  purchaseData.metadata?.paymentMode ?? purchaseData.paymentMode
                ).slice(1) +
                ')'
              : '') || '--'}
        </p>
      </Grid>
      <Grid item xs={4}>
        <h5>Order Status:</h5>
        <p>{purchaseData.status || '--'}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Created At:</h5>
        <p>
          {purchaseData?.created_at
            ? new Date(
                purchaseData?.created_at._seconds * 1000,
              ).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' })
            : '--'}
        </p>
      </Grid>
      <Grid item xs={4}>
        <h5>GST:</h5>
        <p>{purchaseData.customer_gstin || '--'}</p>
      </Grid>
      <Grid item xs={4}>
        <h5>Notes:</h5>
        <p>
          {typeof purchaseData.notes === 'string'
            ? purchaseData.notes || '--'
            : '--'}
        </p>
      </Grid>
      <Grid item xs={4}>
        <h5>Order Creator</h5>
        <p>{purchaseData.order_creator || '--'}</p>
      </Grid>
      {invoiceResponse && (
        <>
          <Grid item xs={4}>
            <h5>
              {isCancelledOrder &&
              (purchaseData.dashBoardOrderType === 'postpaid-event' ||
                purchaseData.dashBoardOrderType === 'custom-event')
                ? 'Credit Note'
                : 'Invoice'}
              :
            </h5>
            {invoiceResponse.isDiscountedOrder &&
              invoiceResponse.pdfURL === null &&
              'Discount Order'}
            {!invoiceResponse.isDiscountedOrder && invoiceResponse.pdfURL && (
              <InvoiceWrapper>
                <a
                  href={invoiceResponse.pdfURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={pdfIcon} alt="" />
                  {/* <span>Type : {invoiceResponse.typeOfInvoice}</span> */}
                  <span>Download Now</span>
                </a>
                {invoiceResponse.GSTINValidity !== null && (
                  <p>{invoiceResponse?.GSTINValidity}</p>
                )}
              </InvoiceWrapper>
            )}
            {(invoiceResponse.isDiscountedOrder === null ||
              !invoiceResponse.isDiscountedOrder) &&
              invoiceResponse.pdfURL === null &&
              '--'}
          </Grid>
          {invoiceResponse.checkoutUserEmail && (
            <Grid item xs={4}>
              <h5>Checkout Email: </h5>
              <p>{invoiceResponse.checkoutUserEmail}</p>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default ViewPurchasesDetails;
